import React from "react";
import {Select} from "@wargostec/react-components";

const SelectType = React.forwardRef((props, ref) => {
  const data = [
    { name: "TODOS", value: null, id: 0 },
    { name: "FALTA", value: "FALTA",id: 1 },
    { name: "LICENCIA SIN GOCE", value: "LICENCIA_SIN_GOCE",id: 2 },
    { name: "LICENCIA CON GOCE", value: "LICENCIA_CON_GOCE",id: 3 },
    { name: "LICENCIA DE PATERNIDAD", value: "LICENCIA_PATERNIDAD",id: 4 },
    { name: "LICENCIA DE MATERNIDAD", value: "LICENCIA_MATERNIDAD",id: 5 },
    { name: "DESCANSO MEDICO", value: "DESCANSO_MEDICO",id: 6 },
  ];

  return (
    <>
      <Select ref={ref} {...props} >
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  );
});

export default SelectType;
