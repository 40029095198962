import React, {useEffect, useState, useContext, useCallback} from "react";
import * as FaIcons from "react-icons/fa";
import {Apis} from "../../../../utils/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import PageSection from "../../../../components/layout/PageSection";
import {useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import SelectType from "../../../../components/selects/SelectType";
import CustomerSearchInput from "../../../../components/selects/CustomerSearchInput";
import EmptyState from "../../../../components/migrationcomponents/emptystate";
import DeleteGenericModal from "../../../../components/modals/DeleteGenericModal";
import useDeleteModal from "../../../../hooks/useDeleteModal";
import axios from "axios";
import {SiteContext} from '../../../../components/context/SiteContext';
import DocumentView from '../../../../components/viewpdf/DocumentView';
import {Button, Card, Slidebar, Table, Input, Title, FlexContainer} from "@wargostec/react-components";
import {LuFileEdit, LuTrash2} from "react-icons/lu";
import {AiOutlineCloudDownload} from "react-icons/ai";
import FormGroup from "../../../../components/migrationcomponents/FormGroup";

const Measures = () => {
  const {empresaId} = useContext(SiteContext);
  const [close, setClose] = useState(false);
  const [open, setOpen] = useState(false);
  const baseURL = Apis.BASE_API_URL + "/medidas";
  let defaultParams = {empresaId};
  const [params, setParams] = useState(defaultParams);
  const {loading, data, fetchData, error} = useFetch2(baseURL, [], params);
  const navigate = useNavigate();
  const {register, handleSubmit, setValue, control} = useForm({
    defaultValues: defaultParams,
  });

  const getData = (params) => {
    setParams(params);
    setClose(!close);
  };

  const {
    wrapper, refresh,
    openDeleteModal,
  } = useDeleteModal({text: "¿Desea eliminar esta medida disciplinaria?"});

  const deleteElement = (id) =>
    axios.delete(baseURL + `/${id}`);


  useEffect(() => {
    fetchData();
  }, [params, refresh]);

  const resetForm = () => {
    setValue("from", "");
    setValue("to", "");
    setValue("tipo", "");
  };

  const downloadReport = async (id) => {
    const param = data.filter((item) => item.id === id)[0];
    window.location.href = (await baseURL) + `/${id}/report.pdf`;
  };

  const goMeasureEdit = (id) => {
    navigate(`/medidas-edit/${id}`);
  };

  const goMeasureNew = () => {
    navigate("/medidas-nuevo");
  };

  const employeeValue = useCallback(props =>
    <div>
      {props.row.original.employee?.person?.lastNameFirstName}
    </div>
    , []);

  const docValue = useCallback(props => {
    const doc = props.row.original.doc;
    return (
      <FlexContainer justifyContent="center">
        {doc ? (
          <DocumentView 
          value={doc?.url} 
          title='Sustento' 
          size='xs' 
          variant="light" 
          icon={<FaIcons.FaRegFilePdf />}
        />
        ) :
          '--'
        }
      </FlexContainer>
    );
  }, []);

  const reglamentValue = useCallback(props => {
    const reglament = props.renderValue()
    return (
      <div>
        {reglament === null ? "--" : ("Art. - " + reglament.articulo)}
      </div>
    )
  }, []);

  const duracionValue = useCallback(props => {
    const day = props.renderValue()
    return (
      <div>
        {day + " Días"}
      </div>
    )
  }, []);

  const periodoSuspensionValue = useCallback(props => {
    const day = props.renderValue()
    return (
      <div>
        {day === null ? "-" : day}
      </div>
    )
  }, []);

  const actionValue = useCallback((props) => {
    const id = props.row.original.id

    return (
      <FlexContainer alignItems="center" justifyContent="center" gap='8px'>
        <Button
          variant='light'
          size='xs'
          onClick={() => downloadReport(id)}
          text={'Generar'}
          icon={<AiOutlineCloudDownload />}
        />
        <Button
          variant='light'
          size='xs'
          onClick={() => goMeasureEdit(id)}
          icon={<LuFileEdit />}
        />
        <Button
          variant='danger'
          size='xs'
          onClick={() => openDeleteModal(id, deleteElement)}
          icon={<LuTrash2 />}
        />
      </FlexContainer>
    );
  }, []);

  const columns = [
    {
      header: "Id",
      accessorKey: "id",
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: "Empleado",
      accessorKey: "employee",
      cell: employeeValue,

    },
    {
      header: "Document",
      accessorKey: "doc",
      enableGlobalFilter: false,
      enableSorting: false,
      cell: docValue,
    },
    {
      header: "Fecha ocurrencia",
      accessorKey: "fechaOcurrencia",
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: "Reglamento",
      accessorKey: "reglamento",
      enableGlobalFilter: false,
      enableSorting: false,
      cell: reglamentValue,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {header: "Motivo", accessorKey: "descripcion"},
    {header: "Medida", accessorKey: "tipo", disableSortBy: true},
    {
      header: "Duración",
      accessorKey: "duracion",
      cell: duracionValue
    },
    {
      header: "Periodo de suspensión",
      accessorKey: "inicio",
      cell: periodoSuspensionValue,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: "Acción",
      cell: actionValue,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
  ];

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Medidas disciplinarias' type={'page'} />
        </div>
      </PageSection>
      <PageSection>
        <Card noPadding>
          <Table
            columns={columns}
            data={data}
            onAdd={goMeasureNew}
            onFilter={() => setClose(!close)}
            searchable
            pageSize={20}
            emptyListText={
              <EmptyState
                title='Sin resultados'
                subtitle='Haga click en filtros para realizar una nueva consulta.'
                data={data}
                loading={loading}
                error={error}
              />
            }
          />
        </Card>
      </PageSection>

      <Slidebar
        title="Filtrar"
        toggler={() => setClose(!close)}
        isOpen={close}
      >
        <FlexContainer flexDirection="column" gap='16px'>
          <strong>Fecha de ocurrencia</strong>
          <Input label={"Desde"} type="date" {...register("from")} />
          <Input label={"Hasta"} type="date" {...register("to")} />
          <FormGroup>
            <label>Empleado</label>
            <Controller
              name="employeeId"
              control={control}
              render={({field}) => <CustomerSearchInput {...field} />}
            />
          </FormGroup>
          <FormGroup>
            <label>Tipo</label>
            <SelectType {...register("tipo")} />
          </FormGroup>
        </FlexContainer>
        <Button variant="primary" onClick={handleSubmit(getData)} text={"BUSCAR"} icon={<FaIcons.FaSearch />} />
        <Button variant="light" onClick={resetForm} text={"LIMPIAR"} icon={<FaIcons.FaEraser />} />
      </Slidebar>
      <DeleteGenericModal {...wrapper} />
    </>
  );
};

export default Measures;
