import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SelectRecords from '../../../../components/selects/SelectRecords';
import CustomerSearchInput from '../../../../components/selects/CustomerSearchInput';
import { DateTime } from 'luxon';
import {Col, Row} from "../../../../components/migrationcomponents/flexlayout";
import {Alert} from "../../../../components/migrationcomponents/alert";
import FormGroup from "../../../../components/migrationcomponents/FormGroup";
import {Input} from "@wargostec/react-components";

const RecordsForm = ({ value, onSubmit, setAction, edit, mode, tipo }) => {
  const { register, handleSubmit, reset, control } = useForm();

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [value]);

  useEffect(() => {
    if (tipo) {
      reset({ ...value, tipo });
    }
  }, [tipo]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, []);

  return (
    <>
      <FormGroup>
        {
          mode ? <Alert variant='info' text={`Fecha de turno: ${DateTime.fromISO(value.fecha).toFormat('dd/MM')}`} /> : null
        }
      </FormGroup>
      <FormGroup>
        <label>Empleado</label>
        {
          edit ? <Input {...register('employeeName')} disbaled /> :
            <Controller
              name='employeeId'
              control={control}
              render={({ field }) => <CustomerSearchInput {...field} />}
            />
        }
      </FormGroup>
      <FormGroup>
        <label>Tipo</label>
        <SelectRecords {...register('tipo')} onChange={onchange} />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <label>Fecha Real</label>
            {
              edit ? <Input type='date' {...register('fecha')} disabled /> :
                <Input
                  type='date'
                  name={'fecha'}
                  placeholder='dd/mm/yyyy'
                  {...register('fecha')}
                />
            }
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <label>Hora</label>
            {
              edit ? <Input
                type="time"
                name={'fecha'}
                {...register('hora')}
                disabled
              /> : <Input
                type="time"
                name={'fecha'}
                placeholder='HH:mm'
                {...register('hora')}
              />
            }
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default RecordsForm;
