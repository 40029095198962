import React, { useEffect } from 'react';
import { Apis } from '../../../../utils/Apis';
import axios from 'axios';
import useFetch2 from '../../../../hooks/useFetch2';
import AreasForm from './AreasForm';

const AreasEdit = ({ id, setAction, setTitle }) => {
  const { data } = useFetch2(Apis.BASE_API_URL + '/areas/' + id, []);

  const update = (params) => {
    return axios.put(Apis.BASE_API_URL + `/areas/${id}`, params);
  };

  useEffect(() => {
    setTitle('Editar área');
  }, []);

  return <AreasForm value={data} onSubmit={update} setAction={setAction} />
};

export default AreasEdit;
