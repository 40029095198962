import React, {useCallback, useEffect} from "react";
import styled from "styled-components";
import {Apis} from "../../../utils/Apis";
import useFetch2 from "../../../hooks/useFetch2";
import PageSection from "../../../components/layout/PageSection";
import zkteco from "../../../assets/reloj.png";
import digitalPerson from "../../../assets/digitalPerson.png";
import ClockNew from "./ClockNew";
import ClockEdit from "./ClockEdit";
import ModalGeneric from '../../../components/Modal';
import useEditModal from '../../../hooks/useEditModal';
import useDeleteModal from '../../../hooks/useDeleteModal'
import useConfirmModal from '../../../hooks/useConfirmModal';
import DeleteGenericModal from '../../../components/modals/DeleteGenericModal';
import axios from 'axios';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import ClockInfo from "./ClockInfo";
import {FlexContainer, Table, Title} from "@wargostec/react-components";
import {OnlineActive} from "../../../components/migrationcomponents/OnlineCircleActive";
import EmptyState from "../../../components/migrationcomponents/emptystate";
import {LuFileEdit, LuSend, LuTrash2} from "react-icons/lu";
import {AiOutlineInfoCircle, AiOutlineReload} from "react-icons/ai";
import {PiBroom} from "react-icons/pi";
import {DropdownButton} from "react-bootstrap";
import {LiaCogSolid} from "react-icons/lia";
import Dropdown from 'react-bootstrap/Dropdown';

const CustomDropDown = styled(DropdownButton)`
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    background: #F8F9FA;
    border: 1px solid #F8F9FA;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
`

const Clock = () => {
  const baseURL = Apis.BASE_API_URL + "/clocks";
  const deleteElement = (id) =>
    axios.delete(Apis.BASE_API_URL + `/clocks/${id}`);
  const { loading, data, fetchData, error } = useFetch2(baseURL,[]);

  const { wrapper, refresh, addAction, editAction } = useEditModal({
    addComponent: <ClockNew />,
    editComponent: <ClockEdit />,
  });

  const { wrapper: wrapperInfo, addAction: infoAction } = useEditModal({
    addComponent: <ClockInfo />,
  });

  const {
    wrapper: wrapper2,
    refresh: refresh2,
    openDeleteModal,
  } = useDeleteModal({ text: '¿Desea eliminar este reloj?' });

  const {
    wrapper: wrapper3,
    openConfirmModal: openWipeModal,
    
  } = useConfirmModal({ text: 'Se eliminara toda la data presente en el reloj como registros de empleados, marcaciones guardadas y log de operLogs. La data de empleados y marcaciones presente en el servidor permanecera intacta. ¿Desea continuar?' });

  const {
    wrapper: wrapper4,
    openConfirmModal: openSyncModal,
  } = useConfirmModal({ text: 'Todos los empleados registrados en esta sucursal serán enviados al reloj. Si la persona tiene una huella registrada, tambien se enviará. Esta operacion tomara unos 30 segundos aproximadamente. ¿Desea continuar?' });

  const {
    wrapper: wrapper5,
    openConfirmModal: openForwardOperationsModal,
  } = useConfirmModal({ text: 'Se enviara un comando al reloj para que vuelva a enviar todos los de operLogs y registros de personas y huellas' });
  const {
    wrapper: wrapper6,
    openConfirmModal: openForwardMarcationModal,
  } = useConfirmModal({ text: 'Se enviara un comando al reloj para que vuelva a enviar todas las marcaciones que tiene almacenada en su memoria interna. En caso la marcacion ya exista en el sistema se descartara' });

  const wipeElement = (id) => {
    return axios.post(Apis.BASE_API_URL + '/clocks/' + id + '/_clear-data', {});
  }

  const syncElement = (id) => {
    return axios.post(Apis.BASE_API_URL + '/clocks/' + id + '/_sync', {});
  }

  const forwardOperations = (id) => {
    return axios.post(Apis.BASE_API_URL + '/clocks/' + id + '/_check?operLogStamp=0', {});
  }

  const forwardMarcation = (id) => {
    return axios.post(Apis.BASE_API_URL + '/clocks/' + id + '/_check?attLogStamp=0', {});
  }

  const relojImage = useCallback((props) => {
    return (
      <div>
        {
          props.renderValue() === 'zkteco' ?
            <img width="50px" src={zkteco} alt={'zkteco'}/>
            :
            <img width="50px" src={digitalPerson} alt={'digitalPerson'}/>
        }
      </div>
    );
  }, []);

  const endConexion = ({ row }) => {
    const status = row.original.lastPing
    return (
      <FlexContainer justifyContent="center">
      <OnlineActive value={status} />
      </FlexContainer>
    )
  };

  const actionValue = useCallback((props) => {
    const id = props.renderValue();

    return (

      <FlexContainer alignItems="center" justifyContent="center" gap='8px'>
        <CustomDropDown
          variant='light'
          align="end"
          size="sm"
          title={
            <FlexContainer alignItems='center' gap='4px'>
              <LiaCogSolid  size={16} />
              <span>Acciones</span>
            </FlexContainer>
          }
        >
          <FlexContainer flexDirection="column" gap='8px' alignItems="center">
            <Dropdown.Item onClick={() => openSyncModal(() => syncElement(id))}>
              <FlexContainer gap='6px' alignItems="center">
              <AiOutlineReload size={16} />
              <span style={{fontSize:'14px'}}>Sincronizar</span>
              </FlexContainer>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => openWipeModal(() => wipeElement(id))}>
              <FlexContainer gap='6px' alignItems="center">
              <PiBroom size={16} />
              <span style={{fontSize:'14px'}}>Limpiar</span>
              </FlexContainer>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => openForwardOperationsModal(() => forwardOperations(id))}>
              <FlexContainer gap='6px' alignItems="center">
              <LuSend size={16} />
              <span style={{fontSize:'14px'}}>Solicitar reenvio operaciones</span>
              </FlexContainer>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => openForwardMarcationModal(() => forwardMarcation(id))}>
              <FlexContainer gap='6px' alignItems="center">
              <LuSend size={16} />
              <span style={{fontSize:'14px'}}>Solicitar reenvio marcaciones</span>
              </FlexContainer>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => infoAction({ item: { id: id, data: props.row.original } })}>
              <FlexContainer gap='6px' alignItems="center">
              <AiOutlineInfoCircle size={16} />
              <span style={{fontSize:'14px'}}>Info y operaciones</span>
              </FlexContainer>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => editAction(props.row.original.id)}>
              <FlexContainer gap='6px' alignItems="center">
              <LuFileEdit size={16} />
              <span style={{fontSize:'14px'}}>Editar</span>
              </FlexContainer>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => openDeleteModal(() => deleteElement(id))}>
              <FlexContainer gap='6px' alignItems="center">
              <LuTrash2 size={16} />
              <span style={{fontSize:'14px'}}>Eliminar</span>
              </FlexContainer>
            </Dropdown.Item>
          </FlexContainer>
        </CustomDropDown>
      </FlexContainer>
    );
  }, []);

  const columns = [
    {
      header: "Reloj",
      accessorKey: "type",
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: relojImage
    },
    {
      header: "Sucursal",
      accessorKey: "sucursalName",
      meta: {
        headerClassName: 'text-left',
        columnClassName: 'text-left',
      },
    },
    {
      header: "Serie",
      accessorKey: "serialNumber",
      meta: {
        headerClassName: 'text-left',
        columnClassName: 'text-left',
      },
    },
    {
      header: "Ultima conexión",
      accessorKey: "lastPing",
      cell: endConexion,
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {
      header: "Acción",
      accessorKey: "id",
      cell: actionValue,
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [refresh, refresh2]);

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Relojes' type={'page'}/>
        </div>
      </PageSection>
      <PageSection>
        <Table 
          columns={columns} 
          data={data}
          onAdd={addAction}
          onRefresh={fetchData}
          searchable
          pageSize={20}
            emptyListText={
              <EmptyState
                title='Sin resultados'
                subtitle='Haga click en filtros para realizar una nueva consulta.'
                data={data}
                loading={loading}
                error={error}
              />
            }
        />
      </PageSection>
      <ModalGeneric {...wrapper} />
      <ModalGeneric {...wrapperInfo} button={'Actualizar Info'} />
      <DeleteGenericModal {...wrapper2} />
      <ConfirmModal {...wrapper3} />
      <ConfirmModal {...wrapper4} />
      <ConfirmModal {...wrapper5} />
      <ConfirmModal {...wrapper6} />
    </>
  );
};

export default Clock;
