import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import * as FaIcons from "react-icons/fa";
import {DateTime} from 'luxon';
import {Apis} from '../../../../utils/Apis';
import useFetch2 from '../../../../hooks/useFetch2';
import PageSection from '../../../../components/layout/PageSection';
import {MdBlock} from 'react-icons/md';
import {Controller, useForm} from 'react-hook-form';
import CustomerSearchInput from '../../../../components/selects/CustomerSearchInput';
import ModalInfo from '../../../../components/modals/ModalInfo';
import useModal from '../../../../hooks/useModal';
import MarkingDetail from './MarkingDetail';
import RecordsNew from '../records/RecordsNew';
import useEditModal from '../../../../hooks/useEditModal';
import ModalGeneric from '../../../../components/Modal';
import EmptyState from "../../../../components/migrationcomponents/emptystate";
import MarkingChart from './MarkingChart';
import {SiteContext} from '../../../../components/context/SiteContext';
import {Button, Card, DateFilter, FlexContainer, Input, Slidebar, Table, Title} from "@wargostec/react-components";
import {TbExternalLink, TbFileExport} from 'react-icons/tb';
import GridContainer from '../../../../components/flex/GridContainer';
import {warningToast} from '../../../../components/Toast';

const Marking = () => {
  const [close, setClose] = useState(false);
  const {sucursalId} = useContext(SiteContext);

  const [fechas, setFechas] = useState({ini: DateTime.local().toISODate(), fin: DateTime.local().toISODate()});
  let defaultParams = {sucursalId, ...fechas};

  const {register, handleSubmit, setValue, control} = useForm({defaultValues: defaultParams});
  let defaultParams2 = {sucursalId, fecha: fechas.ini};

  const [params, setParams] = useState(defaultParams);
  const [params2, setParams2] = useState(defaultParams2);

  const {loading, data, fetchData, error} = useFetch2(Apis.BASE_API_URL + '/marcaciones/report', [], params, {}, false);
  const {loading: loading2, data: data2, fetchData: fetchData2} = useFetch2(Apis.BASE_API_URL + '/curva-dotacion/report', [], params2, {}, false);

  const {wrapper: wrapper1, someAction: infoMarking} = useModal({
    component: <MarkingDetail />
  })
  const {wrapper: wrapper2, refresh, addAction: addTurn} = useEditModal({
    addComponent: <RecordsNew />
  });

  const getData = (params) => {
    setFechas({ini: params.ini, fin: params.fin})
    setParams(params);
    const newparamas = {sucursalId, fecha: params.ini};
    setParams2(newparamas);
    setClose(!close);
  };

  const exportReport = () => {
    const queryParams = new URLSearchParams(params).toString();

    if (params.employeeId > 0) {
      const link = Apis.BASE_API_URL + `/marcaciones/report.xlsx?${queryParams}`;
      window.location.href = link;
    } else {
      warningToast('Filtre solo una persona para descargar el reporte');
    }
  }

  const getMarkingOff = () => {
    return data.filter((item) => item.horarioName === 'OFF');
  }
  const getMarkingOn = () => {
    return data.filter((item) => item.horarioName !== 'OFF');
  }

  const convertDateHours = (time) => {
    return DateTime.fromISO(time).toFormat('HH:mm');
  }

  const dataOff = getMarkingOff();
  const dataOn = getMarkingOn();

  const actionValue = useCallback((props) => {
    const item = props.row.original;

    return (
      <Button
        size={'xs'}
        variant='light'
        onClick={() => infoMarking({
        values: {
          id: item.employeeId,
          ini: item.fecha,
          fin: item.fecha,
        },
        refreshData: fetchData
      })}
        icon={<TbExternalLink />}
      />
    );
  }, []);

  const formatDate = useCallback(props => <>
    {
      <FlexContainer justifyContent='center'>
        {props.renderValue() ?
          <span>{DateTime.fromISO(props.renderValue()).toFormat("dd'/'LL'")}</span> :
          <span>---</span>}
      </FlexContainer>
    }
  </>
    , []);

  const formatHoraMin = useCallback(props => <>
    {
      <FlexContainer justifyContent='center'>
        {props.renderValue() ?
          <span>{DateTime.fromISO(props.renderValue()).toFormat("HH':'mm")}</span> :
          <span>---</span>}
      </FlexContainer>
    }
  </>
    , []);

  const entradaReg = useCallback((props) => {
    const item = props.row.original;

    return (
      <div style={{cursor:'pointer'}} onClick={() => addTurn({item, mode: true, tipo: 'ENTRADA'})}>
        <FlexContainer justifyContent='center'>
          <strong>{item.entradaReg ? convertDateHours(item.entradaReg) : <MdBlock/>}</strong>
        </FlexContainer>
      </div>
    );
  }, []);

  const salidaReg = useCallback((props) => {
    const item = props.row.original;

    return (
      <div style={{cursor:'pointer'}}  onClick={() => addTurn({item, mode: true, tipo: 'SALIDA'})}>
        <FlexContainer justifyContent='center'>
          <strong>{item.salidaReg ? convertDateHours(item.salidaReg) : <MdBlock/>}</strong>
        </FlexContainer>
      </div>
    );
  }, []);

  const salidaDescansoReg = useCallback((props) => {
    const item = props.row.original;

    return (
      <div
        className='center table-info pointer'
        onClick={() => addTurn({item, mode: true, tipo: 'SALIDA_DESCANSO'})}
        style={{cursor: 'pointer'}}
      >
        <FlexContainer justifyContent='center'>
          <strong>{item.salidaDescansoReg ? convertDateHours(item.salidaDescansoReg) : <MdBlock/>}</strong>
        </FlexContainer>
      </div>
    );
  }, []);

  const entradaDescansoReg = useCallback((props) => {
    const item = props.row.original;

    return (
      <div
        onClick={() => addTurn({item, mode: true, tipo: 'ENTRADA_DESCANSO'})}
        style={{cursor: 'pointer'}}
      >
        <FlexContainer justifyContent='center'>
          <strong>{item.entradaDescansoReg ? convertDateHours(item.entradaDescansoReg) : <MdBlock/>}</strong>
        </FlexContainer>
      </div>
    );
  }, []);

  const tardanzaReg = useCallback((props) => {
    const item = props.row.original;

    return (
      <FlexContainer
        justifyContent='center'
        style={item.tardanza !== '00:00:00' ? {color: 'red', fontWeight: 'bold'} : {color: 'black'}}
      >
        {item.tardanza ? convertDateHours(item.tardanza) : ''}
      </FlexContainer>
    );
  }, []);

  const tExtraReg = useCallback((props) => {
    const item = props.row.original;

    return (
      <FlexContainer justifyContent='center'>
        {item.horaExtra ? convertDateHours(item.horaExtra) : ''}
      </FlexContainer>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        header: 'Fecha',
        accessorKey: 'fecha',
        cell: formatDate,

      },
      {
        header: 'Empleado',
        accessorKey: 'fullname',
      },
      {
        header: 'Horario',
        accessorKey: 'horarioName',
        enableGlobalFilter: false,
        enableSorting: false,
      },
      {
        header: 'Evento',
        accessorKey: 'eventoAbrv',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center text-bold',
          columnClassName: 'text-center text-bold',
        },
      },
      {
        header: 'Entrada',
        accessorKey: 'entrada',
        enableGlobalFilter: false,
        enableSorting: false,
        cell: formatHoraMin,
      },
      {
        header: 'Salida',
        accessorKey: 'salida',
        enableGlobalFilter: false,
        enableSorting: false,
        cell: formatHoraMin,

      },
      {
        header: 'Entrada Reg.',
        accessorKey: 'entradaReg',
        cell: entradaReg,
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'table-info text-center',
          columnClassName: 'table-info text-center',
        },
      },
      {
        header: 'Salida Reg.',
        accessorKey: 'salidaReg',
        cell: salidaReg,
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'table-info text-center',
          columnClassName: 'table-info text-center',
        },
      },
      {
        header: 'Salida D.',
        accessorKey: 'salidaDescansoReg',
        cell: salidaDescansoReg,
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'table-orange text-center',
          columnClassName: 'table-orange text-center',
        },
      },
      {
        header: 'Entrada D.',
        accessorKey: 'entradaDescansoReg',
        cell: entradaDescansoReg,
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'table-orange text-center',
          columnClassName: 'table-orange text-center',
        },
      },
      {
        header: 'T.Descanso',
        accessorKey: 'tiempoDescanso',
        cell: formatHoraMin,
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'T.Asistencia',
        accessorKey: 'tasistencia',
        cell: formatHoraMin,
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'T.Jornada',
        accessorKey: 'tjornada',
        cell: formatHoraMin,
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Tardanza',
        accessorKey: 'tardanza',
        cell: tardanzaReg,
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'T.extra',
        accessorKey: 'horaExtra',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: tExtraReg
      },
      {
        header: 'HE Autorizada',
        accessorKey: 'heSalida',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acción',
        accessorKey: 'id',
        cell: actionValue,
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
    ]
    , []
  )


  useEffect(() => {
    setParams({...params, ...fechas});
    setParams2({
      ...params2,
      fecha: fechas.ini,
    });
  }, [fechas]);

  useEffect(() => {
    fetchData();
    fetchData2();
  }, [params, params2, refresh]);

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Marcaciones de asistencia' type={'page'} />
        </div>
        <Button variant='primary' text='Exportar' onClick={exportReport} icon={<TbFileExport />} />
      </PageSection>
      <PageSection>
        <div style={{marginBottom: "16px"}}>
          <DateFilter dates={fechas} onChange={setFechas} onClickCustom={() => setClose(!close)} monthly weekly />
        </div>
        <Table
          searchable
          columns={columns}
          data={data}
          onFilter={() => setClose(!close)}
          pageSize={20}
          sortArray={[{id:'fullname', asc: false}]}
          emptyListText={
            <EmptyState
              title='Sin resultados'
              subtitle='Haga click en filtros para realizar una nueva consulta.'
              data={data}
              loading={loading}
              error={error}
            />
          }
        />
        <GridContainer style={{marginTop: '16px'}}>
          <Card>
            <strong style={{fontSize: '24px'}}>Personal con descanso semanal</strong>
            <FlexContainer flexDirection='column' style={{marginTop: '16px'}}>
              {dataOff.length > 0 && dataOff ? dataOff.map((item, index) => {
                return <span key={index}>- {item.fullname}</span>
              }
              ) : <span>No hay personal con descanso semanal</span>
              }
            </FlexContainer>
          </Card>
          <MarkingChart data={data2} params={params2} fetchData={fetchData2} loading={loading2} />
        </GridContainer>
      </PageSection>
      <Slidebar
        title='Filtrar'
        toggler={() => setClose(!close)}
        isOpen={close}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
            marginTop: '1em',
          }}
        >
          <Input
            label={"Fecha de inasistencia"}
            type='date'
            name={'fecha'}
            placeholder='Desde'
            {...register('ini')}
          />
          <Input
            type='date'
            name={'fecha'}
            placeholder='Hasta'
            {...register('fin')}
          />
          <label>Empleado</label>
          <Controller name="employeeId" control={control} render={({field}) => <CustomerSearchInput {...field} />} />
          <Button variant='primary' onClick={handleSubmit(getData)} text={'BUSCAR'} icon={<FaIcons.FaSearch />} />
          {/* <Button variant='light' onClick={resetForm}>
            <FaIcons.FaEraser />
            LIMPIAR
          </Button> */}
        </div>
      </Slidebar>
      <ModalInfo {...wrapper1} />
      <ModalGeneric {...wrapper2} />
    </>
  );
};

export default Marking;
