import React, {useEffect} from 'react';
import {Apis} from '../../../../utils/Apis';
import axios from 'axios';
import {Controller, useForm} from 'react-hook-form';
import FileUploaderController from "../../../../components/FileUploaderController";

const HolidaysDocEdit = ({ id, setAction, setTitle }) => {
  const { control, handleSubmit, reset } = useForm();

  const update = (params) => {
    return axios.put(Apis.BASE_API_URL + `/convenio-feriado/${id.id}`, params);
  };

  useEffect(() => {
    setTitle('Documento');
  }, []);

  useEffect(() => {
    setAction(() => handleSubmit(update));
  }, []);


  useEffect(() => {
    if (id) {
      reset(id);
    }
  }, [id]);

  return (
    <Controller
      name='filePath'
      control={control}
      render={({ field: { value, onChange } }) => (
        <FileUploaderController value={value} onChange={onChange} />
      )}
    />
  )
};

export default HolidaysDocEdit;
