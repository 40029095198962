import React, { useEffect, useContext } from 'react';
import { Apis } from '../../../../utils/Apis';
import axios from 'axios';
import RecordsForm from './RecordsForm';
import { SiteContext } from '../../../../components/context/SiteContext';

const RecordsNew = ({ item, setAction, setTitle, mode, tipo}) => {
  const { sucursalId } = useContext(SiteContext);
  const save = (params) => {
    const body = {
      deleted: 0,
      employeeId: params.employeeId,
      fecha: params.fecha,
      hora: params.hora,
      source: 'MANUAL',
      sucursalId: sucursalId,
      tipo: params.tipo,
    }
    return axios.post(Apis.BASE_API_URL + '/marcaciones', body);
  }

  useEffect(() => {
    setTitle('Nuevo Registro');
  }, []);

  return <RecordsForm value={item} onSubmit={save} setAction={setAction} mode={mode} tipo={tipo} />
};

export default RecordsNew;
