import React, { useState, useEffect, useContext } from "react";
import PageSection from '../../../../components/layout/PageSection';
import styled from "styled-components";
import { DateTime } from 'luxon';
import useFetch2 from '../../../../hooks/useFetch2';
import { Apis } from '../../../../utils/Apis';
import Table from 'react-bootstrap/Table';
import useGroupBy from '../../../../hooks/useGroupBy';
import ContentLoading from '../../../../components/flex/ContentLoading';
import * as FaIcons from "react-icons/fa";
import SelectSchedule from '../../../../components/selects/SelectSchedule';
import { SiteContext } from '../../../../components/context/SiteContext';
import {Button, Card, DateFilter, Input, Title} from "@wargostec/react-components";
import {Loading} from "../../../../components/migrationcomponents/loading";
import GridContainer from "../../../../components/flex/GridContainer";

const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const AssistanceReport = () => {
  const { sucursalId } = useContext(SiteContext);
  const [fechas, setFechas] = useState({
    ini: DateTime.local().startOf('month').toISODate(),
    fin: DateTime.local().endOf('month').toISODate(),
  });
  const [dataFilter, setDataFilter] = useState(
    []
  );
  const [assistance, setAssistance] = useState('TARDANZA');
  let defaultParams = { sucursalId, ...fechas };
  const [params, setParams] = useState(defaultParams);
  const { loading, data, fetchData } = useFetch2(
    Apis.BASE_API_URL + '/turno-dia/report-asistencia',
    [],
    params,
    {},
    false
  );
  const getText = (turnoDiaMarcacion) => {
    let text = '';
    if (turnoDiaMarcacion !== null) {
      let jornada = turnoDiaMarcacion.tjornada;
      if (jornada !== null) {
        if (assistance === 'JORNADA' && turnoDiaMarcacion.tjornada != null) {
          text = turnoDiaMarcacion.tjornada.substring(0, 5);
        }
        if (assistance === 'TARDANZA' && turnoDiaMarcacion.tardanza != null) {
          text = turnoDiaMarcacion.tardanza.substring(0, 5);
        }
      } else {
        text = turnoDiaMarcacion.horario === 'OFF' ? 'OFF' : 'F';
      }
    } else {
      text = '--';
    }
    return text;
  }
  const handleSearch = e => {
    const search = e.target.value;
    const dataFilter = data?.data.filter(user => user.fullname.toLowerCase().includes(search.toLowerCase())
    )
    setDataFilter({ ...data, data: dataFilter });
  }
  const handleSearchSelect = e => {
    const search = e.target.options[e.target.selectedIndex].text;
    setAssistance(search);
  }
  const { dataGroup } = useGroupBy(dataFilter?.data, 'cargo');

  const style = (item) => {
    let style = {};
    if (item?.horarioName === 'OFF') {
      style = { backgroundColor: '#f69191' };
    }
    if (DateTime.fromISO(item?.fecha).weekdayShort === 'dom') {
      style = { borderRight: '2px solid darkslategray' };
      if (item?.eventoColor || item?.eventoColorSec) {
        style = { backgroundColor: item?.eventoColor, color: item?.eventoColorSec, borderRight: '2px solid darkslategray' };
      }
    };
    if (item?.eventoColor || item?.eventoColorSec) {
      style = { backgroundColor: item?.eventoColor, color: item?.eventoColorSec };
      if (DateTime.fromISO(item?.fecha).weekdayShort === 'dom') {
        style = { backgroundColor: item?.eventoColor, color: item?.eventoColorSec, borderRight: '2px solid darkslategray' };
      }
    }

    return style;
  }

  useEffect(() => {
    setParams({ ...params, ...fechas })
  }, [fechas])
  useEffect(() => {
    fetchData();
  }, [params]);
  useEffect(() => {
    setDataFilter(data);
  }, [data]);
  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Calendario de asistencia' type={'page'}/>
        </div>
        <Button onClick={() => fetchData()} variant='primary' icon={<FaIcons.FaSync />} text={'Actualizar'}/>
      </PageSection>
      <PageSection>
        <div style={{marginBottom: "16px"}}>
          <DateFilter dates={fechas} onChange={setFechas} monthly weekly defaultMode={"monthly"}/>
        </div>
        <GridContainer>
          <Input placeholder='Filtrar por nombre' icon={<FaIcons.FaSearch />} onChange={handleSearch} />
          <SelectSchedule onChange={handleSearchSelect} />
        </GridContainer>
        {
          loading ? <ContentLoading>
            <Loading variant='secondary' />
          </ContentLoading> :
            <Card noPadding>
              <Table hover bordered responsive size="sm">
                <thead>
                  <tr>
                    <th></th>
                    {data?.fechas && data?.fechas.map((item, index) => {
                      return (
                        <th key={index} className="text-center" style={{fontSize: '13px', fontWeight: '400'}} >
                          {DateTime.fromISO(item).toFormat('dd')}
                        </th>
                      )
                    })
                    }
                  </tr>
                  <tr>
                    <th style={{fontSize: '13px', fontWeight: '400'}}>EMPLEADOS</th>
                    {data?.fechas && data?.fechas.map((item, index) => {
                      return (
                        <th key={index} className="text-center" style={{fontSize: '13px', fontWeight: '400', textTransform: 'capitalize'}}>
                          {DateTime.fromISO(item).weekdayShort}
                        </th>
                      )
                    })
                    }
                  </tr>
                </thead>
                <tbody>
                  {dataGroup && Object.keys(dataGroup).map((key, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td className='head' style={{fontSize: '13px', fontWeight: '400'}}>
                            <strong>
                              {key} ({dataGroup[key].length})
                            </strong>
                          </td>
                        </tr>

                        {
                          dataGroup[key].map((item, index) => {
                            return (
                              <tr key={index}>
                                <td style={{fontSize: '13px', fontWeight: '400'}}>{item.fullname}</td>
                                {
                                  item.detalle.map((item2, index2) => {
                                    return (
                                      <td 
                                        key={index2} 
                                        style={{fontSize: '13px', fontWeight: '400', textAlign: 'center', cursor: 'pointer', ...style(item2)}}
                                      >
                                        {getText(item2.turnoDiaMarcacion)}
                                      </td>
                                    )
                                  })
                                }
                              </tr>
                            )
                          })
                        }
                      </>
                    )
                  })
                  }
                </tbody>
              </Table>
            </Card>
        }
      </PageSection>
    </>
  );
};

export default AssistanceReport;
