import React, {useEffect, useContext, useMemo, useCallback} from "react";
import * as FaIcons from "react-icons/fa";
import {Apis} from "../../../../utils/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import PageSection from "../../../../components/layout/PageSection";
import ModalGeneric from "../../../../components/Modal";
import DeleteGenericModal from "../../../../components/modals/DeleteGenericModal";
import useEditModal from "../../../../hooks/useEditModal";
import useDeleteModal from "../../../../hooks/useDeleteModal";
import axios from "axios";
import ScheduleEdit from "./ScheduleEdit";
import ScheduleAdd from "./ScheduleAdd";
import {SiteContext} from '../../../../components/context/SiteContext';
import {Button, Table, Title,FlexContainer} from "@wargostec/react-components";
import {ProgressBar} from "../../../../components/migrationcomponents/ProgressBar";
import {TableLoading} from "../../../../components/migrationcomponents/tableloading";
import {LuFileEdit, LuTrash2} from "react-icons/lu";

const Schedule = () => {
  const {sucursalId} = useContext(SiteContext);
  const baseURL = Apis.BASE_API_URL + "/horarios";

  const {loading, data, fetchData, error} = useFetch2(baseURL + `?sucursalId=${sucursalId}`,[]);

  const {wrapper, refresh, addAction, editAction} = useEditModal({
    addComponent: <ScheduleAdd/>,
    editComponent: <ScheduleEdit/>,
  });

  const {
    wrapper: wrapper1,
    refresh: refresh1,
    openDeleteModal,
  } = useDeleteModal({text: "¿Desea eliminar esta horario?"});

  const deleteElement = (areaId) =>
    axios.delete(baseURL + `/${areaId}`);

  const horarioValue = useCallback((props) => {
    const x = props.row.original

    const ini = x.entrada ? x.entrada.slice(0, 5) : '';
    const fin = x.salida ? x.salida.slice(0, 5) : '';

    return (
      <div style={{width: '25em'}}>
        {ini + "-" + fin}
        <ProgressBar ini={ini} fin={fin}/>
      </div>
    )
  }, [])

  const colorValue = useCallback((props) => {
    const x = props.row.original;

    return (
      <FaIcons.FaSquare size={26} color={x.color === null ? "#fff" : x.color} />
    );
  }, []);

  const actionValue = useCallback((props) => {

    const id = props.renderValue()

    return (
      <>
      <FlexContainer flexDirection="row" gap="8px" justifyContent="center" alignItems="center">
          <Button 
            size={'xs'} 
            variant='light' 
            onClick={() => editAction(id)}
            icon={<LuFileEdit />}
            text='Editar' 
          />
          <Button 
            size={'xs'} 
            variant='danger' 
            onClick={() => openDeleteModal(id, deleteElement)}
            icon={<LuTrash2 />}
            text='Eliminar'
            />
        </FlexContainer>
      </>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        header: 'Color',
        accessorKey: 'color',
        enableGlobalFilter:false,
        enableSorting:false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: colorValue,
        
      },
      {
        header: 'Abreviatura',
        accessorKey: 'name',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Descripción',
        accessorKey: 'description',
      },
      {
        header: 'Horario',
        accessorKey: '',
        enableGlobalFilter:false,
        enableSorting:false,
        cell: horarioValue,
      },
      {
        header: 'Minutos de descanso',
        accessorKey: 'minutosDescanso',
        enableGlobalFilter:false,
        enableSorting:false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acción',
        accessorKey: 'id',
        enableGlobalFilter:false,
        enableSorting:false,
        cell: actionValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
    ]
    , []
  )

  useEffect(() => {
    fetchData();
  }, [refresh, refresh1]);

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Horarios' type={'page'}/>
        </div>
      </PageSection>

      <PageSection>
        <Table searchable columns={columns} data={data} onAdd={addAction} pageSize={20}/>
      </PageSection>
      <ModalGeneric {...wrapper} />
      <DeleteGenericModal {...wrapper1} />
    </>
  );
};

export default Schedule;
