import { createGlobalStyle } from "styled-components";

export const CreateStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
  }
  body {
    background: #fafafa;
    color: #0a0a0a;
    font-weight: 400;
  }

  .table-orange {
    background-color: #efd797 !important;
  }  

  .head {
    font-weight: 400;
    background-image: linear-gradient(45deg, #0674db, #069cf9);
    color: #fff !important;
  }
  td{
    background-color:transparent;
    vertical-align:middle;
  }

  li{
    text-decoration: none;
  }

  a{
    text-decoration: none;
  }

  .myActiveLink span{
    background:#404040;
    border-radius:6px;
  }

  .font-sm{
    font-size:14px;
  }

  .font-xs{
    font-size:12px;
  }

  .text-bold{
    font-weight:700;
  }
`;
