import React, { useEffect, useContext } from 'react';
import AssignmentForm from './AssignmentForm';
import axios from "axios";
import { Apis } from "../../../../utils/Apis";
import { SiteContext } from '../../../../components/context/SiteContext';

const AssignmentNew = ({ item, setAction, setTitle }) => {
  const { sucursalId } = useContext(SiteContext);
  const create = (params) => {
    let url = "/turno-dia"
    params.employeeId = item.employeeId;
    params.sucursalId = sucursalId;
    params.ini = params.fecha
   if (params.type == 'TURNO') {
     url = "/turno-dia/by-turno"
   }
    return axios.post(Apis.BASE_API_URL + url, params);
  };

  useEffect(() => {
    setTitle(item.name);
  }, []);

  return <AssignmentForm value={item.data} onSubmit={create} setAction={setAction} />;
};

export default AssignmentNew;
