import axios from 'axios';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {errorToast, successToast} from '../../../../components/Toast';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../utils/Apis';
import MeasureForm from './MeasureForm';

const MeasureEdit = () => {
	const {id} = useParams();
	const navigate = useNavigate();
	const baseURL = Apis.BASE_API_URL + '/medidas';
	const {data} = useFetch2(baseURL + `/${id}`,[]);

	const update = (params) => {
		return axios
			.put(baseURL + `/${id}`, params)
			.then((res) => {
				successToast('Los cambios se registraron correctamente');
				navigate('/medidas');
			})
			.catch((err) => {
				errorToast('Ocurrio un problema. Por favor intente nuevamente');
			});
	};

	return <MeasureForm value={data} onSubmit={update} edit={true} />;
};

export default MeasureEdit;
