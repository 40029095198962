import React from 'react';
import styled from 'styled-components';

const ContentLoadingStyled = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentLoading = ({ children }) => {
  return <ContentLoadingStyled>{children}</ContentLoadingStyled>;
};

export default ContentLoading;
