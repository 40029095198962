import axios from 'axios';
import React, { useContext } from 'react'
import { errorToast, successToast } from '../../../../components/Toast';
import { Apis } from '../../../../utils/Apis';
import MeasureForm from './MeasureForm'
import { useNavigate } from 'react-router-dom';
import { SiteContext } from '../../../../components/context/SiteContext';

const MeasureNew = () => {
  const { empresaId, sucursalId } = useContext(SiteContext);
  const navigate = useNavigate();
  const baseURL = Apis.BASE_API_URL + "/medidas";
  const save = (params) => {
    params.empresaId = empresaId;
    params.sucursalId = sucursalId;
    return axios.post(baseURL, params)
      .then((res) => {
        successToast("Los cambios se registraron correctamente");
        navigate('/medidas');
      }).catch((err) => {
        errorToast("Ocurrio un problema. Por favor intente nuevamente");
      });
  };

  return (
    <MeasureForm edit={false} onSubmit={save} />
  )
}

export default MeasureNew