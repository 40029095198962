import React from "react";
import {Select} from "@wargostec/react-components";

const SelectCargo = ({data,loading,register}) => {

  const newData = data && data.map((item) => {
    return {
      name: item.name,
      id: item.id,
      value: item.id,
    };
  });

  return (
    <>
      {data && !loading ?
        <Select {...register('cargoId')} >
          {newData.map((item) => (
            <option key={item.id} value={item.value}>
              {item.name}
            </option>
          ))}
        </Select>
        :
        <Select disabled>
          <option value="0">Cargando...</option>
        </Select>
      }
    </>
  )
}

export default SelectCargo
