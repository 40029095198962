import React from "react";
import {Select} from "@wargostec/react-components";

const SelectRegime = React.forwardRef((props, ref) => {
  const data = [
    { name: "TODOS", value: "", id:1},
    { name: "4Cat | Recibo", value: "CAT_4", id:2 },
    { name: "5Cat | Planilla", value: "CAT_5", id:3 }
  ];

  return (
    <>
      <Select ref={ref} {...props}>
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  );
});

export default SelectRegime;
