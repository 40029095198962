import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { errorToast, successToast } from "./Toast";
import {Button} from "@wargostec/react-components";

const ModalGeneric = ({ body, onHide, ...props }) => {
  const [action, setAction] = useState(null);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  function doModalAction() {
    setLoading(true);
    action()
      .then((res) => {
        setLoading(false);
        onHide();
        successToast("Los cambios se registraron correctamente")
      })
      .catch((err) => {
        let toastrTxt = "Ocurrio un problema. Por favor intenta nuevamente"
        if (err.response !== undefined) {
          toastrTxt = err.response.data.message
        }
        errorToast(toastrTxt)
        setLoading(false);
      });
  }

  return (
    <Modal onHide={onHide} centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          body &&
          <div style={{ display: 'block', flexDirection: 'row' }}>
            {React.cloneElement(body, { setAction, setTitle })}
          </div>
        }
      </Modal.Body>

      <Modal.Footer>
        <Button variant={'light'} onClick={onHide} text={'Cerrar'}/>
        <Button variant="primary" onClick={doModalAction} disabled={loading} text={!props.button? "Guardar" : props.button}/>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalGeneric;
