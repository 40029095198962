import React from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";

const TardyReport = () => {
  return (
    <PageSection>
      <div style={{marginBottom: '1rem'}}>
        <Title text='Reporte de tardanza' type={'page'}/>
      </div>
    </PageSection>
  );
};

export default TardyReport;
