import React from "react";
import styled from "styled-components";

const ContainerBar = styled.div`
  background: #f5f5f5;
  width: 100%;
  height: 8px;
  border-radius:3px;
  box-shadow:rgb(0 0 0 / 5%) 0 0 4px 0;
`;
const ContainerProgrees = styled.div`
  background: #0062cc;
  margin-left: ${({ left }) => (left ? left : "0px")};
  width: ${({ width }) => (width ? width : "0%")};
  color: transparent;
  height: 8px;
  border-radius:3px;
`;

export const ProgressBar = ({ ini, fin }) => {
  const barTotal = 48 * 60;
  const transformHour = (hour) => {
    const parts = hour.split(":");
    return parseInt(parts[0]) * 60 + parseInt(parts[1]);
  };

  const result =
    (Math.abs(transformHour(ini) - transformHour(fin)) * 100) / barTotal;

  const initial = (transformHour(ini) * 100) / barTotal;

  return (
    <ContainerBar>
      <ContainerProgrees left={initial + `%`} width={result + `%`}>.
      </ContainerProgrees>
    </ContainerBar>
  );
};
