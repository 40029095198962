import React, {useState, useEffect, useContext} from "react";
import * as FaIcons from "react-icons/fa";
import PageSection from '../../../../components/layout/PageSection';
import SelectCargo from "../../../../components/selects/SelectCargo";
import {DateTime} from 'luxon';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../utils/Apis';
import Table from 'react-bootstrap/Table';
import useGroupBy from '../../../../hooks/useGroupBy';
import ModalGeneric from '../../../../components/Modal';
import useEditModal from '../../../../hooks/useEditModal';
import AssignmentEdit from './AssignmentEdit';
import {Loading} from "../../../../components/migrationcomponents/loading";
import ContentLoading from '../../../../components/flex/ContentLoading';
import MarkingChart from '../../attendance/markings/MarkingChart';
import AssignmentNew from './AssignmentNew';
import {SiteContext} from '../../../../components/context/SiteContext';
import {Button, Card, DateFilter, Input, Title,FlexContainer, Tooltip} from "@wargostec/react-components";
import GridContainer from "../../../../components/flex/GridContainer";
import {TbFileExport} from "react-icons/tb";
import {LuRefreshCcw} from "react-icons/lu";

const Assignment = () => {
  const {sucursalId} = useContext(SiteContext);
  const [fechas, setFechas] = useState({
    ini: DateTime.local().startOf('month').toISODate(),
    fin: DateTime.local().endOf('month').toISODate(),
  });
  let defaultParams2 = {sucursalId, fecha: DateTime.local().toISODate()};
  let defaultParams = {sucursalId, ...fechas};
  const [params, setParams] = useState(defaultParams);
  const [params2, setParams2] = useState(defaultParams2);
  const [dataFilter, setDataFilter] = useState([]);
  const {loading, data, fetchData} = useFetch2(Apis.BASE_API_URL + '/turno-dia/report', [], params, {}, false);
  const {loading: loading2, data: data2, fetchData: fetchData2} = useFetch2(Apis.BASE_API_URL + '/curva-dotacion/report', [], params2, {}, false);

  const {wrapper, refresh, editAction, addAction} = useEditModal({
    editComponent: <AssignmentEdit />,
    addComponent: <AssignmentNew />,
  });

  const exportReport = () => {
		const queryParams = new URLSearchParams(params).toString();
		const link = Apis.BASE_API_URL +`/turno-dia/report.xlsx?${queryParams}`;
    window.location.href = link;
	};

  const handleSearch = e => {
    const search = e.target.value;
    const dataFilter = data?.data.filter(user => user.fullname.toLowerCase().includes(search.toLowerCase())
    )
    setDataFilter({...data, data: dataFilter});
  }

  const handleSearchSelect = e => {
    const search = e.target.options[e.target.selectedIndex].text;
    const dataFilter = data?.data.filter(user => user.cargo === search
    )
    setDataFilter({...data, data: dataFilter});
  }

  const {dataGroup} = useGroupBy(dataFilter?.data, 'cargo');

  const style = (item) => {
    let style = {};
    if (item?.horarioName === 'OFF') {
      style = {backgroundColor: '#f69191'};
    }
    if (DateTime.fromISO(item?.fecha).weekdayShort === 'dom') {
      style = {borderRight: '2px solid darkslategray'};
      if (item?.eventoColor || item?.eventoColorSec) {
        style = {backgroundColor: item?.eventoColor, color: item?.eventoColorSec, borderRight: '2px solid darkslategray'};
      }
      if (item?.horarioName === 'OFF') {
        style = {backgroundColor: '#f69191', borderRight: '2px solid darkslategray'};
      }
    };
    if (item?.eventoColor || item?.eventoColorSec) {
      style = {backgroundColor: item?.eventoColor, color: item?.eventoColorSec};
      if (DateTime.fromISO(item?.fecha).weekdayShort === 'dom') {
        style = {backgroundColor: item?.eventoColor, color: item?.eventoColorSec, borderRight: '2px solid darkslategray'};
      }
    }

    return style;
  }

  const updatechart = (item) => {
    setParams2({...params2, fecha: item});
  }

  useEffect(() => {
    setParams({...params, ...fechas})
  }, [fechas])

  useEffect(() => {
    fetchData();
  }, [refresh]);

  useEffect(() => {
    fetchData();
  }, [params]);

  useEffect(() => {
    fetchData2();
  }, [params2]);

  useEffect(() => {
    setDataFilter(data);
  }, [data]);

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Asignación de empleados' type={'page'} />
        </div>
        <FlexContainer gap='8px'>
          <Button variant='primary' text='Exportar'onClick={exportReport} icon={<TbFileExport/>} />
          <Button variant='dark' onClick={() => fetchData()} text='Actualizar' icon={<LuRefreshCcw />}/>
        </FlexContainer>
      </PageSection>
      <PageSection>
        <div style={{marginBottom: "16px"}}>
          <DateFilter dates={fechas} onChange={setFechas} monthly weekly defaultMode={"monthly"} />
        </div>
        <GridContainer>
          <Input placeholder='Filtrar por nombre' icon={<FaIcons.FaSearch />} onChange={handleSearch} />
          <SelectCargo onChange={handleSearchSelect}/>
        </GridContainer>
        <Card noPadding>
          <Table hover bordered responsive size="sm">
            <thead>
            <tr>
              <th></th>
              {dataFilter?.fechas && dataFilter?.fechas.map((item, index) => {
                return (
                  <th key={index} className="text-center" style={{fontSize: '13px', fontWeight: '400'}}
                      onClick={() => updatechart(item)}>
                    {DateTime.fromISO(item).toFormat('dd')}
                  </th>
                )
              })
              }
            </tr>
            <tr>
              <th style={{fontSize: '13px', fontWeight: '400'}}>EMPLEADOS</th>
              {data?.fechas && data?.fechas.map((item, index) => {
                return (
                  <th key={index} className="text-center"
                      style={{fontSize: '13px', fontWeight: '400', textTransform: 'capitalize'}}>
                    {DateTime.fromISO(item).weekdayShort}
                  </th>
                )
              })
              }
            </tr>
            </thead>
            <tbody>
            {dataGroup && Object.keys(dataGroup).map((key, index) => {
              return (
                <>
                  <tr key={index}>
                    <td className='head' style={{fontSize: '13px', fontWeight: '400'}}>
                      <strong>{key} </strong>
                      <span>({dataGroup[key].length})</span>
                    </td>
                  </tr>
                  {dataGroup[key].map((item, index) => {
                    return (
                      <tr key={index}>
                        <td style={{fontSize: '13px', fontWeight: '400'}}>{item.fullname}</td>
                        {item.detalle.map((item2, index2) => {
                          return (
                            <td
                              key={index2}
                              style={{
                                fontSize: '13px',
                                fontWeight: '400',
                                textAlign: 'center',
                                cursor: 'pointer', ...style(item2)
                              }}
                              onClick={item2.horarioName === '--' ?
                                () => addAction({item: {employeeId: item.id, name: item.fullname, data: item2}})
                                :
                                () => editAction({
                                  turnoDiaId: item2.turnoDiaId,
                                  employeeId: item.id,
                                  name: item.fullname,
                                  data: item2
                                })
                              }
                            >
                              <Tooltip
                                text={
                                  DateTime.fromISO(item2.fecha).toFormat('dd/MM') + ' ' +
                                  (item2.horarioName !== '--' && item2.horarioName !== 'OFF' ?
                                    (DateTime.fromISO(item2.entrada).toFormat('hh:mm') + ' a ' +
                                      DateTime.fromISO(item2.salida).toFormat('hh:mm')) : '')
                                }
                                direction="top"
                              >
                                {item2.evento ? item2.evento : item2.horarioName}
                              </Tooltip>
                            </td>
                          )
                        })
                        }
                      </tr>
                    )
                  })
                  }
                </>
              )
            })
            }
            </tbody>
          </Table>
        </Card>
        <div style={{marginTop: '16px'}}>
          <MarkingChart data={data2} params={params2} fetchData={fetchData2} loading={loading2} />
        </div>
      </PageSection>
      <ModalGeneric {...wrapper} />
    </>
  );
};

export default Assignment;
