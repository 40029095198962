import React from "react";
import {Select} from "@wargostec/react-components";

const SelectType = React.forwardRef((props, ref) => {
  const data = [
    { name: "TODOS", value: "" , id:0},
    { name: "AMONESTACIÓN VERBAL", value: "AMONESTACION_VERBAL",id:1},
    { name: "AMONESTACION ESCRITA", value: "AMONESTACION_ESCRITA",id:2},
    { name: "SUSPENCION", value: "SUSPENSION",id:3},
  ];

  return (
    <>
      <Select ref={ref} {...props} >
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  );
});

export default SelectType;
