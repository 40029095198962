import {useState} from "react";
import { errorToast, successToast } from '../components/Toast';

//Hook para centralizar logica y estado necesario
//para manejar para elimianr entidades en la base de datos
//si la operacion es exitosa, debemos indicar que el estado ha cambiado y se debe refrescar las vista
//en caso de error mostramos uno mensaje en el mismo modal
const useDeleteModal = ({text}) => {

  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(1)

  //saved for later use
  const [id, setId] = useState(null);
  const [action, setAction] = useState(null);

  const openDeleteModal = (id, action) => {
    //save function and id for later use
    setId(id)
    setAction(() => action)

    //open modal
    setShow(true)
  }

  //TODO, incluir parametro para ver si debemos refrecar o no
  const onCloseDeleteModal = () => {
    //fetchData()
    setShow(false)
    setRefresh(refresh + 1)
  }

  const executeAction = () => {
    action(id).then(res => {
      setRefresh(refresh + 1)
      setShow(false)
      successToast("Se elimino correctamente")
    }).catch(err => {
      errorToast("Ocurrio un problema. Por favor intente nuevamente")
    })
  }

  const wrapper = {
    show: show,
    onHide: onCloseDeleteModal,
    'action': executeAction,
    text : text
  }

  //TODO, podriamos agregar una funcion similar a la de react hook form : handleSubmit
  //para evitar guardar la action en este hook como estado
  //return {show, id, refresh, executeAction, openDeleteModal, onCloseDeleteModal}
  return {wrapper, id, refresh, openDeleteModal}
}

export default useDeleteModal
