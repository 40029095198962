import React, { useContext } from "react";
import useFetch2 from "../../../../hooks/useFetch2";
import { Apis } from "../../../../utils/Apis";
import PageSection from '../../../../components/layout/PageSection';
import { SiteContext } from '../../../../components/context/SiteContext';
import {Table, Title} from "@wargostec/react-components";
import {TableLoading} from "../../../../components/migrationcomponents/tableloading";
import EmptyState from "../../../../components/migrationcomponents/emptystate";

const VacationReport = () => {
  const { sucursalId } = useContext(SiteContext);
  const baseURL = Apis.BASE_API_URL + "/vacaciones-sol/resumen";
  const params = { sucursalId };
  const { loading, data, error } = useFetch2(baseURL, [], params);

  const columns = [
    { 
      header: "Empleado", 
      accessorKey: "fullname" ,
    },
    { 
      header: "Documento", 
      accessorKey: "document",
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    { 
      header: "Inicio", 
      accessorKey: "iniContrato",
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    { 
      header: "Cese", 
      accessorKey: "fechaCese",
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    { 
      header: "Años cumplidos", 
      accessorKey: "añosCumplidos",
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      }, 
    },
    { 
      header: "Mes periodo actual", 
      accessorKey: "meses",
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    { 
      header: "Vacaciones/periodo actual", 
      accessorKey: "vacacionesPeriodoActual",
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
  ];

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Resumen vacaciones' type={'page'}/>
        </div>
      </PageSection>
      <PageSection>
        <Table 
          columns={columns} 
          data={data}
          searchable
          pageSize={20}
          emptyListText={
            <EmptyState
              title='Sin resultados'
              subtitle='Haga click en filtros para realizar una nueva consulta.'
              data={data}
              loading={loading}
              error={error}
            />
          }
        />
      </PageSection>
    </>
  );
};

export default VacationReport;
