import React from "react";
import {Select} from "@wargostec/react-components";

const SelectStatus = React.forwardRef((props, ref) => {
  const data = [
    { name: "TODOS", value: "", id:1},
    { name: "ACTIVOS", value: "ACTIVO", id:2 },
    { name: "CESADOS", value: "CESADO", id:3 }
  ];

  return (
    <>
      <Select ref={ref} {...props} >
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  );
});

export default SelectStatus;
