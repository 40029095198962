import React, {useState, useContext, useCallback} from 'react';
import styled from 'styled-components';
import {useWauth} from '../auth/WauthProvider';
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';
import * as Hi2Icons from 'react-icons/hi2';
import * as BiIcons from 'react-icons/bi';
import {useNavigate, Outlet} from 'react-router-dom';
import {SiteContext, SiteProvider} from '../components/context/SiteContext';
import {
	Layout as WargosLayout,
	SidebarBody,
	SidebarItem,
	SidebarFooter,
	MenuList,
	Menu,
	Button,
	Avatar
} from '@wargostec/react-components';
import logo from '../assets/logo.png';
import {RoomProvider} from '../components/context/RoomContext';
import axios from 'axios';
import MenuItem from '../components/layout/MenuItem';

axios.interceptors.request.use(function (config) {
	const token = JSON.parse(localStorage.getItem('currentUser')).token;
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response) {
			if (401 === error.response.status) {
				window.location = '/logout';
				return Promise.reject(error);
			} else {
				return Promise.reject(error);
			}
		} else {
			return Promise.reject(error);
		}
	}
);

const MenuProfile = styled.div`
	position: fixed;
	top: 60px;
	right: 10px;
	background-color: white;
	box-sizing: border-box;
	box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
	padding: 14px;
	border-radius: 6px;
	flex-direction: column;
	align-items: center;
	gap: 6px;
	width: 180px;

	display: ${(props) => (props.showProfile ? 'flex' : 'none')};

	& > span:first-child {
		font-size: 14px;
		font-weight: bold;
		color: #555;
		max-width: 100%;
		overflow: hidden;
		text-align: center;
	}

	& > span:last-child {
		font-size: 12px;
		font-weight: 500;
		color: #555;
		cursor: pointer;
		user-select: none;
	}

	& > div {
		height: 1px;
		width: 100%;
		background-color: #e9ecef;
	}
`;

const Layout = () => {
	const navigate = useNavigate();
	const [showProfile, setShowProfile] = useState(false);
	const {isAuthenticated, loginWithRedirect} = useWauth();

	const name = localStorage.getItem('fullname');
	const fullname = name?.replace(/['"]+/g, '');
	const fullnameInitial =
		fullname?.split(' ').at(0).split('')[0] +
		fullname?.split(' ').at(1).split('')[0];

	const Items = () => {
		return (
			<>
				<SidebarBody>
					<SidebarItem active={true} icon={<MdIcons.MdPeople size={20} />} />
				</SidebarBody>
				<SidebarFooter>
					<SidebarItem
						icon={
							<MdIcons.MdSecurity
								size={20}
								onClick={() =>
									window.open(
										'https://admin.wargos.cloud',
										'_blank',
										'noreferrer'
									)
								}
							/>
						}
					/>
				</SidebarFooter>
			</>
		);
	};

	const Menus = useCallback(() => {
		return (
			<MenuList>
				<Menu title='Empleados' icon={<FaIcons.FaUsers size={20} />}>
					<MenuItem title='Áreas' path='areas' />
					<MenuItem title='Cargos' path='cargos' />
					<MenuItem title='Empleados' path='personas' />
				</Menu>
				<Menu title='Asistencia' icon={<FaIcons.FaBusinessTime size={20} />}>
					<MenuItem title='Marcaciones' path='marcaciones' />
					<MenuItem title='Registros' path='registros' />
				</Menu>
				<Menu
					title='Programación Turnos'
					icon={<FaIcons.FaBusinessTime size={20} />}
				>
					<MenuItem title='Asignación' path='asignacion' />
					<MenuItem title='Asignación masiva' path='asignacion-masiva' />
					<MenuItem title='Horarios' path='horarios' />
					<MenuItem title='Restricciones' path='restricciones' />
					<MenuItem title='Turnos' path='turno' />
				</Menu>
				<Menu title='Eventos' icon={<FaIcons.FaCalendarAlt size={20} />}>
					<MenuItem title='Disciplinarias' path='medidas' />
					<MenuItem title='Feriados' path='convenio-feriados' />
					<MenuItem title='Tipos de evento' path='tipo-de-evento' />
				</Menu>
				<Menu title='Justificaciones' icon={<FaIcons.FaUserMd size={20} />}>
					<MenuItem title='Atrasos' path='atrasos' />
					<MenuItem title='Inasistencia' path='inasistencia' />
				</Menu>
				<Menu title='Solicitudes' icon={<FaIcons.FaPaperclip size={20} />}>
					<MenuItem title='Horas extra' path='horas-extras' />
					<MenuItem title='Permisos de salida' path='permisos' />
					<MenuItem title='Vacaciones' path='vacaciones-solicitudes' />
				</Menu>
				<Menu title='Reportes' icon={<FaIcons.FaListUl size={20} />}>
					<MenuItem title='Asistencia' path='reporte-asistencia' />
					<MenuItem title='Tardanza' path='reporte-tardanza' />
					<MenuItem title='Vacaciones' path='reporte-vacaciones' />
				</Menu>
				<Menu title='Alertas' icon={<FaIcons.FaBell size={20} />}>
					<MenuItem title='Configuración' path='alertas' />
					<MenuItem title='Grupo Análisis' path='grupo-analisis' />
					<MenuItem title='Grupo Notificación' path='grupo-notificaciones' />
				</Menu>
				<Menu title='Reloj' icon={<FaIcons.FaStopwatch size={20} />}>
					<MenuItem title='Reloj' path='relojes' />
				</Menu>
				<Menu title='Talento' icon={<FaIcons.FaAdjust size={20} />}>
					<MenuItem title='Desempeño' path='configuracion-otros' />
					<MenuItem title='Evaluaciones' path='periodos' />
					<MenuItem title='Feedback' path='reglamento' />
					{/* <MenuItem title='Publicaciones' path='publicaciones' /> */}
				</Menu>
				<Menu title='Otros' icon={<FaIcons.FaAdjust size={20} />}>
					<MenuItem title='Configuración' path='configuracion-otros' />
					<MenuItem title='Periodos' path='periodos' />
					<MenuItem title='Reglamento' path='reglamento' />
				</Menu>
			</MenuList>
		);
	}, []);

	const Header = () => {
		const {sucursal} = useContext(SiteContext);
		return (
			<>
				<Button
					variant='success'
					size='sm'
					icon={<Hi2Icons.HiOutlineArrowsRightLeft />}
					text={sucursal?.companyName?.toUpperCase()}
					onClick={() => navigate('/selector')}
				/>
				<Avatar
					name={fullnameInitial}
					onClick={() => setShowProfile((previousState) => !previousState)}
				/>
				<MenuProfile showProfile={showProfile}>
					<span>{fullname}</span>
					<div></div>
					<span onClick={logout}>
						<BiIcons.BiLogOut /> Cerrar Sesión
					</span>
				</MenuProfile>
			</>
		);
	};

	const logout = () => {
		localStorage.clear();
		navigate('/logout');
	};

	if (!isAuthenticated) {
		loginWithRedirect();
		return;
	}

	return (
		<>
			<SiteProvider>
				<RoomProvider>
					<WargosLayout
						brand='ASIS'
						logo={logo}
						items={<Items />}
						menus={<Menus />}
						header={<Header />}
						defaultOpen
					>
						<Outlet />
					</WargosLayout>
				</RoomProvider>
			</SiteProvider>
		</>
	);
};

export default Layout;
