import React, {useCallback, useContext, useEffect} from 'react';
import ModalGeneric from '../../../../components/Modal';
import PositionNew from './PositionNew';
import PositionEdit from './PositionEdit';
import useEditModal from '../../../../hooks/useEditModal';
import {LuFileEdit, LuTrash2} from "react-icons/lu";
import {Apis} from '../../../../utils/Apis';
import useFetch2 from '../../../../hooks/useFetch2';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import DeleteGenericModal from '../../../../components/modals/DeleteGenericModal';
import PageSection from '../../../../components/layout/PageSection';
import axios from 'axios';
import {Button, FlexContainer, Table, Title} from "@wargostec/react-components";
import EmptyState from '../../../../components/migrationcomponents/emptystate';
import {errorToast, successToast} from "../../../../components/Toast";
import {SiteContext} from "../../../../components/context/SiteContext";
import {MdSync} from "react-icons/md";

const Position = () => {

  const {empresaId} = useContext(SiteContext);

  const {wrapper, refresh, addAction, editAction} = useEditModal({
    addComponent: <PositionNew />,
    editComponent: <PositionEdit />,
  });

  const {
    wrapper: wrapper2,
    refresh: refresh2,
    openDeleteModal,
  } = useDeleteModal({text: '¿Desea eliminar este cargo?'});

  const {loading, data, fetchData, error} = useFetch2(Apis.BASE_API_URL + '/cargos', []);

  const deleteElement = (positionId) =>
    axios.delete(Apis.BASE_API_URL + `/cargos/${positionId}`);

  const syncCargos = () => {
    return axios.get(Apis.BASE_API_URL + `/cargos/sync?empresaId=${empresaId}`)
      .then(res => {
          successToast('Sincronización realizada con éxito');
          fetchData()
        }
      ).catch((err) => {
          errorToast(err ? err.response.data.message : 'Ocurrió un problema. Por favor intente nuevamente');
        }
      )
  }

  const actionValue = useCallback(props => {
    return (
      <>
        <FlexContainer flexDirection="row" gap="8px" justifyContent="center" alignItems="center">
          <Button 
            size={'xs'} 
            variant='light' 
            onClick={() => editAction(props.renderValue())}
            icon={<LuFileEdit />}
            text='Editar' 
          />
          <Button 
            size={'xs'} 
            variant='danger' 
            onClick={() => openDeleteModal(props.renderValue(), deleteElement)}
            icon={<LuTrash2 />}
            text='Eliminar'
            />
        </FlexContainer>
      </>
    );
  }, []);

  const columns = [
    {header: 'Nombre', accessorKey: 'name'},
    {
      header: 'Acción',
      accessorKey: 'id',
      cell: actionValue,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
  ];

  useEffect(() => {
    fetchData()
  }, [refresh, refresh2])

  return (
    <>
      <PageSection>
        <Title text='Cargos' type={'page'} />
      </PageSection>
      <PageSection>
        <Table 
          columns={columns} 
          data={data} 
          onAdd={addAction}
          onCustom={
            <Button
              variant='light'
              size='sm'
              text="Sincronizar"
              icon={<MdSync />}
              onClick={syncCargos}
            />
          }
          pageSize={20}
          searchable
          emptyListText={
            <EmptyState
              title='Sin resultados'
              subtitle='Haga click en filtros para realizar una nueva consulta.'
              data={data}
              loading={loading}
              error={error}
            />
          }
        />
      </PageSection>

      <ModalGeneric {...wrapper} />
      <DeleteGenericModal {...wrapper2} />
    </>
  );
};

export default Position;
