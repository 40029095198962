import React, {useEffect, useContext, useCallback} from 'react';
import {Apis} from '../../../../utils/Apis';
import useFetch2 from '../../../../hooks/useFetch2';
import PageSection from '../../../../components/layout/PageSection';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import DeleteGenericModal from '../../../../components/modals/DeleteGenericModal';
import {SiteContext} from '../../../../components/context/SiteContext';
import {Button, Table, Title,FlexContainer} from "@wargostec/react-components";
import {LuFileEdit, LuTrash2} from 'react-icons/lu';
import EmptyState from '../../../../components/migrationcomponents/emptystate';

const TurnList = () => {
	const {sucursalId} = useContext(SiteContext);
	const baseURL = Apis.BASE_API_URL + '/turnos';
	const params = {sucursalId};
	const navigate = useNavigate();
	const {loading, data, fetchData, error} = useFetch2(baseURL, [], params);

	const goTurnEdit = (id) => {
		navigate(`/editar-turno/${id}`);
	};

	const {wrapper, refresh, openDeleteModal} = useDeleteModal({
		text: '¿Desea eliminar este turno?'
	});

	const deleteElement = (id) => axios.delete(baseURL + `/${id}`);

	const actionValue = useCallback((props) => {
		const id = props.row.original.id;

		return (
			<FlexContainer flexDirection="row" gap="8px" justifyContent="center" alignItems="center">
          <Button 
            size={'xs'} 
            variant='light' 
            onClick={() => goTurnEdit(id)}
            icon={<LuFileEdit />}
            text='Editar' 
          />
          <Button 
            size={'xs'} 
            variant='danger' 
            onClick={() => openDeleteModal(id, deleteElement)}
            icon={<LuTrash2 />}
            text='Eliminar'
            />
        </FlexContainer>
		);
	}, []);

	const columns = [
		{
			header: 'Turno',
			accessorKey: 'name',
		},
		{
			header: 'Periodo',
			accessorKey: 'periodo',
			meta: {
				headerClassName: 'text-center',
				columnClassName: 'text-center',
			},
		},
		{
			header: 'Ciclos',
			accessorKey: 'cycles',
			meta: {
				headerClassName: 'text-center',
				columnClassName: 'text-center',
			},
		},
		{
			header: 'Acción',
			accessorKey: '',
			cell: actionValue,
			meta: {
				headerClassName: 'text-center',
				columnClassName: 'text-center',
			},
		}
	];

	useEffect(() => {
		fetchData();
	}, [refresh]);

	return (
		<>
			<PageSection>
				<div style={{marginBottom: '1rem'}}>
					<Title text='Turnos' type={'page'}/>
				</div>
			</PageSection>
			<PageSection>
				<Table 
					columns={columns}
					data={data}
					searchable
					onAdd={() => navigate('/nuevo-turno')}
					emptyListText={
						<EmptyState
							title='Sin resultados'
							subtitle='Haga click en filtros para realizar una nueva consulta.'
							data={data}
							loading={loading}
							error={error}
						/>
					}
				/>
			</PageSection>
			<DeleteGenericModal {...wrapper} />
		</>
	);
};

export default TurnList;
