import styled from 'styled-components'

const GridContainer = styled.div`
	display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns || '1fr 1fr'};
	gap: 16px;
	margin-bottom: 16px;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

export default GridContainer