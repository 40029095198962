import React, {useEffect, useState} from 'react';
import PeopleFormCese from './PeopleFormCese';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import CustomerSearchForm from './CustomerSearchForm';
import styled from 'styled-components';
import SelectCargo from './SelectCargo';
import SelectArea from '../../../../components/selects/SelectArea';
import OfficeConfig from './OfficeConfig';
import SelectContract from '../../../../components/selects/SelectContract';
import SelectPension from '../../../../components/selects/SelectPension';
import {IoFingerPrint} from "react-icons/io5";
import ClocksConfig from './ClocksConfig';
import useEditModal from '../../../../hooks/useEditModal';
import FingerPrintNew from './FingerPrintNew';
import ModalGeneric from '../../../../components/Modal';
import DocumentConfig from './DocumentConfig';
import DocumentView from '../../../../components/viewpdf/DocumentView';
import {Button, Card, Input, Tabs, Tab,FlexContainer} from "@wargostec/react-components";
import FileUploaderController from "../../../../components/FileUploaderController";
import {Col, Row} from "../../../../components/migrationcomponents/flexlayout";
import {Alert} from "../../../../components/migrationcomponents/alert";
import FormGroup, {FormBlockTitle} from "../../../../components/migrationcomponents/FormGroup";
import {Apis} from '../../../../utils/Apis';
import useFetch2 from '../../../../hooks/useFetch2';

const InputRadio = styled.div`
    display: flex;
    align-items: center;
  > label {
    font-size: 0.9em;
    font-weight: 400;
  }
`;

const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;


const Badge = styled.span`
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color:#fff;
  background-color: ${({ variant }) => (variant === 'danger' ? 'red' : '#007bff')};
`;

const FormGeneral = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const PeopleForm = ({ value, onSubmit, edit, data }) => {

  const {data:dataCargos,loading: loadingCargos} = useFetch2(Apis.BASE_API_URL + "/cargos", [])
  const {data:dataSalas,loading:loadingSalas} = useFetch2(Apis.BASE_API_URL + "/salas", [])

  const [change, setChange] = useState('');
  const { register, handleSubmit, reset, control } = useForm();

  const { wrapper, addAction } = useEditModal({
    addComponent: <FingerPrintNew />,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sucursales"
  });

  const addOffice = () => {
    append({ sucursalId: 0 });
  }

  const { fields: fields2, append: append2, remove: remove2 } = useFieldArray({
    control,
    name: "employeeClocks"
  });

  const addOffice2 = () => {
    append2({ sucursalId: '' });
  }

  const { fields: fieldsDocument, append: appendDocument, remove: removeDocument } = useFieldArray({
    control,
    name: "employeeDocuments"
  });

  const addDocument = () => {
    appendDocument({ sucursalId: '', employeeId: value.id });
  }

  useEffect(() => {
    handleSubmit(onSubmit);
  }, []);

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [value]);

  return (
    <FlexContainer gap='16px' flexDirection='column' >
      <Card>
        <Controller
          name='personId'
          control={control}
          render={({ field }) => <CustomerSearchForm {...field} />}
        />
      </Card>

      <Tabs>
        <Tab title="General">
          <FormGeneral>
            <ContainerForm>
              <Card>
                <FlexContainer alignItems='center' gap='8px'>
                  <FormBlockTitle>Datos laborales</FormBlockTitle>
                  {edit && value && (
                    <>
                      {value.status === 'ACTIVO' ? 
                        <Badge style={{ marginBottom: '8px' }}>Activo</Badge>
                      : 
                        <Badge variant='danger' style={{ marginBottom: '8px' }}>Cesado</Badge>
                      }
                    </>
                  )}
                </FlexContainer>
                <FormGroup>
                  <Input
                    label={"ID"}
                    type='text'
                    {...register('id')}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <label>Cargo</label>
                  <SelectCargo data={dataCargos} loading={loadingCargos} register={register} />
                </FormGroup>
                <FormGroup>
                  <label>Area</label>
                  <SelectArea {...register('areaId')} />
                </FormGroup>
                <FormGroup>
                  <label>Renta</label>
                  <InputRadio>
                    <input type='radio' name='regimen' value='CAT_4' {...register('regimen',
                      {
                        onChange: (e) => {
                          setChange(e.target.value);
                        }
                      })} />
                    <label>4taCat | ReciboxHonorario</label>
                  </InputRadio>
                  <InputRadio>
                    <input type='radio' name='regimen' value='CAT_5' {...register('regimen',
                      {
                        onChange: (e) => {
                          setChange(e.target.value);
                        }
                      })} />
                    <label>5taCat | Planilla</label>
                  </InputRadio>
                </FormGroup>
                <Row>
                  <Col>
                    <FormGroup>
                      <Input
                        label={"Inicio contrato"}
                        type='date'
                        name={'fecha'}
                        placeholder='Desde'
                        {...register('iniContrato')}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Input
                        label={"Fin contrato"}
                        type='date'
                        name={'fecha'}
                        placeholder='Hasta'
                        {...register('finContrato')}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {
                  (change === 'CAT_5' || value.regimen === 'CAT_5') ? <>
                    <FormGroup>
                      <label>Tipo de contrato</label>
                      <SelectContract {...register('contratoTypeId')} />
                    </FormGroup>
                    <FormGroup>
                      <label>Regimen pensionario</label>
                      <SelectPension {...register('pensionTypeId')} />
                    </FormGroup>
                    <FormGroup>
                      <label>Regimen de salud</label>
                      <InputRadio>
                        <input type='radio' name='reg-salud' value={1} {...register('regSaludId')} />
                        <label>ESSALUD</label>
                      </InputRadio>
                      <InputRadio>
                        <input type='radio' name='reg-salud' value={2} {...register('regSaludId')} />
                        <label>EPS</label>
                      </InputRadio>
                    </FormGroup>
                  </> : ''
                }
              </Card>
              <Card>
                <FormBlockTitle>Sucursales</FormBlockTitle>
                <FormGroup>
                  <Alert variant='info' text='El empleado aparecera en los calendarios de asignacion de turnos de las sucursales seleccionadas.' />
                </FormGroup>
                <OfficeConfig fields={fields} remove={remove} register={register} addOffice={addOffice} data={dataSalas} loading={loadingSalas} />
              </Card>
            </ContainerForm>
            <div>
              <Button variant='primary' onClick={handleSubmit(onSubmit)} text={'GUARDAR'} />
            </div>
          </FormGeneral>
        </Tab>
        <Tab title="Documentación">
          <Card>
            <FlexContainer flexDirection='row' gap='16px' flexWrap='wrap'>
              <FlexContainer flexDirection='column' flex='1' alignItems='flex-start'>
                <FormBlockTitle>Ficha de ingreso</FormBlockTitle>
                <Controller
                  name='docContract.url'
                  control={control}
                  render={({field: {value, onChange}}) => (
                    <FileUploaderController value={value} onChange={onChange} />
                  )}
                />
                <DocumentView value={value?.docContract?.url} title='Ficha de ingreso' />
              </FlexContainer>
              <FlexContainer flexDirection='column' flex='1' alignItems='flex-start'>
                <FormBlockTitle>Alta Sunat</FormBlockTitle>
                <Controller
                  name='docAltaSunat.url'
                  control={control}
                  render={({field: {value, onChange}}) => (
                    <FileUploaderController value={value} onChange={onChange} />
                  )}
                />
                <DocumentView value={value?.docAltaSunat?.url} title='Alta Sunat' />
                </FlexContainer>
                <FlexContainer flexDirection='column' flex='1' alignItems='flex-start'>
                <FormBlockTitle>Modificación Sunat</FormBlockTitle>
                <Controller
                  name='docModSunat.url'
                  control={control}
                  render={({field: {value, onChange}}) => (
                    <FileUploaderController value={value} onChange={onChange} />
                  )}
                />
                <DocumentView value={value?.docModSunat?.url} title='Modificación Sunat' />
              </FlexContainer>

            </FlexContainer>
            <DocumentConfig fields={fieldsDocument} control={control} remove={removeDocument} addDocument={addDocument} />
            <Button variant='primary' onClick={handleSubmit(onSubmit)} text={'GUARDAR'} />
          </Card>
        </Tab>
        <Tab title="Reloj">
          <ContainerForm>
            <Card>
              <FormBlockTitle>Replicar huella en:</FormBlockTitle>
              <FormGroup>
                <Alert variant='info' text='Seleccione las sucursales en donde el empleado podra marcar asistencia'/>
              </FormGroup>
              <ClocksConfig fields={fields2} remove={remove2} register={register} addOffice={addOffice2} edit={edit} data={dataSalas} loading={loadingSalas} />
            </Card>
            {edit ? 
              <Card>
                <FormBlockTitle>Huellas registradas: {data.length}</FormBlockTitle>
                <FormGroup>
                  <Button 
                    variant='primary'
                    size={'xs'} 
                    onClick={() => addAction({data: value.sucursales})}
                    text={'Registrar huella'} icon={<IoFingerPrint />}
                  />
                  <FlexContainer flexDirection='column' gap='8px' styles={{marginTop:'16px'}}>
                    {data && data.map((item) => (
                      <FlexContainer key={item.fingerId} alignItems='center' gap='8px' styles={{padding:'8px',borderBottom:'1px solid rgb(0 0 0 / 10%)'}}>
                        <IoFingerPrint/> 
                        <span>{item.fingerId}</span>
                      </FlexContainer>
                    ))}
                  </FlexContainer>
                </FormGroup>
              </Card> : ''
            }
          </ContainerForm>
          <Button variant='primary' onClick={handleSubmit(onSubmit)} text={'GUARDAR'} />
        </Tab>
        <Tab title="Cese"><PeopleFormCese value={value} edit={edit} /></Tab>
      </Tabs>
      <ModalGeneric {...wrapper} />
    </FlexContainer>
  );
}

export default PeopleForm;
