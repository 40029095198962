const baseAsis = 'https://api.asis.pe'
const api5Wargos = 'https://api5.wargos.cloud'

export const Apis = Object.freeze({
  BASE_RESOURCE_URL: 'https://asisdata.s3.amazonaws.com/',
  API_WARGOS_USER: 'https://api5.wargos.cloud/xuser',
  API_FILE: api5Wargos + '/xfile',

  BASE_IP: baseAsis + '/',
  BASE_API_URL: baseAsis + '/api',
  BASE_API_BLACKLIST: baseAsis + '/xblacklist',
  BASE_API_CLOCK: baseAsis + '/xbiometric',
  BASE_API_SURVEY: baseAsis + '/xsurvey',
  BASE_API_TGM: baseAsis + '/xtgmconex',
  BASE_API_FILE: baseAsis + '/xfile',
  BASE_API_PT: baseAsis + '/xpointcore',
});
