import React from "react";
import moment from "moment";
import {FlexContainer} from "@wargostec/react-components";

export const OnlineCircleActive = ({ valueDate }) => {
  let textColor = "#dad5d5";
  const today = new Date();
  const day = new Date(valueDate);
  const minutes = Math.round((today.getTime() - day.getTime()) / (1000 * 60));

  if (minutes <= 5) {
    textColor = "#0df10d";
  }
  return (
    <div
      className="type"
      style={{
        background: textColor,
        borderRadius: "50%",
        width: "20px",
        height: "20px",
        float: "left",
        marginRight: "10px"
      }}
    />
  );
};

export const TimeAgoOnline = ({children}) => {

  const goTime = (valueDate) => {
    let value;
    const today = new Date()
    const day = new Date(valueDate)
    const days = Math.round((today.getTime() - day.getTime()) / (1000 * 60 * 60 * 24))
    const horas = Math.round((today.getTime() - day.getTime()) / (1000 * 60 * 60))
    const minutes = Math.round((today.getTime() - day.getTime()) / (1000 * 60))

    if (days > 0) {
      return moment(valueDate).format('DD/MM HH:mm')
    }

    if (horas > 0) {
      return `hace ${horas}h`;
    }

    if (minutes === 0) {
      value = 'Ahora';
    } else {
      value = `hace ${minutes}m`;
    }

    return value;
  }

  return (
    <span style={{marginLeft: '5px'}}>
      {goTime(children)}
    </span>
  )
}

export const OnlineActive = ({ value }) => (
  <FlexContainer gap='8px'>
    <OnlineCircleActive valueDate={value}/>
    {value && <TimeAgoOnline>{value}</TimeAgoOnline>}
  </FlexContainer>
);
