import React, { useEffect, useState } from "react";
import { Apis } from '../../../../utils/Apis';
import Spinner from 'react-bootstrap/Spinner';
// import { SiteContext } from "../context/SiteContext";
import axios from 'axios'
import styled from "styled-components";
import {Input} from "@wargostec/react-components";

const UlCustom = styled.ul`
  border-radius: 0.5em;
  height: 200px;
  overflow: auto;
  z-index: 1000;
  position: absolute;
  bottom: -1em;
  margin: 0 1em 0 0;
`
const Form = styled.div`
  display: flex;
  height: 6em;
  flex-direction: column;
  gap: 0.2em;
  justify-content: center;
  .form-control {
    position: relative;
  }
`

const LiCustom = styled.li`
  cursor: pointer;
  background-color: white;
  color: #566074;
  font-weight: normal;
  font-size: 0.8em;

  &:hover {
    color: white;
    text-decoration: none;
    background: #007bff;
  }

  &:active {
    color: #fff;
    text-decoration: none;
    background: #007bff;
  }
`

const CountrySearch = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [noQuery, setNoQuery] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState('');
  // const { salaId } = useContext(SiteContext);

  const handleSearch = (e) => {

    setSearchValue(e?.target?.value)
    if (e.target.value.length > 2) {
      setLoading(true);
      getCustomers(e.target.value);
    } else {
      cleanSearch()
    }
  };

  const cleanSearch = () => {
    setNoQuery(true)
    setSearchValue(null)
    props.onChange(null)
  }

  const getCustomers = async (query) => {
    setNoQuery(false)
    const items = await axios.get(`${Apis.BASE_API_URL}/combos/ubigeo?term=${query}`)
    const options = items.data;
    setOptions(options)
    setLoading(false)
  }

  const customOnchange = (item) => {
    setNoQuery(true)
    setSearchValue(item.distrito)
    setData(item)
    props.onChange(item)
  }

  useEffect(() => {
    if (props.value) {
      setSearchValue(props.value.distrito)
      setData(props.value)
    }
  }, [props.value])

  return (
    <>
      <Form>
        <Input label={"Departamento"} placeholder='Departamento' value={data && data.departamento} disabled />
      </Form>
      <Form>
        <Input label={"Provincia"} placeholder='Provincia' value={data && data.provincia} disabled />
      </Form>
      <Form>
        <Input label={"Distrito"} className="form-control" icon={loading ? <Spinner animation="border" size="sm" /> : ''}
          id="listSearch" type="search" ref={ref} placeholder="Ingrese el nombre o DNI"
          onChange={handleSearch}
          value={searchValue}
          autoComplete="off"
        />
        <UlCustom className="list-group" id="myList">
          {options && !noQuery && options.map(item =>
            <LiCustom key={item.id} className="list-group-item" onClick={() => {
              customOnchange(item);
            }}>
              {item.distrito} |  {item.departamento} - {item.provincia}
            </LiCustom>
          )}
        </UlCustom>
      </Form>
    </>
  )
})
export default CountrySearch

