import React from "react";
import {Select} from "@wargostec/react-components";

const SelectHours = React.forwardRef(({ data, loading, ...props }, ref) => {

  const newData = data && data?.map((item) => {
    const nameItem = item.name +
      " / " +
      item.entrada.slice(0, 5) +
      " a " +
      item.salida.slice(0, 5)
    return {
      name: nameItem,
      id: item.id,
      value: item.id
    };
  });
  newData.unshift({ name: "DESCANSO SEMANAL", id: 0, value: 0 });

  return (
    <>
      {
        data && !loading ?
          <Select ref={ref} {...props} >
            {newData.map((item) => (
              <option key={item.id} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
          :
          <Select disabled>
            <option value="0">Cargando...</option>
          </Select>
      }
    </>
  );
});

export default SelectHours;
