import React, {useEffect, useContext, useCallback} from 'react';
import * as FaIcons from 'react-icons/fa';
import PageSection from '../../../../components/layout/PageSection';
import {Apis} from '../../../../utils/Apis';
import useFetch2 from '../../../../hooks/useFetch2';
import {useNavigate} from 'react-router-dom';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import axios from 'axios';
import DeleteGenericModal from '../../../../components/modals/DeleteGenericModal';
import {SiteContext} from '../../../../components/context/SiteContext';
import {Button, Table, Title} from "@wargostec/react-components";
import EmptyState from "../../../../components/migrationcomponents/emptystate";

const SurveyList = () => {
	const baseURL = Apis.BASE_API_URL + '/employee-exit-survey';
	const {empresaId} = useContext(SiteContext);
	const navigate = useNavigate();

	const {loading, data, fetchData, error} = useFetch2(baseURL + `?empresaId=${empresaId}`, []);

	const goSurveyEdit = (id) => {
		navigate(`/edit-encuesta-salida/${id}`);
	};

	const {wrapper, refresh, openDeleteModal} = useDeleteModal({
		text: '¿Desea eliminar esta encuesta?'
	});

	const deleteElement = (id) => axios.delete(baseURL + `/${id}`);

	const employeeValue = ({ row }) => (
		<div>
			{row.original.employee?.person?.lastNameFirstName}
		</div>
	);

	const actionValue = useCallback((props) => {
		const id = props.row.original.id;

		return (
			<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
				<Button variant='light' size='xs' onClick={() => goSurveyEdit(id)}
								icon={<FaIcons.FaEdit fontSize='1.3em' color='black' />}/>
				<Button variant='light' size='xs' onClick={() => openDeleteModal(id, deleteElement)}
								icon={<FaIcons.FaTrash fontSize='1.2em' color='red' />}/>
			</div>
		);
	}, []);

	const columns = [
		{header: '#', accessorKey: 'id'},
		{
			header: 'Empleado',
			accessorKey: '',
			cell: employeeValue
		},
		{header: 'Motivo', accessorKey: 'motivo'},
		{header: 'Explicacion', accessorKey: 'explicacion'},
		{
			header: 'Acción',
			accessorKey: '',
			cell: actionValue
		}
	];

	useEffect(() => {
		fetchData();
	}, [refresh]);

	return (
		<>
			<PageSection>
				<div style={{marginBottom: '1rem'}}>
					<Title text='Encuestas de salida' type={'page'}/>
				</div>
			</PageSection>
			<PageSection>
				<Table 
				columns={columns} 
				data={data}
				searchable
				emptyListText={
					<EmptyState
						title='Sin resultados'
						subtitle='Haga click en filtros para realizar una nueva consulta.'
						data={data}
						loading={loading}
						error={error}
					/>
				}
				 />
			</PageSection>
			<DeleteGenericModal {...wrapper} />
		</>
	);
};

export default SurveyList;
