import React, { useEffect } from 'react';
import { Apis } from '../../../../utils/Apis';
import axios from 'axios';
import RecordsForm from './RecordsForm';

const RecordsEdit = ({ id, setAction, setTitle }) => {
  const recordId = id.id;
  const update = (params) => {
    return axios.put(Apis.BASE_API_URL + `/marcaciones/${recordId}`, params);
  };

  useEffect(() => {
    setTitle('Editar Registro');
  }, []);

  return <RecordsForm value={id} onSubmit={update} setAction={setAction} edit={true}/>;
};

export default RecordsEdit;
