import React from 'react'
import {Select} from "@wargostec/react-components";

const SelectOffice = React.forwardRef((props, ref) => {

  const newData = props.data && props.data.map((item) => {
    const name = item?.company?.name + ' | ' + item?.name
    return {
      name: name,
      id: item.id,
      value: item.id,
    };
  });

  return (
    <>
      {props.data && !props.loading ?
        <Select ref={ref} {...props} >
          {newData.map((item) => (
            <option key={item.id} value={item.value}>
              {item.name}
            </option>
          ))}
        </Select>
        :
        <Select disabled>
          <option value="0">Cargando...</option>
        </Select>
      }
    </>
  );
});

export default SelectOffice;
