import React from 'react';
import SelectOffice from '../../../../components/selects/SelectOffice';
import {Button,FlexContainer} from "@wargostec/react-components";
import {LuTrash2} from 'react-icons/lu';
import {AiOutlinePlus} from 'react-icons/ai';

const ClocksConfig = ({fields, register, addOffice, remove, edit, data, loading}) => {

  return (
    <FlexContainer flexDirection='column' gap='16px' alignItems='flex-start'>

      <Button variant="primary" size={'xs'} onClick={addOffice} disabled={!edit} text={'Agregar'} icon={<AiOutlinePlus />}/>

      {fields && fields.map((field, index) => {
        return (
          <FlexContainer key={field.id} gap='8px' alignItems='center'>
            <SelectOffice {...register(`employeeClocks[${index}].sucursalId`)} data={data} loading={loading} />
            <Button variant="danger" size={'xs'} onClick={() => remove(index)} icon={<LuTrash2/>} />
          </FlexContainer>
        )
      })
      }
    </FlexContainer>
  );
}

export default ClocksConfig;
