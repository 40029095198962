import React, {useState, useEffect, useContext, useCallback} from 'react';
import * as FaIcons from 'react-icons/fa';
import PageSection from '../../../../components/layout/PageSection';
import EmptyState from "../../../../components/migrationcomponents/emptystate";
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../utils/Apis';
import {DateTime} from 'luxon';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import DeleteGenericModal from '../../../../components/modals/DeleteGenericModal';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import axios from 'axios';
import {SiteContext} from '../../../../components/context/SiteContext';
import DocumentView from '../../../../components/viewpdf/DocumentView';
import ModalGeneric from '../../../../components/Modal';
import useEditModal from '../../../../hooks/useEditModal';
import HolidaysDocEdit from './HolidaysDocEdit';
import {Button, Slidebar, Table, Input, Title,FlexContainer} from "@wargostec/react-components";
import {TbFileUpload} from 'react-icons/tb';
import {AiOutlineCloudDownload} from 'react-icons/ai';
import {LuFileEdit, LuTrash2} from 'react-icons/lu';

const Holidays = () => {
	const navigate = useNavigate();
	const {empresaId} = useContext(SiteContext);
	const [close, setClose] = useState(false);
	let defaultParams = {companyId: empresaId};
	const [params, setParams] = useState(defaultParams);
	const {loading, data, fetchData, error} = useFetch2(
		Apis.BASE_API_URL + '/convenio-feriado',
		[],
		params,
		{},
		false
	);
	const {register, handleSubmit, setValue, control} = useForm({
		defaultValues: defaultParams
	});
	const {
		wrapper: wrapper2,
		refresh: refresh2,
		editAction
	} = useEditModal({
		editComponent: <HolidaysDocEdit />
	});
	const {wrapper, refresh, openDeleteModal} = useDeleteModal({
		text: '¿Desea eliminar esta área?'
	});

	const deleteElement = (areaId) =>
		axios.delete(Apis.BASE_API_URL + `/convenio-feriado/${areaId}`);

	const getData = (params) => {
		setParams(params);
		setClose(!close);
	};

	const resetForm = () => {
		setValue('from', '');
		setValue('to', '');
	};

	const goAbsenceNew = () => {
		navigate('/convenio-feriados-nuevo');
	};

	const goAbsenceEdit = (id) => {
		navigate('/convenio-feriados-edit/' + id);
	};

	useEffect(() => {
		fetchData();
	}, [params, refresh, refresh2]);

	const downloadReport = async (id) => {
		window.location.href =
			(await Apis.BASE_API_URL) + `/convenio-feriado/${id}/report.pdf`;
	};

	const formatDate = useCallback(props => <>
			{
				<div>
					{props.renderValue() ?
						<span>{DateTime.fromISO(props.renderValue()).toFormat("dd'-'LL'-'yyyy'")}</span> :
						<span>---</span>}
				</div>
			}
		</>
		, []);

	const actionValue = useCallback((props) => {
		const id = props.row.original.id;

		return (
			<FlexContainer gap='8px' alignItems='center' justifyContent='center'>
				<Button 
					variant={'light'} 
					size={'xs'} 
					onClick={() => downloadReport(id)}
					text='Generar'
					icon={<AiOutlineCloudDownload />}
				/>
				<Button 
					variant='light' 
					size='xs' 
					onClick={() => goAbsenceEdit(id)}
					icon={<LuFileEdit />}
				/>
				<Button 
					variant='danger' 
					size='xs' 
					onClick={() => openDeleteModal(id, deleteElement)}
					icon={<LuTrash2/>}
				/>
			</FlexContainer>
		);
	}, []);

	const renderFileCell = ({row}) => (
		<FlexContainer gap='8px' alignItems='center' justifyContent='center'>
			<Button
				variant='light'
				size='xs'
				onClick={() => editAction(row.original)}
				icon={<TbFileUpload />}
			/>
			{row.original.filePath ? (
				<DocumentView 
					value={row.original?.filePath} 
					title='Archivo' 
					size='xs' 
					variant="light" 
					icon={<FaIcons.FaRegFilePdf />} 
				/>
			) : null}
		</FlexContainer>
	);

	const renderCompanyCell = ({row}) => (
		<div>
			{row.original.company?.razonSocial}
		</div>
	);

	const columns = [
		{ header: 'Id',
			accessorKey: 'id',
			enableGlobalFilter:false,
      enableSorting:false,
			meta: {
				headerClassName: 'text-center',
				columnClassName: 'text-center',
			}
		},
		{
			header: 'Empresa',
			accessorKey: 'company',
			cell: renderCompanyCell,
		},
		{
			header: 'Archivo',
			accessorKey: 'filePath',
			cell: renderFileCell,
			enableGlobalFilter:false,
      enableSorting:false,
			meta: {
				headerClassName: 'text-center',
				columnClassName: 'text-center',
			},
		},
		{
			header: 'Fecha Feriado',
			accessorKey: 'fecha',
			enableGlobalFilter:false,
      enableSorting:false,
			cell: formatDate,
			meta: {
				headerClassName: 'text-center',
				columnClassName: 'text-center',
			},
		},
		{
			header: 'Acción',
			accessorKey: '',
			enableGlobalFilter:false,
      enableSorting:false,
			cell: actionValue,
			meta: {
				headerClassName: 'text-center',
				columnClassName: 'text-center',
			},
		}
	];
	return (
		<>
			<PageSection>
				<div style={{marginBottom: '1rem'}}>
					<Title text='Feriados' type={'page'}/>
				</div>
			</PageSection>
			<PageSection>
				<Table
					columns={columns}
					data={data}
					pagination
					paginationSize={20}
					onAdd={goAbsenceNew}
					onFilter={() => setClose(!close)}
					searchable
					emptyListText={
						<EmptyState
							title='Sin resultados'
							subtitle='Haga click en filtros para realizar una nueva consulta.'
							data={data}
							loading={loading}
							error={error}
						/>
					}
				/>
			</PageSection>
			<Slidebar
				title='Filtrar'
				toggler={() => setClose(!close)}
				isOpen={close}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '1em',
						marginTop: '1em'
					}}
				>
					<Input
						label={"Fecha de inasistencia"}
						type='date'
						name={'fecha'}
						placeholder='Desde'
						{...register('from')}
					/>
					<Input
						type='date'
						name={'fecha'}
						placeholder='Hasta'
						{...register('to')}
					/>
					<Button variant='primary' onClick={handleSubmit(getData)} text={'BUSCAR'} icon={<FaIcons.FaSearch />}/>
					<Button variant='light' onClick={resetForm} text={'LIMPIAR'} icon={<FaIcons.FaEraser />}/>
				</div>
			</Slidebar>
			<DeleteGenericModal {...wrapper} />
			<ModalGeneric {...wrapper2} />
		</>
	);
};

export default Holidays;
