import React, { createContext, useState } from 'react';
import { Apis } from "../../utils/Apis";
import axios from "axios";

export const RoomContext = createContext({
  getRoomInfo: () => {
  },
  loading: false
});

export const RoomProvider = (props) => {
  const [room, setRoom] = useState()
  const [loading, setLoading] = useState(false)

  const getRoomInfo = async () => {
    setLoading(true)
    const existingCustomer = room
    if (existingCustomer !== undefined) {
      setLoading(false)
      return existingCustomer
    }
    try {
      const res = await axios.get(Apis.BASE_API_URL + '/salas')
      setRoom(res.data)
      setLoading(false)
      return res.data
    } catch (e) {
      setLoading(false)
      return null
    }
  }


  return (
    <RoomContext.Provider value={{ loading, getRoomInfo }}>
      {props.children}
    </RoomContext.Provider>
  );
}
