import React, {useEffect} from 'react';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../utils/Apis';
import * as FaIcons from 'react-icons/fa';
import useGroupBy from '../../../../hooks/useGroupBy';
import PageSection from '../../../../components/layout/PageSection';
import RegulationNew from './RegulationNew';
import RegulationEdit from './RegulationEdit';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import useEditModal from '../../../../hooks/useEditModal';
import ModalGeneric from '../../../../components/Modal';
import DeleteGenericModal from '../../../../components/modals/DeleteGenericModal';
import axios from 'axios';
import {Button, Card, FlexContainer, Title} from "@wargostec/react-components";
import {Table} from 'react-bootstrap';
import EmptyState from '../../../../components/migrationcomponents/emptystate';
import {LuFileEdit, LuTrash2} from 'react-icons/lu';

const Regulation = () => {
  const {loading, data, fetchData, error} = useFetch2(
    Apis.BASE_API_URL + '/reglamento-interno',
    []
  );

  const {dataGroup} = useGroupBy(data, 'capitulo');

  const {wrapper, refresh, addAction, editAction} = useEditModal({
    addComponent: <RegulationNew />,
    editComponent: <RegulationEdit />,
  });

  const {
    wrapper: wrapper2,
    refresh: refresh2,
    openDeleteModal,
  } = useDeleteModal({text: '¿Desea eliminar este reglamento interno?'});

  const deleteElement = (regulationId) =>
    axios.delete(Apis.BASE_API_URL + `/reglamento-interno/${regulationId}`);

  useEffect(() => {
    fetchData();
  }, [refresh, refresh2]);

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Reglamento interno' type={'page'} />
        </div>
        <Button variant='primary' onClick={addAction} text={'Nuevo'} icon={<FaIcons.FaPlus size='15px' />} />
      </PageSection>
      <PageSection>
        <Card noPadding>
          <Table hover striped responsive size="sm">
            <thead>
              <tr>
                <th>Artículo</th>
                <th>Descripción</th>
                <th className='text-center'>Acciones</th>
              </tr>
            </thead>
            {Object.keys(dataGroup).map((key, index) => (
              <tbody key={index}>
                <tr>
                  <th colSpan='3' className='head'>{key}</th>
                </tr>
                {dataGroup[key].map((x, index) => (
                  <tr key={index}>
                    <td>{x.articulo}</td>
                    <td>{x.descripcion}</td>
                    <td>
                      <FlexContainer flexDirection='row' gap='8px' justifyContent='center' alignItems='center'>
                        <Button
                          variant={'light'}
                          size={'xs'}
                          onClick={() => editAction(x.id)}
                          icon={<LuFileEdit />}
                        />
                        <Button
                          variant={'danger'}
                          size={'xs'}
                          onClick={() => openDeleteModal(x.id, deleteElement)}
                          icon={<LuTrash2 />}
                        />
                      </FlexContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            ))}
          </Table>

          <EmptyState
            title='Sin resultados'
            subtitle='Haga click en filtros para realizar una nueva consulta.'
            data={data}
            loading={loading}
            error={error}
          />
        </Card>
      </PageSection>
      <ModalGeneric {...wrapper} />
      <DeleteGenericModal {...wrapper2} />
    </>
  );
};

export default Regulation;
