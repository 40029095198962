import React, { useEffect } from "react";
import { Apis } from "../../../utils/Apis";
import axios from 'axios';
import useFetch2 from '../../../hooks/useFetch2';
import { useForm } from "react-hook-form";
import { infoToast } from '../../../components/Toast';

const ClockInfo = ({ item, setAction, setTitle }) => {
  const { handleSubmit } = useForm();
  // const baseURL = Apis.BASE_API_CLOCK + "/oplogs/last?serialNumber="+item.serialNumber;
  // const { loading, data, fetchData, error } = useFetch2(baseURL);

  const save = () => {
    return axios.post(Apis.BASE_API_URL + '/clocks/' + item.id + '/_info', {}).then((response) => {
      infoToast('Se actualizara la informacion en 30 seg');
    })
  }

  useEffect(() => {
    setAction(() => handleSubmit(save));
  }, []);

  useEffect(() => {
    setTitle("Log de operaciones");
  }, []);

  return (
    <>
      <h5>Data almacenada en el reloj:</h5>
      <h6>Empleador: {item.data.userCount}</h6>
      <h6>Huellas: {item.data.fpCount}</h6>
      <h6>Marcaciones: {item.data.transactionCount}</h6>
    </>
  )
}

export default ClockInfo;