import React from 'react';
import * as FaIcons from "react-icons/fa";
import styled from 'styled-components';
import {Controller} from 'react-hook-form';
import CustomerSearchInput from '../../../../components/selects/CustomerSearchInput';
import {Button, Input,FlexContainer} from "@wargostec/react-components";
import {LuTrash2} from 'react-icons/lu';
import Table from 'react-bootstrap/Table';
import {AiOutlinePlus} from 'react-icons/ai';

const HolidayConfig = ({fields, register, addHoliday, remove, control}) => {
  return (
    <FlexContainer flexDirection='column' gap='8px'>
      <Table responsive hover borderless size='sm'>
        <thead>
          <tr>
            <th>Trabajador</th>
            <th className='text-center'>Horas</th>
            <th className='text-center'>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {fields && fields.map((field, index) => {
            return (
              <tr key={field.id}>
                <td style={{width: '70%'}}>
                  <Controller name={`detalle.${index}.employeeId`} control={control} render={({field}) => <CustomerSearchInput {...field} />} />
                </td>
                <td className='text-center'>
                  <Input
                    type='text'
                    name={'motivo'}
                    placeholder='Ingrese horas'
                    className='text-center'
                    {...register(`detalle[${index}].horas`)}
                  />
                </td>
                <td className='text-center'>
                  <Button variant="danger" size={'xs'} onClick={() => remove(index)} icon={<LuTrash2 />} />
                </td>
              </tr>
            )
          })
          }
        </tbody>
      </Table>
      <div>
      <Button variant="primary" size={'xs'} onClick={addHoliday} icon={<AiOutlinePlus />} text={'Agregar'} />
      </div>
    </FlexContainer>

  );
}

export default HolidayConfig;
