import React, {useContext, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import SelectOffice from '../../../../components/selects/SelectOffice';
import styled from 'styled-components';
import * as FaIcons from "react-icons/fa";
import useDeleteModal from '../../../../hooks/useDeleteModal';
import DeleteGenericModal from '../../../../components/modals/DeleteGenericModal';
import {Apis} from "../../../../utils/Apis";
import axios from 'axios';
import {Button, Input,FlexContainer} from "@wargostec/react-components";
import SelectTurns from "../../../../components/selects/SelectTurn";
import SelectHours from "../../../../components/selects/SelectHours";
import FormGroup from "../../../../components/migrationcomponents/FormGroup";
import useFetch2 from '../../../../hooks/useFetch2';
import {SiteContext} from '../../../../components/context/SiteContext';

const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  gap: 1em;
`;

const AssignmentForm = ({value, onSubmit, setAction, edit}) => {
  const [repeticiones, setRepeticiones] = useState(1);
  const [type, setType] = useState("HORARIO");
  const {register, handleSubmit, reset} = useForm({defaultValues: {type: type}});

  const {sucursalId} = useContext(SiteContext);
  const params = {sucursalId: sucursalId};
  const {data,loading} = useFetch2(Apis.BASE_API_URL + "/salas", [])
  const {data:dataSelect, loading:loadingSelect} = useFetch2(Apis.BASE_API_URL + "/horarios", [], params);

  const {
    wrapper,
    openDeleteModal,
  } = useDeleteModal({text: '¿Desea eliminar esta asignación?'});

  const deleteElement = (turnoDiaId) => axios.delete(Apis.BASE_API_URL + `/turno-dia/${turnoDiaId}`);

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [value]);

  useEffect(() => {
    reset({ ...value, repeticiones });
  }, [repeticiones]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, []);

  return (
    <>
      <FormGroup>
        <label>Sucursal</label>
        <SelectOffice {...register('sucursalId')} data={data} loading={loading} />
      </FormGroup>
      <label style={{paddingBottom: '10px'}}>Crear por :</label>
      <FormGroup>
        <input
          label={"Crear por :"}
          type="radio"
          {...register('type')}
          required
          value="HORARIO"
          checked={type === "HORARIO"}
          onChange={() => setType("HORARIO")}
          disabled={edit}
        />
        <label>Horario</label>
      </FormGroup>
      <FormGroup>
        <input
          type="radio"
          {...register('type')}
          required
          value="TURNO"
          checked={type === "TURNO"}
          onChange={() => setType("TURNO")}
          disabled={edit}
        />
        <label>Turno</label>
      </FormGroup>
      {type === 'TURNO' ?
        <ContainerForm>
          <FormGroup>
            <label>Inicio</label>
            <Input type='date' placeholder='Nombre' {...register('fecha')} />
          </FormGroup>
          <FormGroup>
            <label>Fin</label>
            <Input type='date' placeholder='Nombre' {...register('fin')} />
          </FormGroup>
          <FormGroup>
            <label>Turno</label>
            <SelectTurns {...register('turnoId')} />
          </FormGroup>
        </ContainerForm>
        :
        <ContainerForm>
          <FormGroup>
            <label>Fecha</label>
            <Input type='date' placeholder='Nombre' {...register('fecha')} />
          </FormGroup>
          <FormGroup>
            <label>Repeticiones</label>
            <FlexContainer justifyContent='center' alignItems='center' gap='4px'>
              <Button 
                variant='primary'
                size={'xs'}
                onClick={() => setRepeticiones(repeticiones - 1)}
                disabled={repeticiones === 1} 
                icon={<FaIcons.FaMinus size={12}/>}
              />
              <div>
                <Input type='text' value={repeticiones} {...register('repeticiones')} />
              </div>
              <Button variant='primary' size={'xs'} onClick={() => setRepeticiones(repeticiones + 1)}
                      icon={<FaIcons.FaPlus size={12}/>}/>
            </FlexContainer>
          </FormGroup>
          <FormGroup>
            <label>Horario</label>
            <SelectHours data={dataSelect} loading={loadingSelect} {...register('horarioId')} />
          </FormGroup>
        </ContainerForm>
      }
      {
        value.turnoDiaId > 0 &&
        <FormGroup>

          {edit ?
            <Button size={'xs'} 
            variant='danger' onClick={() => openDeleteModal(value.turnoDiaId, deleteElement)}
                    text={'Eliminar Turno'}/>
            : ''}
        </FormGroup>
      }
      <DeleteGenericModal {...wrapper} />
    </>
  );
};

export default AssignmentForm;
