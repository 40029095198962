import axios from "axios";
import React, { useEffect, useContext } from "react";
import { Apis } from "../../../../utils/Apis";
import VacationRequestForm from "./VacationRequestForm";
import { SiteContext } from '../../../../components/context/SiteContext';

const VacationRequestsNew = ({ setAction, setTitle }) => {
  const { empresaId } = useContext(SiteContext);
  const save = (params) => {
    params.empresaId = empresaId;
    return axios.post(Apis.BASE_API_URL + "/vacaciones-sol", params);
  }

  useEffect(() => {
    setTitle("Agregar vacaciones");
  }, []);

  return <VacationRequestForm value={{}} onSubmit={save} setAction={setAction} />;
};

export default VacationRequestsNew;
