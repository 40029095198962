import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import SelectOffice from '../../../components/selects/SelectOffice';
import styled from 'styled-components';
import FormGroup from "../../../components/migrationcomponents/FormGroup";
import {Checkbox, Input, Select} from "@wargostec/react-components";
import useFetch2 from '../../../hooks/useFetch2';
import {Apis} from '../../../utils/Apis';

const InputCheckBox = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
`

const ClockForm = ({ value, onSubmit, setAction }) => {
  const { register, handleSubmit, reset } = useForm();
  const {data,loading} = useFetch2(Apis.BASE_API_URL + "/salas", [])

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [value]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, []);

  return (
    <>
      <FormGroup>
        <Input label={"Nro serie"} placeholder='Nro Serie' {...register('serialNumber')} />
      </FormGroup>
      <FormGroup>
        <label>Sucursal</label>
        <SelectOffice {...register('sucursalId')} data={data} loading={loading} />
      </FormGroup>
      <FormGroup>
        <label>Tipo</label>
        <Select {...register('type')}>
          <option value='zkteco'>ZKTeco</option>
          <option value='digitalpersona'>USB</option>
        </Select>
      </FormGroup>
      <FormGroup>
        <InputCheckBox>
          <Checkbox {...register('authorized')} />
          Autorizado
        </InputCheckBox>
      </FormGroup>
    </>
  );
};

export default ClockForm;
