import React, {useState, useEffect, useContext} from 'react';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../utils/Apis';
import RecordsTable from '../records/RecordsTable';
import {SiteContext} from '../../../../components/context/SiteContext';

const MarkingDetail = ({values, setTitle, refreshData}) => {

  const {sucursalId} = useContext(SiteContext);

  let defaultParams = {sucursalId, ini: values.ini, fin: values.fin};
  const [params, setParams] = useState(defaultParams);
  const {loading, data, fetchData, error} = useFetch2(
    Apis.BASE_API_URL + `/marcaciones?employeeId=${values.id}`, [], params);

  useEffect(() => {
    setTitle('Detalle de marcaciones');
  }, []);

  return (
    <div>
      {data &&
        <RecordsTable data={data} loading={loading} fetchData={fetchData} refreshData={refreshData} error={error} />
      }
    </div>
  );
}

export default MarkingDetail