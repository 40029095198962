import React, { useState, useEffect } from 'react';
import CustomerForm from './CustomerForm';
import useFetch2 from '../../../../hooks/useFetch2';
import { Apis } from '../../../../utils/Apis';
import axios from 'axios';

const CustomerEdit = ({ id, setAction, setTitle }) => {
  const { data, loading } = useFetch2(Apis.BASE_API_URL + '/persons/' + id, []);
  const update = (params) => {
    return axios.put(Apis.BASE_API_URL + '/persons/' + id, params);
  };
  useEffect(() => {
    setTitle('Editar Empleado');
  }, []);

  return (
    <>
      {
        data && !loading ?
          <CustomerForm value={data} onSubmit={update} setAction={setAction} edit={true}/> :
          <p style={{ textAlign: 'center' }}>Cargando...</p>
      }
    </>
  )
}

export default CustomerEdit;