import React, {useEffect} from 'react';
import {Apis} from '../../../../utils/Apis';
import axios from 'axios';
import useFetch2 from '../../../../hooks/useFetch2';
import PositionForm from './PositionForm';

const PositionEdit = ({ id, setAction, setTitle }) => {
  const { data } = useFetch2(Apis.BASE_API_URL + '/cargos/' + id, []);

  const update = (params) => {
    return axios.put(Apis.BASE_API_URL + `/cargos/${id}`, params);
  };

  useEffect(() => {
    setTitle('Editar Cargo');
  }, []);

  return <PositionForm value={data} onSubmit={update} setAction={setAction} />

};

export default PositionEdit;
