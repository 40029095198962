import React, { useState } from "react";

const useEditModal = ({ editComponent, addComponent }) => {
  const [refresh, setRefresh] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [body, setBody] = useState(null);

  const addAction = (args) => {
    setShowModal(true);
    setBody(React.cloneElement(addComponent, { ...args }));
  };

  const editAction = (id) => {
    setShowModal(true);
    setBody(React.cloneElement(editComponent, { id }));
  };

  const onCloseModal = () => {
    setShowModal(false);
    setRefresh(refresh + 1);
  };

  const wrapper = {
    show: showModal,
    onHide: onCloseModal,
    body: body,
  };

  return { wrapper, refresh, addAction, editAction };
};

export default useEditModal;
