import React from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";

const ConfigurationOther = () => {
  return (
    <PageSection>
      <div style={{marginBottom: '1rem'}}>
        <Title text='Otras configuraciones' type={'page'}/>
      </div>
    </PageSection>
  )
};

export default ConfigurationOther;
