import React, { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { Apis } from "../../../../utils/Apis";
import axios from "axios";
import {Col, Row} from "../../../../components/migrationcomponents/flexlayout";
import { SiteContext } from '../../../../components/context/SiteContext';
import FormGroup from "../../../../components/migrationcomponents/FormGroup";
import {Input} from "@wargostec/react-components";

const NewPeriods = ({ setAction, setTitle }) => {
  const { empresaId } = useContext(SiteContext);
  const baseURL = Apis.BASE_API_URL + "/periodos";
  const { register, handleSubmit, reset } = useForm();

  const addPeriod = async (params) => {
    params.empresaId = empresaId;
    await axios.post(baseURL, params);
    reset()
  };

  useEffect(() => {
    setTitle("Periodo");
    setAction(() => handleSubmit(addPeriod));
  }, []);

  return (
    <>
      <Row>
        <Col>
          <FormGroup>
            <Input label={"Fecha ini"} name="ini" type={"date"} {...register("ini")} />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Input label={"Fecha fin"} name="fin" type={"date"} {...register("fin")} />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default NewPeriods;
