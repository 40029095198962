import React, {useEffect, useState} from 'react';
import SelectType from './SelectType';
import {Controller, useForm} from 'react-hook-form';
import CustomerSearchInput from '../../../../components/selects/CustomerSearchInput';
import {Button, FlexContainer, Input} from "@wargostec/react-components";
import FileUploaderController from "../../../../components/FileUploaderController";
import FormGroup from "../../../../components/migrationcomponents/FormGroup";

const AbsenceForm = ({ value, onSubmit }) => {
  const { register, handleSubmit, reset, control, setValue, getValues } = useForm();
  const [change, setChange] = useState('');

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [value]);

  useEffect(() => {
    handleSubmit(onSubmit);
  }, []);


  return (
    <>
      <FormGroup>
        <label>Empleado</label>
        <Controller
          name='employeeId'
          control={control}
          render={({ field }) => <CustomerSearchInput {...field} />}
        />
      </FormGroup>
      <FormGroup>
        <label>Tipo</label>
        <SelectType
          {...register('type', {
            onChange: (e) => {
              setChange(e.target.value);
            }
          })} />
      </FormGroup>
      <FormGroup>
        <Input
          label={"Motivo"}
          type='text'
          name={'motivo'}
          placeholder='Ingrese motivo de falta'
          {...register('description')}
        />
      </FormGroup>
      {
        change === 'FALTA' ?
          <FormGroup>
            <Input
              label={"Inicio de la inasistencia"}
              type='date'
              name={'fecha'}
              placeholder='Desde'
              {...register('ini')}
            />
          </FormGroup>
          : <FlexContainer flexDirection='row' gap='16px'>
            <FormGroup>
              <Input
                label={"Inicio de la inasistencia"}
                type='date'
                name={'fecha'}
                placeholder='Desde'
                {...register('ini')}
              />
            </FormGroup>
            <FormGroup>
              <Input
                label={"Fin de la inasistencia"}
                type='date'
                name={'fecha'}
                placeholder='Hasta'
                {...register('fin')}
              />
            </FormGroup>
          </FlexContainer>
      }
      <FormGroup>
        <label>Documentacion firmada</label>
        <Controller
          name='doc.url'
          control={control}
          render={({ field: { value, onChange } }) => (
            <FileUploaderController value={value} onChange={onChange} />
          )}
        />
      </FormGroup>
      <Button variant='primary' onClick={handleSubmit(onSubmit)} text={'GUARDAR'} />
    </>
  );
};

export default AbsenceForm;
