import React from "react";
import {Select} from "@wargostec/react-components";

const SelectCessation = React.forwardRef((props, ref) => {
  const data = [
    { name: "ABANDONO LABORAL", value: 'ABANDONO_LABORAL', id: 1 },
    { name: "RENUNCIA", value: 'RENUNCIA', id: 2 },
    { name: "PERIODO DE PRUEBA", value: 'PERIODO_PRUEBA', id: 3 },
    { name: "TERMINO DE CONTRATO", value: 'TERMINO_CONTRATO', id: 4 },
  ];

  return (
    <>
      <Select ref={ref} {...props} >
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  );
});

export default SelectCessation;
