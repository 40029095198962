import React from 'react';
import styled from 'styled-components';
import * as FaIcons from "react-icons/fa";

const AlertStyled = styled.div`
  display: flex;
  gap: 1em;
  padding: 10px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.alert-primary {
    background-color: #cfe2ff;
    border-color: #cfe2ff;
    color: #084298;
  }
  &.alert-success {
    background-color: #d1e7dd;
    border-color: #d1e7dd;
    color: #0f5132;
  }
  &.alert-warning {
    background-color: #fff3cd;
    border-color: #fff3cd;
    color: #664d03;
  }
  &.alert-danger {
    background-color: #f8d7da;
    border-color: #f8d7da;
    color: #842029;
  }
  &.alert-info {
    background-color: #cff4fc;
    border-color: #cff4fc;
    color: #055160;
  }
`;

export function Alert({ variant, text }) {
  let variantClass = "";
  let icon = '';
  switch (variant) {
    case 'primary':
      variantClass = 'alert-primary';
      icon = <FaIcons.FaInfoCircle />;
      break;
    case 'success':
      variantClass = 'alert-success';
      icon = <FaIcons.FaCheckCircle />;
      break;
    case 'warning':
      variantClass = 'alert-warning';
      icon = <FaIcons.FaExclamationCircle />;
      break;
    case 'danger':
      variantClass = 'alert-danger';
      icon = <FaIcons.FaTimesCircle />;
      break;
    case 'info':
      variantClass = 'alert-info';
      icon = <FaIcons.FaInfoCircle />;
      break;
    default:
      variantClass = 'alert-primary';
      break;
  }
  return (
    <AlertStyled className={variantClass}>
      <span>
        {icon}
      </span>
      {text ? text : 'Ingrese texto'}
    </AlertStyled>
  );
}
