import React, {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import SelectCessation from '../../../../components/selects/SelectCessation';
import {Apis} from '../../../../utils/Apis';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {errorToast, successToast} from '../../../../components/Toast';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import DeleteGenericModal from '../../../../components/modals/DeleteGenericModal';
import {Button, Input,Card,FlexContainer} from "@wargostec/react-components";
import FileUploaderController from "../../../../components/FileUploaderController";
import FormGroup, {FormBlockTitle} from "../../../../components/migrationcomponents/FormGroup";
import DocumentView from '../../../../components/viewpdf/DocumentView';

const PeopleFormCese = ({value, edit}) => {
	const {register, handleSubmit, reset, control} = useForm();
	const [show, setShow] = useState(false);
	const {id} = useParams();
	const navigate = useNavigate();

	const {wrapper, refresh, openDeleteModal} = useDeleteModal({
		text: '¿Estas seguro de realizar esta acción?'
	});

	const getData = (params) => {
		params.employeeId = id;
		// if (params.docCese) {
		//   params.docCese = {
		//     name: params?.docCese.split('/').pop(),
		//     url: params?.docCese,
		//   }
		// }
		return axios
			.post(Apis.BASE_API_URL + '/employee-cese', params)
			.then(() => {
				successToast('Los cambios se registraron correctamente');
				navigate('/personas');
			})
			.catch(() => {
				errorToast('Ocurrio un problema. Por favor intente nuevamente');
			});
	};

	const putCese = (id) =>
		axios
			.delete(Apis.BASE_API_URL + '/employee-cese/' + id)
			.then(() => {
				successToast('Los cambios se registraron correctamente');
				navigate('/personas');
			})
			.catch(() => {
				errorToast('Ocurrio un problema. Por favor intente nuevamente');
			});

	useEffect(() => {
		if (value.employeeCese) {
			reset(value.employeeCese);
			setShow(true);
		}
	}, [value.employeeCese]);

	return (
		<>
			{edit ? (
				<>
					{!show && (
						<Button
							variant='danger'
							onClick={() => {
								setShow(true);
							}}
							text={'Dar de baja'}
						/>
					)}
				</>
			) : (
				<Button variant='danger' disabled text={'Dar de baja'}/>
			)}
			{show && (
				<Card>
					<FormBlockTitle>Informacion de cese</FormBlockTitle>
					<FormGroup>
						<Input label={"Fecha cese"} type='date' {...register('fechaCese')} />
					</FormGroup>
					<FormGroup>
						<label>Tipo de cese</label>
						<SelectCessation {...register('ceseType')} />
					</FormGroup>
					<FormGroup>
						<Input label={"Motivo"} type='text' {...register('motivoCese')} />
					</FormGroup>
					<FormGroup>
						<label>Documentación de cese</label>
						<Controller
							name='docCese.url'
							control={control}
							render={({ field: { value, onChange } }) => (
								<FileUploaderController value={value} onChange={onChange} />
							)}
						/>
						<DocumentView value={value?.employeeCese?.docCese?.url} title={'Documento'} />
					</FormGroup>
					<FlexContainer gap='16px'>
						<Button variant='danger' onClick={handleSubmit(getData)} text={'Confirmar Baja'}/>
						{value.employeeCese ? (
							<Button
								variant='light'
								onClick={() =>
									openDeleteModal(value.employeeCese.id, putCese)
								}
								text={'Anular Baja'}
							/>
						) : null}
					</FlexContainer>
				</Card>
			)}
			<DeleteGenericModal {...wrapper} />
		</>
	);
};

export default PeopleFormCese;
