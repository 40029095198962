import React, { useEffect, useState, useContext } from "react";
import { Apis } from '../../utils/Apis';
import { SiteContext } from "../context/SiteContext";
import axios from 'axios'
import styled from "styled-components";
import {Input} from "@wargostec/react-components";

const UlCustom = styled.ul`
  max-height: 200px;
  overflow: auto;
  z-index: 2;
`

const LiCustom = styled.li`
  cursor: pointer;
  background-color: white;
  color: #566074;
  font-weight: normal;

  &:hover {
    color: white;
    text-decoration: none;
    background: #007bff;
  }

  &:active {
    color: #fff;
    text-decoration: none;
    background: #007bff;
  }

  .badge {
    display: inline-block;
    margin-right: 5px;
    font-size: 11px;
    padding: 3px 7px;
    border-radius: 7px;
    font-weight: normal;
    color: white;
    text-align: center;
    background-color: red;
  }
`

const CustomerSearchInput = React.forwardRef((props, ref) => {

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [noQuery, setNoQuery] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const { empresaId } = useContext(SiteContext);

  const handleSearch = (e) => {
    setLoading(true);
    setSearchValue(e?.target?.value)
    e.target.value.length > 2
      ? getCustomers(e.target.value)
      : cleanSearch()
  };

  const cleanSearch = () => {
    setNoQuery(true)
    setSearchValue(null)
    props.onChange(null)
  }

  const getCustomers = async (query) => {
    setNoQuery(false)
    const items = await axios.get(`${Apis.BASE_API_URL}/employees/search?term=${query}&empresaId=${empresaId}`)
    const options = items.data.map((i) => ({
      id: i.id,
      name: i.fullTotalname,
      document: i.document,
      status: i.status
    }));
    setOptions(options)
    setLoading(false)
  }

  const getCustomerInfo = (customerId) => {
    axios.get(`${Apis.BASE_API_URL}/employees/${customerId}`)
      .then((data) => {
        const searchValue = data.data.person.fullTotalname + ' - ' + data.data.person.document
        setSearchValue(searchValue);
        setLoading(false);
      })
  }

  const customOnchange = (item) => {
    setNoQuery(true)
    setSearchValue(item.name || item.document)
    props.onChange(item.id)
  }

  useEffect(() => {
    if (props.value > 0) {
      getCustomerInfo(props.value)
    }
  }, [props.value])

  return (
    <>
      <Input className="form-control" id="listSearch" type="search" ref={ref} placeholder="Ingrese el nombre o DNI del Cliente."
        onChange={handleSearch}
        value={searchValue}
        autoComplete="off"
      />

      <UlCustom className="list-group" id="myList">
        {options && !noQuery && options.map(item =>
          <LiCustom key={item.id} className="list-group-item" onClick={() => {
            customOnchange(item);
          }}>
            {item.name} | {item.document}  {
              item.status === 'CESADO' ? <span className="badge">Baja</span> : ''
            }
          </LiCustom>
        )}
      </UlCustom>
    </>
  )
})
export default CustomerSearchInput

