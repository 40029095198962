import React, { useEffect } from "react";
import { Apis } from '../../../../utils/Apis';
import axios from 'axios';
import AreasForm from "./AreasForm";

const AreasNew = ({ setAction, setTitle }) => {
  const save = (params) => {
    return axios.post(Apis.BASE_API_URL + '/areas', params);
  }

  useEffect(() => {
    setTitle("Nueva área");
  }, []);

  return <AreasForm value={{}} onSubmit={save} setAction={setAction} />
};

export default AreasNew;
