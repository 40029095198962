import React from "react";
import Modal from "react-bootstrap/Modal";
import {Button} from "@wargostec/react-components";

const DeleteGenericModal = ({
  show,
  onHide,
  action,
  title = "Confirmación",
  text = "¿Esta seguro que desea realizar esta accion?",
  ...props
}) => {
  return (
    <Modal onHide={onHide} show={show} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} text={"Cancelar"} variant={"light"} />
        <Button variant="primary" onClick={action} text={"Confirmar"} />
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteGenericModal;
