import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {Checkbox, FlexContainer, Input} from "@wargostec/react-components";

const FormEditPeriods = ({ value, onSubmit, setAction }) => {
  const { register, handleSubmit, reset,watch } = useForm({
    defaultValues: {
      ini: value.ini,
      fin: value.fin,
      blocked: value.blocked
    },
  });

  const checked = watch("blocked");

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, []);

  return (
    <div>
      <FlexContainer flexDirection="row" alignItems="center" margin={'0 0 32px 0'}>
        <Checkbox {...register('blocked')} defaultChecked={value.blocked} />
        <span>
        {checked && 'Cerrado, no puede registrar operaciones'}
        {!checked &&'Abierto, puede registrar operaciones.'}
      </span>
      </FlexContainer>
      <FlexContainer flexDirection="row" gap='16px'>
        <Input label={"Fecha ini"} name="ini" type={"date"} {...register("ini")} />
        <Input label={"Fecha fin"} name="fin" type={"date"} {...register("fin")} />
      </FlexContainer>
    </div>
  );
};

export default FormEditPeriods;
