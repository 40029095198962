import React from "react";
import {Title} from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";

const AssignmentMassive = () => {
  return (
    <PageSection>
      <div style={{marginBottom: '1rem'}}>
        <Title text='Asignación masiva' type={'page'}/>
      </div>
    </PageSection>
  )
};

export default AssignmentMassive;
