import React from 'react';
import AbsenceForm from './AbsenceForm';
import {Preview} from "../../../../components/migrationcomponents/preview";
import {useNavigate} from 'react-router-dom';
import PageSection from '../../../../components/layout/PageSection';
import axios from 'axios';
import {Apis} from '../../../../utils/Apis';
import {errorToast, successToast} from "../../../../components/Toast";
import {Card} from "@wargostec/react-components";

const AbsenceNew = () => {
  const navigate = useNavigate();
  const save = (params) => {
    if (!params.fin) {
      params.fin = params.ini;
    }
    return axios.post(Apis.BASE_API_URL + '/inasistencias', params).then((res) => {
      successToast("Los cambios se registraron correctamente");
      navigate('/inasistencia');
    }).catch((err) => {
      let toastTxt = "Ocurrio un problema. Por favor intenta nuevamente"
      if (err.response !== undefined) {
        toastTxt = err.response.data.message
      }
      errorToast(toastTxt);
    });
  };

  return (
    <PageSection>
      <Preview text='Regresar' onClick={() => navigate(-1)} />
      <Card padding>
        <AbsenceForm value={{}} onSubmit={save} />
      </Card>
    </PageSection>
  );
};

export default AbsenceNew;
