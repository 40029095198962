import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styled from 'styled-components';
import * as FaIcons from "react-icons/fa";
import SelectTipoDoc from '../../../../components/selects/SelectTipoDoc';
import SelectNacionality from '../../../../components/selects/SelectNacionality';
import SelectCivil from '../../../../components/selects/SelectCivil';
import CountrySearch from './CountrySearch';
import {Col, Row} from "../../../../components/migrationcomponents/flexlayout";
import FileUploaderController from "../../../../components/FileUploaderController";
import FormGroup, {FormBlockTitle} from "../../../../components/migrationcomponents/FormGroup";
import {Input} from "@wargostec/react-components";

const ContainerGrids = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
`;

const ContainerFlex = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  @media (max-width: 997px) {
    flex-direction: column;
  }
`;

const InputRadio = styled.div`
    display: flex;
    align-items: center;
    label {
      font-size: 0.9em;
      font-weight: 400;
    }
`

const CustomerForm = ({ value, onSubmit, setAction, edit }) => {

  const { register, handleSubmit, reset, control } = useForm();

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [value]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, []);

  return (
    <>
      <FormBlockTitle>
        Datos personales
      </FormBlockTitle>
      <ContainerFlex>
        <div style={{ width: '75%' }}>
          <Row>
            <Col>
              <FormGroup>
                <label>
                  Documento
                </label>
                {
                  edit ? <Input icon={<FaIcons.FaRegIdCard />} placeholder='Documento' {...register('document')} disabled /> : <Input icon={<FaIcons.FaRegIdCard />} placeholder='Documento' {...register('document')} />
                }
              </FormGroup>
              <FormGroup>
                <Input label={"Apellido paterno"} placeholder='Apellido Paterno' {...register('lastName1')} />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>
                  Tipo documento
                </label>
                <SelectTipoDoc {...register('documentTypeId')} />
              </FormGroup>
              <FormGroup>
                <Input label={"Apellido materno"} placeholder='Apellido Materno' {...register('lastName2')} />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Input label={"Nombres"} placeholder='Nombres' {...register('firstName')} />
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Input label={"Nacimiento"} type='date' placeholder='Nacimiento' {...register('birthday')} />
              </FormGroup>
              <FormGroup>
                <label>
                  Género
                </label>
                <InputRadio>
                  <input type='radio' name='gender' value='M' {...register('gender')} />
                  <FaIcons.FaMale />
                  <label> Masculino</label>
                </InputRadio>
                <InputRadio>
                  <input type="radio" value='F' {...register('gender')} />
                  <FaIcons.FaFemale />
                  <label>Femenino</label>
                </InputRadio>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>
                  Nacionalidad
                </label>
                <SelectNacionality {...register('countryId')} />
              </FormGroup>
              <FormGroup>
                <label>
                  Estado Civil
                </label>
                <SelectCivil {...register('civil')} />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div style={{ width: '25%' }}>
          <Controller
            name='photo'
            control={control}
            render={({ field: { value, onChange } }) => (
              <FileUploaderController value={value} onChange={onChange} folder={'persons'}/>
            )}
          />
        </div>
      </ContainerFlex>
      <FormBlockTitle>
        Ubicación
      </FormBlockTitle>
      <ContainerGrids>
        <Controller
          name='ubigeo'
          control={control}
          render={({ field }) => <CountrySearch {...field} />}
        />
      </ContainerGrids>
      <FormGroup>
        <Input label={"Dirección"}  icon={<FaIcons.FaMapMarker />} placeholder='Nombre' {...register('address')} />
      </FormGroup>
      <FormBlockTitle>
        Contacto
      </FormBlockTitle>
      <Row>
        <Col>
          <FormGroup>
            <Input label={"Celular"}  icon={<FaIcons.FaTabletAlt />} placeholder='Nombre' {...register('cellphone')} />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Input label={"Email"}  type='email' icon={<FaIcons.FaEnvelope />} placeholder='Email' {...register('email')} />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Input label={"Telf. Fijo"}  icon={<FaIcons.FaPhone />} placeholder='Nombre' {...register('telephone')} />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default CustomerForm;
