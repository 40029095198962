import {DateTime} from "luxon";
import moment from "moment";

const getCasinoRange = () => {
  let ini = DateTime.now()
  const hour = ini.hour

  if (hour < 8){
    ini = ini.minus({ day: 1 })
  }

  ini = ini.set({ hour: 8 }).set({ minutes: 0 }).set({ seconds: 0 })

  const fin = ini.plus({ day: 1 })

  return { ini: DateTime.now().toFormat("yyyy'-'LL'-'dd'T'HH':'mm':'ss")  , fin : DateTime.now().toFormat("yyyy'-'LL'-'dd'T'HH':'mm':'ss")}
}

const getCasinoDay = () => {
  let ini = DateTime.now()
  const hour = ini.hour

  if (hour < 8){
    ini = ini.minus({ day: 1 })
  }

  return ini.toISODate()
}

const getDateFilter = (mode) => {

  let date = {}
  if (mode === 'day') {
    const today = moment(new Date()).format('YYYY-MM-DD')
    date = {
      ini: today,
      fin: today,
    }
  }
  if (mode === 'month') {
    const firstDayOfMonth = moment(moment(new Date)).startOf('month').format('YYYY-MM-DD');
    const lastDayOfMonth = moment(moment(new Date)).endOf('month').format('YYYY-MM-DD');
    date = {
      ini: firstDayOfMonth,
      fin: lastDayOfMonth,
    }
  }

  return date
}

const getStartDateTimeFromRaffle = (fechaHora, relativePeriod, relativePeriodAmount ) => {
  let momentPeriod = 'hours'

  if (relativePeriod === 'WEEK'){
    momentPeriod = 'weeks'
  } else if(relativePeriod === 'DAY'){
    momentPeriod = 'days'
  }else if(relativePeriod === 'MONTH'){
    momentPeriod = 'months'
  }

  return moment(fechaHora).add( -1 * relativePeriodAmount, momentPeriod).format('DD/MM HH:mm');
}

export { getCasinoDay , getDateFilter, getStartDateTimeFromRaffle}

export default getCasinoRange

