import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import {Input} from "@wargostec/react-components";

const FormPosition = ({ value, onSubmit, setAction }) => {

  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [value]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, []);

  return (
    <>
      <Input label={"Nombre"} placeholder="Nombre" {...register("name")}/>
    </>
  );
};

export default FormPosition;
