import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {CreateStyles} from './styles/global';
import {ToastContainer} from 'react-toastify';
import WauthProvider from './auth/WauthProvider';
import CallbackPage from './login/CallbackPage';
import Selector from './page/Selector';
import LogoutPage from './login/LogoutPage';
import Layout from './layout/Layout';
import Areas from './page/human/staff/areas/Areas';
import Position from './page/human/staff/position/Position';
import People from './page/human/staff/employees/People';
import PeopleNew from './page/human/staff/employees/PeopleNew';
import PeopleEdit from './page/human/staff/employees/PeopleEdit';
import Records from './page/human/attendance/records/Records';
import Marking from './page/human/attendance/markings/Marking';
import Assignment from './page/human/progra-scheudule/assignment/Assignment';
import AssignmentMassive from './page/human/progra-scheudule/assignment-massive/AssignmentMassive';
import Schedule from './page/human/progra-scheudule/schedule/Schedule';
import Restrictions from './page/human/progra-scheudule/restrictions/Restrictions';
import TurnList from './page/human/progra-scheudule/turn/TurnList';
import TurnEdit from './page/human/progra-scheudule/turn/TurnEdit';
import TurnNew from './page/human/progra-scheudule/turn/TurnNew';
import Measures from './page/human/events/disciplinary/Measures';
import MeasureEdit from './page/human/events/disciplinary/MeasureEdit';
import MeasureNew from './page/human/events/disciplinary/MeasureNew';
import Holidays from './page/human/events/holidays/Holidays';
import HolidaysNew from './page/human/events/holidays/HolidaysNew';
import HolidaysEdit from './page/human/events/holidays/HolidaysEdit';
import TypeOfEvents from './page/human/events/typeOfEvent/TypeOfEvents';
import Atrasos from './page/human/justifications/atrasos/Atrasos';
import Absence from './page/human/justifications/absence/Absence';
import AbsenceNew from './page/human/justifications/absence/AbsenceNew';
import AbsenceEdit from './page/human/justifications/absence/AbsenceEdit';
import SurveyList from './page/human/requests/survey/SurveyList';
import SurveyEdit from './page/human/requests/survey/SurveyEdit';
import HoursExtra from './page/human/requests/hoursExtras/HoursExtra';
import NewSurvey from './page/human/requests/newSurvey/NewSurvey';
import Human from './page/human/Human';
import Regulation from './page/human/others/regulation/Regulation';
import Periods from './page/human/others/periods/Periods';
import ConfigurationOther from './page/human/others/configuration/ConfigurationOther';
import Clock from './page/human/clocks/Clock';
import Notifications from './page/human/alert/notification/Notifications';
import Analysis from './page/human/alert/analysis/Analysis';
import Configuration from './page/human/alert/configuration/Configuration';
import TardyReport from './page/human/report/tardy/TardyReport';
import VacationReport from './page/human/report/vacation/VacationReport';
import AssistanceReport from './page/human/report/assistance/AssistanceReport';
import VacationRequests from './page/human/requests/vacations/VacationRequests';
import NewSurveyNew from './page/human/requests/newSurvey/NewSurveyNew';
import Permissions from './page/human/requests/permissions/Permissions';

function App() {
	return (
		<BrowserRouter basename='/'>
			<WauthProvider
				domain={process.env.REACT_APP_AUTH_SERVER}
				clientId={'51qlSpMePOWHCD17Aw7fhwVRwbuKo5hE'}
				redirectUri={window.location.origin + '/callback'}
			>
				<CreateStyles />
				<ToastContainer />
				<Routes>
					<Route path='/' element={<Layout />}>
						<Route path='areas' element={<Areas />} />
						<Route path='cargos' element={<Position />} />
						<Route path='personas' element={<People />} />
						<Route path='personas-nuevo' element={<PeopleNew />} />
						<Route path='personas-edit/:id' element={<PeopleEdit />} />
						<Route path='registros' element={<Records />} />
						<Route path='marcaciones' element={<Marking />} />
						<Route path='asignacion' element={<Assignment />} />
						<Route path='asignacion-masiva' element={<AssignmentMassive />} />
						<Route path='horarios' element={<Schedule />} />
						<Route path='restricciones' element={<Restrictions />} />
						<Route path='turno' element={<TurnList />} />
						<Route path='editar-turno/:id' element={<TurnEdit />} />
						<Route path='nuevo-turno' element={<TurnNew />} />
						<Route path='medidas' element={<Measures />} />
						<Route path='medidas-edit/:id' element={<MeasureEdit />} />
						<Route path='medidas-nuevo' element={<MeasureNew />} />

						<Route path='convenio-feriados' element={<Holidays />} />
						<Route path='convenio-feriados-nuevo' element={<HolidaysNew />} />
						<Route
							path='convenio-feriados-edit/:id'
							element={<HolidaysEdit />}
						/>

						<Route path='tipo-de-evento' element={<TypeOfEvents />} />
						<Route path='atrasos' element={<Atrasos />} />

						<Route path='inasistencia' element={<Absence />} />
						<Route path='inasistencia-nuevo' element={<AbsenceNew />} />
						<Route path='inasistencia-edit/:id' element={<AbsenceEdit />} />

						<Route path='encuesta-salida' element={<SurveyList />} />
						<Route path='edit-encuesta-salida/:id' element={<SurveyEdit />} />
						<Route path='horas-extras' element={<HoursExtra />} />
						<Route path='nueva-encuesta-salida' element={<NewSurvey />} />
						<Route
							path='nueva-encuesta-salida-nuevo'
							element={<NewSurveyNew />}
						/>
						<Route path='permisos' element={<Permissions />} />
						<Route
							path='vacaciones-solicitudes'
							element={<VacationRequests />}
						/>
						<Route path='reporte-asistencia' element={<AssistanceReport />} />
						<Route path='reporte-vacaciones' element={<VacationReport />} />
						<Route path='reporte-tardanza' element={<TardyReport />} />
						<Route path='alertas' element={<Configuration />} />
						<Route path='grupo-analisis' element={<Analysis />} />
						<Route path='grupo-notificaciones' element={<Notifications />} />
						<Route path='relojes' element={<Clock />} />
						<Route
							path='configuracion-otros'
							element={<ConfigurationOther />}
						/>
						<Route path='periodos' element={<Periods />} />
						<Route path='reglamento' element={<Regulation />} />
						<Route path='/' element={<Human />} />
					</Route>
					<Route path='selector' element={<Selector />} />
					<Route path='callback' element={<CallbackPage />} />
					<Route path='logout' element={<LogoutPage />} />
				</Routes>
			</WauthProvider>
		</BrowserRouter>
	);
}

export default App;
