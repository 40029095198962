import React from "react";
import {Select} from "@wargostec/react-components";

const SelectTipoDoc = React.forwardRef((props, ref) => {
  const data = [
    { name: "DNI", value: 1, id: 1 },
    { name: "CARNET DE EXTRANJERIA", value: 2, id: 2 },
    { name: "PASAPORTE", value: 3, id: 3 }
  ];

  return (
    <>
      <Select ref={ref} {...props} >
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  );
});

export default SelectTipoDoc;
