import React, { useEffect } from "react";
import { Apis } from '../../../../utils/Apis';
import axios from 'axios';
import RegulationForm from './RegulationForm';

const RegulationNew = ({ setAction, setTitle }) => {
  const save = (params) => {
    return axios.post(Apis.BASE_API_URL + '/reglamento-interno', params);
  }

  useEffect(() => {
    setTitle("Nuevo Reglamento Interno");
  }, []);

  return (
    <>
      <RegulationForm value={{}} onSubmit={save} setAction={setAction} />
    </>
  );
};

export default RegulationNew;