import React, { useEffect, useState } from "react";
import { Apis } from '../../../../utils/Apis';
import * as FaIcons from "react-icons/fa";
import axios from 'axios'
import styled from "styled-components";
import CustomerNew from "./CustomerNew";
import CustomerEdit from "./CustomerEdit";
import useEditModal from '../../../../hooks/useEditModal';
import ModalGeneric from '../../../../components/Modal';
import { errorToast } from '../../../../components/Toast';
import {Button, Input, ViewerImage,FlexContainer} from "@wargostec/react-components";
import {LuFileEdit, LuX} from "react-icons/lu";

const UlCustom = styled.ul`
  max-height: 200px;
  overflow: auto;
  z-index: 2;
`

const LiCustom = styled.li`
  cursor: pointer;
  background-color: white;
  color: #566074;
  font-weight: normal;

  &:hover {
    color: white;
    text-decoration: none;
    background: #007bff;
  }

  &:active {
    color: #fff;
    text-decoration: none;
    background: #007bff;
  }
`

const CustomerSearchForm = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [noQuery, setNoQuery] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [change, setChange] = useState('');

  const { wrapper,refresh, addAction, editAction } = useEditModal({
    addComponent: <CustomerNew />,
    editComponent: <CustomerEdit />,
  });

  const handleSearch = (e) => {
    setLoading(true);
    setSearchValue(e?.target?.value)
    e.target.value.length > 0
      ? getCustomers(e.target.value)
      : cleanSearch()
  };

  const cleanSearch = () => {
    setNoQuery(true)
    setSearchValue(null)
    props.onChange(null)
  }

  const getCustomers = async (query) => {
    setNoQuery(false)
    const items = await axios.get(`${Apis.BASE_API_URL}/persons/search?term=${query}`)
    const options = items.data.map((i) => ({
      id: i.id,
      name: i.fullname,
      document: i.document,
    }));
    setOptions(options)
    setLoading(false)
  }

  const getCustomerInfo = (customerId) => {
    axios.get(`${Apis.BASE_API_URL}/persons/${customerId}`)
      .then((data) => {
        const searchValue = data.data.fullname + ' - ' + data.data.document
        setSearchValue(searchValue);
        setData(data.data);
        setLoading(false);
        setShow(true);
      })
      .catch((error) => {
        errorToast(error.response.data.message);
        addAction({ data: searchValue });
        cleanSearch();
        setLoading(false);
      });
  }

  const customOnchange = (item) => {
    setChange(item)
    setNoQuery(true)
    setSearchValue(item.name || item.document)
    props.onChange(item.id)
  }

  useEffect(() => {
    if (props.value > 0) {
      getCustomerInfo(props.value)
    }
  }, [props.value, refresh])

  const getPhotoUrl = (photo) => {
    const notImage = 'https://res.cloudinary.com/dghqjv5az/image/upload/v1629393973/mix/profile03_k7zkt9.png'
    if (!photo) {
      return notImage
    }
    let url = "https://res.cloudinary.com/dghqjv5az/image/upload/w_906,h_906,c_limit,q_auto,f_auto/linkedasis/persons/"

    return url + photo
  }

  return (
    <>
      {
        !show &&
        <Input icon={<FaIcons.FaPlus onClick={() => {
          searchValue === '' || searchValue === null ? addAction() : getCustomerInfo(searchValue);
        }} cursor='pointer' color='#0674db' />} className="form-control" id="listSearch" type="text" ref={ref} placeholder="Ingrese el nombre o DNI del Cliente."
          onChange={handleSearch}
          value={searchValue}
          autoComplete="off"
        />
      }
      {
        show &&
        <FlexContainer gap='8px' alignItems="center">
          <ViewerImage src={getPhotoUrl(data?.photo)} zoom={true} height={50} width={50} border={'circle'}/>
          <FlexContainer flexDirection="column">
            <strong>{data.fullname}</strong>
            <span>{data.document}</span>
          </FlexContainer>
          <FlexContainer gap='4px'>
            <Button 
              variant='primary' 
              size='xs' 
              onClick={() => editAction(data.id)} 
              icon={<LuFileEdit size={16} />}
            />
            <Button 
              onClick={() => {
                cleanSearch();
                setShow(false)
              }} 
              variant='danger' 
              size='xs' 
              icon={<LuX size={16}/>} 
            />
          </FlexContainer>
        </FlexContainer>
      }

      <UlCustom className="list-group" id="myList">
        {options && !noQuery && options.map(item =>
          <LiCustom key={item.id} className="list-group-item" onClick={() => {
            customOnchange(item);
          }}>
            {item.name} | {item.document}
          </LiCustom>
        )}
      </UlCustom>
      <ModalGeneric {...wrapper} size='lg' />
    </>
  )
})
export default CustomerSearchForm

