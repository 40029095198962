import React, {createContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

export const SiteContext = createContext({
	empresaId: null,
	sucursalId: null,
	sucursal: null,
	setSucursal: () => {},
	setEmpresaId: () => {},
	setSucursalId: () => {}
});

export const SiteProvider = (props) => {
	const navigate = useNavigate();

	const [empresaId, setEmpresaId] = useState(() => {
		const storedEmpresaId = localStorage.getItem('sucursal');
		return storedEmpresaId ? JSON.parse(storedEmpresaId).companyId : null;
	});

	const [sucursalId, setSucursalId] = useState(() => {
		const storedSucursalId = localStorage.getItem('sucursal');
		return storedSucursalId ? JSON.parse(storedSucursalId).id : null;
	});
	
	const [sucursal, setSucursal] = useState({});

	useEffect(() => {
		const storedSucursal = localStorage.getItem('sucursal');
		if (!storedSucursal) {
			navigate('/selector');
			return;
		}
		const parsedSucursal = JSON.parse(storedSucursal);
		if (!parsedSucursal || !parsedSucursal.companyId || !parsedSucursal.id) {
			navigate('/selector');
			return;
		}

		setEmpresaId(parsedSucursal.companyId);
		setSucursalId(parsedSucursal.id);
		setSucursal(parsedSucursal);
	}, [navigate]);

	return (
		<SiteContext.Provider
			value={{
				empresaId,
				sucursalId,
				sucursal,
				setSucursal,
				setEmpresaId,
				setSucursalId
			}}
		>
			{props.children}
		</SiteContext.Provider>
	);
};
