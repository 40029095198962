import React, {useState} from "react";

const useModal = ({component}) => {

  const [refresh, setRefresh] = useState(1)
  const [showModal, setShowModal] = useState(false);

  const [body, setBody] = useState(null);

  const someAction = (args) => {
    setShowModal(true)
    setBody(React.cloneElement(component, {...args}))
  }

  //TODO, incluir parametro para ver si debemos refrecar o no
  const onCloseModal = () => {
    setShowModal(false)
    setRefresh(refresh + 1)
  }

  const wrapper = {
    show: showModal,
    onHide: onCloseModal,
    body: body
  }

  return {wrapper, refresh, someAction}
}

export default useModal

