import React, {useEffect, useContext, useCallback} from "react";
import * as FaIcons from "react-icons/fa";
import { Apis } from "../../../../utils/Apis";
import useFetch2 from "../../../../hooks/useFetch2";
import useEditModal from "../../../../hooks/useEditModal";
import ModalGeneric from "../../../../components/Modal";
import EditPeriods from "./EditPeriods";
import DeleteGenericModal from "../../../../components/modals/DeleteGenericModal";
import useDeleteModal from "../../../../hooks/useDeleteModal";
import axios from "axios";
import NewPeriods from "./NewPeriods";
import PageSection from '../../../../components/layout/PageSection';
import { SiteContext } from '../../../../components/context/SiteContext';
import {Button, FlexContainer, Table, Title} from "@wargostec/react-components";
import EmptyState from "../../../../components/migrationcomponents/emptystate";
import {LuFileEdit, LuLock, LuTrash2, LuUnlock} from "react-icons/lu";
import {AiOutlineCloudDownload} from "react-icons/ai";

const Periods = () => {
  const {sucursalId, empresaId} = useContext(SiteContext);
  const baseURL = Apis.BASE_API_URL + "/periodos";
  const params = {empresaId};
  const {loading, data, fetchData, error} = useFetch2(baseURL, [], params);

  const { wrapper, refresh, editAction, addAction } = useEditModal({
    editComponent: <EditPeriods />,
    addComponent: <NewPeriods />,
  });

  const {
    wrapper: wrapper1,
    refresh: refresh1,
    openDeleteModal,
  } = useDeleteModal({ text: "¿Desea eliminar esta periodo?"});

  const deleteElement = async (id) => await axios.delete(baseURL + `/${id}`);

  const downloadReport = async (ini,fin) => {
    window.location.href =
      await baseURL +
      `/report.xlsx?ini=${ini}&fin=${fin}&sucursalId=${sucursalId}`;
  };

  const downloadAlvisoft = async (ini,fin) => {
    window.location.href =
      await baseURL +
      `/report-alvisoft.xlsx?ini=${ini}&fin=${fin}&sucursalId=${sucursalId}`;
  };

  const estadoColumn = useCallback((props) => {
    const x = props.row.original;
    return (
      <>
        {x.blocked ? <LuLock size={18} /> : <LuUnlock size={18} /> }
      </>
    );
  }, []);

  const actionValue = useCallback((props) => {
    const id = props.row.original.id;
    const ini = props.row.original.ini;
    const fin = props.row.original.fin;

    return (
      <FlexContainer flexDirection="row" justifyContent="center" gap='8px'>
        <Button 
          variant={'dark'} 
          size={'xs'} 
          onClick={() => downloadReport(ini,fin)}
          text={'Generar'} 
          icon={<AiOutlineCloudDownload />}
        />
        <Button 
          variant="light"
          size="xs" 
          onClick={() => downloadAlvisoft(ini,fin)} 
          text={'Reporte Alvisoft'}
          icon={<AiOutlineCloudDownload />}
        />
        <Button variant='light' size='xs' onClick={() => editAction(id)}
                icon={<LuFileEdit fontSize='1.3em' color='black' />}/>
        <Button variant='danger' size='xs' onClick={() => openDeleteModal(id, deleteElement)}
                icon={<LuTrash2 />}/>
      </FlexContainer>
    );
  }, []);

  const columns = [
    {
      header: "Estado",
      accessorKey: "",
      cell: estadoColumn,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    { 
      header: "Inicio", 
      accessorKey: "ini",
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      }, 
    },
    { 
      header: "Fin", 
      accessorKey: "fin",
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      }, 
    },
    {
      header: "Acción",
      accessorKey: "id",
      cell: actionValue,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [refresh, refresh1]);

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Periodos' type={'page'}/>
        </div>
        <Button variant="primary" onClick={addAction} icon={<FaIcons.FaPlus size={'15px'}/>} text={'Nuevo'} />
      </PageSection>
      <PageSection>
        <Table 
          columns={columns} 
          data={data}
          pageSize={50}
          sortArray={[{id:'ini', desc: true}]}
          emptyListText={
            <EmptyState
              title='Sin resultados'
              subtitle='Haga click en filtros para realizar una nueva consulta.'
              data={data}
              loading={loading}
              error={error}
            />
          } />
      </PageSection>
      <ModalGeneric {...wrapper} />
      <DeleteGenericModal {...wrapper1} />
    </>
  );
};

export default Periods;
