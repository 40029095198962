import React, { useEffect } from 'react';
import FingerPrintForm from './FingerPrintForm';
import { Apis } from '../../../../utils/Apis';
import axios from 'axios';
import {useParams} from "react-router-dom";

const FingerPrintNew = ({ data, setAction, setTitle }) => {

  const {id} = useParams();

  const save = (params) => {
    params.employeeId = id;
    return axios.post(Apis.BASE_API_URL + '/clocks/enroll', params);
  }
  useEffect(() => {
    setTitle("Registrar huella en marcador");
  }, []);

  return <FingerPrintForm value={data} onSubmit={save} setAction={setAction} />
};

export default FingerPrintNew;

