import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styled from 'styled-components';
import * as FaIcons from 'react-icons/fa';
import SelectArt from '../../../../components/selects/SelectArt';
import PageSection from '../../../../components/layout/PageSection';
import {useNavigate} from 'react-router-dom';
import CustomerSearchInput from '../../../../components/selects/CustomerSearchInput';
import {Button, Card, Input, FlexContainer} from "@wargostec/react-components";
import FileUploaderController from "../../../../components/FileUploaderController";
import {Preview} from "../../../../components/migrationcomponents/preview";
import FormGroup, {FormBlockTitle} from "../../../../components/migrationcomponents/FormGroup";

const ContainerInputs = styled.div`
	> label {
		margin-left: 10px;
		font-size: 14px;
	}
`;
const MeasureForm = ({value, onSubmit, edit}) => {
	const name = value?.employee?.person?.lastNameFirstName;
	const navigate = useNavigate();

	const {register, handleSubmit, reset, control,watch} = useForm({
		defaultValues: {
			fechaOcurrencia: value?.fechaOcurrencia,
			reglamentoId: value?.reglamentoId,
			descripcion: value?.descripcion,
			tipo: value?.tipo
		}
	});

	const watchTipo = watch("tipo");

	useEffect(() => {
		if (value) {
			reset(value);
		}
	}, [value]);

	useEffect(() => {
		handleSubmit(onSubmit);
	}, []);

	return (
		<PageSection>
			<div style={{marginBottom:'16px'}}>
				<Preview text='Regresar' onClick={() => navigate(-1)} />
			</div>
			<Card>
				<FormBlockTitle>Nueva Medida Disciplinaria</FormBlockTitle>
				<FormGroup>
					{edit ?
						<Input label={"Empleado"} icon={<FaIcons.FaUser />} disabled value={name} />
						:
						<Controller
							name='employeeId'
							control={control}
							render={({field}) => <CustomerSearchInput disable {...field} />}
						/>
					}
				</FormGroup>
				<FormGroup>
					<Input label={"Fecha de ocurrencia"} type='date' {...register('fechaOcurrencia')} />
				</FormGroup>
				<FormGroup>
					<label>Reglamento</label>
					<SelectArt {...register('reglamentoId')} />
				</FormGroup>
				<FlexContainer flexDirection='column' gap='4px' margin='0 0 16px 0'>
					<label style={{fontWeight:600}}>Motivo</label>
					<textarea rows='7' {...register('descripcion')} />
				</FlexContainer>
				<FormGroup>
					<label>Sanción</label>
					<ContainerInputs>
						<input
							type='radio'
							value='AMONESTACION_VERBAL'
							{...register('tipo')}
						/>
						<label>AMONESTACION VERBAL</label>
					</ContainerInputs>
					<ContainerInputs>
						<input
							type='radio'
							value='AMONESTACION_ESCRITA'
							{...register('tipo')}
						/>
						<label>AMONESTACION ESCRITA</label>
					</ContainerInputs>
					<ContainerInputs>
						<input type='radio' value='SUSPENSION' {...register('tipo')} />
						<label>SUSPENSION</label>
					</ContainerInputs>
				</FormGroup>
				{watchTipo ==='SUSPENSION' &&
					<FormGroup>
						<FlexContainer flexDirection='row' gap='16px'>
							<Input label={"Inicio de suspensión"} type='date' {...register('inicio')} />
							<Input label={"Fin de suspensión"} type='date' {...register('fin')} />
						</FlexContainer>
				</FormGroup>
				}
				<FormGroup>
					<label>Documentación firmada</label>
					<Controller
						name='doc.url'
						control={control}
						render={({field: {value, onChange}}) => (
							<FileUploaderController value={value} onChange={onChange} />
						)}
					/>
				</FormGroup>
				<Button variant='primary' onClick={handleSubmit(onSubmit)} text={'Guardar'} />
			</Card>
		</PageSection>
	);
};

export default MeasureForm;
