import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../utils/Apis';
import TurnForm from './TurnForm';
import axios from 'axios';
import {errorToast, successToast} from '../../../../components/Toast';
import {Preview} from "../../../../components/migrationcomponents/preview";
import PageSection from '../../../../components/layout/PageSection';

const TurnEdit = () => {
	const baseURL = Apis.BASE_API_URL + '/turnos/';
	const {id} = useParams();
	const navigate = useNavigate();
	const {data, loading} = useFetch2(baseURL + id,[]);

	const update = (params) => {
		axios
			.put(baseURL + id, params)
			.then((res) => {
				successToast('Los cambios se registraron correctamente');
				navigate('/turno');
			})
			.catch((err) => {
				errorToast('Ocurrio un problema. Por favor intente nuevamente');
			});
	};

	return (
		<PageSection>
			<Preview text='Regresar' onClick={() => navigate(-1)} />
			{data && !loading ? (
				<TurnForm value={data} onSubmit={update} title={'Editar Turno'} />
			) : (
				<p style={{textAlign: 'center'}}>Cargando...</p>
			)}
		</PageSection>
	);
};

export default TurnEdit;
