import React, {useContext} from 'react';
import {Preview} from "../../../../components/migrationcomponents/preview";
import {useNavigate} from 'react-router-dom';
import TurnForm from './TurnForm';
import {Apis} from '../../../../utils/Apis';
import axios from 'axios';
import {errorToast, successToast} from '../../../../components/Toast';
import {SiteContext} from '../../../../components/context/SiteContext';

const TurnNew = () => {
	const {sucursalId} = useContext(SiteContext);
	const baseURL = Apis.BASE_API_URL + '/turnos';
	const navigate = useNavigate();

	const newTurn = (params) => {
		params.sucursalId = sucursalId;
		axios
			.post(baseURL, params)
			.then(() => {
				successToast('Los cambios se registraron correctamente');
				navigate('/turno');
			})
			.catch(() => {
				errorToast('Ocurrio un problema. Por favor intente nuevamente');
			});
	};

	return (
		<div>
			<Preview text='Regresar' onClick={() => navigate(-1)} />
			<TurnForm onSubmit={newTurn} title={'Nuevo Turno'} />
		</div>
	);
};

export default TurnNew;
