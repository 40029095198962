import React, { useState, useEffect } from 'react';
import CustomerForm from './CustomerForm';
import axios from 'axios';
import { Apis } from '../../../../utils/Apis';

const CustomerNew = ({ data, setAction, setTitle }) => {
  const save = (params) => {
    return axios.post(Apis.BASE_API_URL + '/persons', params);
  }

  useEffect(() => {
    setTitle("Nuevo Empleado");
  }, []);

  return <CustomerForm value={{ document: data }} onSubmit={save} setAction={setAction} />
}

export default CustomerNew;