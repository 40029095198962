import React, {useContext, useEffect, useState} from 'react';
import * as FaIcons from 'react-icons/fa';
import {Apis} from '../../../../utils/Apis';
import PageSection from '../../../../components/layout/PageSection';
import {Controller, useForm} from 'react-hook-form';
import CustomerSearchInput from '../../../../components/selects/CustomerSearchInput';
import RecordsNew from './RecordsNew';
import useEditModal from '../../../../hooks/useEditModal';
import ModalGeneric from '../../../../components/Modal';
import RecordsTable from './RecordsTable';
import useFetch2 from '../../../../hooks/useFetch2';
import {DateTime} from 'luxon';
import {SiteContext} from '../../../../components/context/SiteContext';
import {Button, Slidebar, Input, Title,FlexContainer} from "@wargostec/react-components";
import {BsFilter} from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";

const Records = () => {
  const [close, setClose] = useState(false);
  const { sucursalId } = useContext(SiteContext);
  const [fechas, setFechas] = useState({
    ini: DateTime.local().toISODate(),
    fin: DateTime.local().toISODate(),
  });
  let defaultParams = { sucursalId, ...fechas };
  const [params, setParams] = useState(defaultParams);
  const { loading, data, fetchData, error } = useFetch2(
    Apis.BASE_API_URL + '/marcaciones',
    [],
    params,
    {},
    false
  );

  const { wrapper, refresh, addAction } = useEditModal({
    addComponent: <RecordsNew />,
  });

  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: defaultParams,
  });
  const getData = (params) => {
    setParams(params);
    setClose(!close);
  };

  const resetForm = () => {
    setValue('ini', '');
    setValue('fin', '');
  };
  useEffect(() => {
    fetchData();
  }, [params, refresh]);

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Registros' type={'page'}/>
        </div>
        <FlexContainer gap='8px'>
          <Button variant='primary' onClick={addAction} text='Nuevo' icon={<AiOutlinePlus />}/>
          <Button onClick={() => setClose(!close)} text='Filtros' variant={'dark'} icon={<BsFilter/>}/>
        </FlexContainer>
      </PageSection>
      <PageSection>
          <RecordsTable data={data} loading={loading} fetchData={fetchData} error={error}/>
      </PageSection>
      <Slidebar
        title='Filtrar'
        toggler={() => setClose(!close)}
        isOpen={close}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
            marginTop: '1em',
          }}
        >
          <Input
            type='date'
            name={'fecha'}
            placeholder='Desde'
            {...register('ini')}
          />
          <Input
            type='date'
            name={'fecha'}
            placeholder='Hasta'
            {...register('fin')}
          />
          <label>Empleado</label>
          <Controller
            name='employeeId'
            control={control}
            render={({ field }) => <CustomerSearchInput {...field} />}
          />
          <Button variant='primary' onClick={handleSubmit(getData)} text={'BUSCAR'} icon={<FaIcons.FaSearch />}/>
          <Button variant='light' onClick={resetForm} text={'LIMPIAR'} icon={<FaIcons.FaEraser />}/>
        </div>
      </Slidebar>
      <ModalGeneric {...wrapper} />
    </>
  );
};

export default Records;
