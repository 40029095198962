import axios from "axios";
import React, { useEffect, useContext } from "react";
import { Apis } from "../../../../utils/Apis";
import ScheduleForm from "./ScheduleForm";
import { SiteContext } from '../../../../components/context/SiteContext';

const ScheduleAdd = ({ setAction, setTitle }) => {
  const baseURL = Apis.BASE_API_URL + "/horarios";
  const { sucursalId } = useContext(SiteContext);
  const addNewSchedule = async (params) => {
    params.sucursalId = sucursalId;
    await axios.post(baseURL, params);
  };

  useEffect(() => {
    setTitle("Horario");
  }, []);

  return (
    <ScheduleForm
      value={{}}
      onSubmit={addNewSchedule}
      setAction={setAction}
    />
  );
};

export default ScheduleAdd;
