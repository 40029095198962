import React, {useContext, useEffect, useState} from 'react';
import * as FaIcons from 'react-icons/fa';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../utils/Apis';
import PageSection from '../../../../components/layout/PageSection';
import {DateTime} from 'luxon';
import {useForm} from 'react-hook-form';
import SelectStatus from '../../../../components/selects/SelectStatus';
import SelectRegime from '../../../../components/selects/SelectRegime';
import Chart from 'react-apexcharts';
import {useNavigate} from 'react-router-dom';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import DeleteGenericModal from '../../../../components/modals/DeleteGenericModal';
import axios from 'axios';
import {SiteContext} from '../../../../components/context/SiteContext';
import {Button, Card, FlexContainer, Input, Slidebar, Title, ViewerImage} from "@wargostec/react-components";
import {LuFileEdit, LuTrash2} from 'react-icons/lu';
import {TbFileExport, TbFileImport} from "react-icons/tb";
import GridContainer from '../../../../components/flex/GridContainer';
import EmptyState from '../../../../components/migrationcomponents/emptystate';
import SelectPosition from "../../../../components/selects/SelectPosition";
import Table from 'react-bootstrap/Table';
import {AiOutlinePlus} from 'react-icons/ai';
import {BsFilter} from 'react-icons/bs';
import {errorToast, successToast} from "../../../../components/Toast";
import {MdSync} from "react-icons/md";

const People = () => {

	const {sucursalId, empresaId, sucursal} = useContext(SiteContext);
	const navigate = useNavigate();
	const [close, setClose] = useState(false);
	const [status, setStatus] = useState('ACTIVO');
	const [lastData, setLastData] = useState('');
	const [lastData2, setLastData2] = useState('');
	const [dataFilter, setDataFilter] = useState([]);

	let defaultParams = {empresaId, status: 'ACTIVO'};
	const [params, setParams] = useState(defaultParams);
	const {data, error, loading, fetchData} = useFetch2(Apis.BASE_API_URL + '/employees', [], params, {}, false);
	const {data: data2, fetchData: fetchData2} = useFetch2(Apis.BASE_API_URL + `/employees/count-record-company?empresaId=${empresaId}`, []);
	const {data: data3, fetchData: fetchData3} = useFetch2(Apis.BASE_API_URL + `/employees/count-record-sucursal?sucursalId=${sucursalId}`, []);

	const {register, handleSubmit, setValue} = useForm({defaultValues: defaultParams});
	const {wrapper, refresh, openDeleteModal} = useDeleteModal({text: '¿Desea eliminar este colaborador?'});

	const chartEmployees = {
		options: {
			dataLabels: {
				enabled: false
			},
			chart: {
				type: 'area',
				stacked: false,
				zoom: {
					enabled: false
				}
			},
			colors: ['#008FFB'],
			fill: {
				opacity: 1
			},
			tooltip: {
				intersect: false,
				shared: false
			},
			xaxis: {
				categories: data2.map((x) => x.fecha),
				labels: {
					show: false
				}
			},
			yaxis: {
				labels: {
					show: false
				}
			},
			stroke: {
				curve: 'smooth'
			},
			grid: {
				yaxis: {
					lines: {
						show: false
					}
				}
			}
		},
		series: [
			{
				name: 'Cantidad de empleados',
				data: data2.map((x) => x.count)
			}
		]
	};

	const chartSucursal = {
		options: {
			dataLabels: {
				enabled: false
			},
			chart: {
				type: 'area',
				stacked: false,
				zoom: {
					enabled: false
				}
			},
			colors: ['#008FFB'],
			fill: {
				opacity: 1
			},
			tooltip: {
				intersect: false,
				shared: false
			},
			xaxis: {
				categories: data3.map((x) => x.fecha),
				labels: {
					show: false
				}
			},
			yaxis: {
				labels: {
					show: false
				}
			},
			stroke: {
				curve: 'smooth'
			},
			grid: {
				yaxis: {
					lines: {
						show: false
					}
				}
			}
		},
		series: [
			{
				name: 'Cantidad de empleados',
				data: data3.map((x) => x.count)
			}
		]
	};

	const getData = (params) => {
		setParams(params);
		setClose(!close);
	};

	const resetForm = () => {
		setValue('from', '');
		setValue('to', '');
		setValue('regimen', '');
		setValue('status', '');
		setValue('cargoId', '');
		setValue('ceseIni', '');
		setValue('ceseFin', '');
		setStatus('');
	};

	const goPeopleNew = () => {
		navigate('/personas-nuevo');
	};

	const goPeopleEdit = (id) => {
		navigate('/personas-edit/' + id);
	};

	const exportReport = () => {
		const queryParams = new URLSearchParams(params).toString();
		const link = Apis.BASE_API_URL + `/employees/report.xlsx?${queryParams}`;
		window.location.href = link;
	};

	const groupData = dataFilter.reduce((acc, {cargoName, ...rest}) => {
		const existingGroup = acc.find(group => group.cargoName === cargoName);

		if (existingGroup) {
			existingGroup.groupItem.push(rest);
		} else {
			acc.push({cargoName, groupItem: [rest]});
		}

		return acc;
	}, []);

	const handleSearch = (event) => {
		const term = event.target.value.toLowerCase();
		const filteredData = data.filter(employee => {
			return employee.personLastNameFirstName.toLowerCase().includes(term);
		});
		setDataFilter(filteredData);
	};


	const deleteElement = (employeeId) =>
		axios.delete(Apis.BASE_API_URL + `/employees/${employeeId}`);

	const actionValue = (id) => (
		<>
			<FlexContainer flexDirection="row" gap="8px" justifyContent="center" alignItems="center">
				<Button
					size={'xs'}
					variant='light'
					onClick={() => goPeopleEdit(id)}
					icon={<LuFileEdit />}
					text='Editar'
				/>
				<Button
					size={'xs'}
					variant='danger'
					onClick={() => openDeleteModal(id, deleteElement)}
					icon={<LuTrash2 />}
					text='Eliminar'
				/>
			</FlexContainer>
		</>
	);

	const createdValue = (value) => (
		value ? <span>{DateTime.fromISO(value).toFormat("yyyy'-'LL'-'dd'")}</span> : <span>---</span>
	);

	const docCellValue = (item) => (
		<FlexContainer gap='4px' justifyContent='center' alignItems='center'>
			{item.docContractId > 0 && item.docAltaSunatId > 0 && item.docModSunatId > 0 ? (
				<FaIcons.FaCheck color='green' />
			) : '--'}
		</FlexContainer>
	);

	const nameCellValue = (item) => {
		const notImage = 'https://res.cloudinary.com/dghqjv5az/image/upload/v1629393973/mix/profile03_k7zkt9.png';
		let url = "https://res.cloudinary.com/dghqjv5az/image/upload/w_906,h_906,c_limit,q_auto,f_auto/linkedasis/persons/";
		const photo = item.personPhoto;

		return (
			<FlexContainer gap='8px' alignItems='center'>
				<FlexContainer justifyContent='center' alignItems='center' style={{minWidth: '55px', minHeight: '55px', borderRadius: '8px', backgroundColor: '#fff'}}>
					<ViewerImage
						src={photo ? url + photo : notImage}
						zoom={true}
						height={50}
						width={'auto'}
						border={'circle'}
					/>
				</FlexContainer>
				<div>
					<strong>
						{item.personLastNameFirstName}{' '}
						{item.status === 'CESADO' ? (
							<span className='badge' style={{background: '#dc3545'}}>Cesado</span>
						) : (
							<></>
						)}{' '}
					</strong>
					<FlexContainer gap='4px' alignItems='center'>
						<FaIcons.FaRegAddressCard />
						<span>{item.personDocument}</span>
					</FlexContainer>
				</div>
			</FlexContainer>
		);
	};


	useEffect(() => {
		fetchData();
		fetchData2();
		fetchData3();
	}, [params, refresh]);

	useEffect(() => {
		setDataFilter(data);
	}, [data]);

	useEffect(() => {
		const lastData = data2[data2?.length - 1];
		setLastData(lastData?.count);
	}, [data2]);

	useEffect(() => {
		const lastData = data3[data3?.length - 1];
		setLastData2(lastData?.count);
	}, [data3]);

	const getEmployees = (id, type) => {
		if (type === 'company') {
			setParams({...params, empresaId: id, sucursalId: ""});
		}
		if (type === 'sucursal') {
			setParams({...params, sucursalId: id, empresaId: "", status: 'ACTIVO'});
		}
	}

	const syncEmployees = () => {
		return axios.get(Apis.BASE_API_URL + `/employees/sync?empresaId=${empresaId}`)
			.then(res => {
					successToast('Sincronización realizada con éxito');
					fetchData()
				}
			).catch((err) => {
					errorToast(err ? err.response.data.message : 'Ocurrió un problema. Por favor intente nuevamente');
				}
			)
	}

	return (
		<>
			<PageSection>
				<div style={{marginBottom: '1rem'}}>
					<Title text='Empleados' type={'page'} />
				</div>
				
			</PageSection>
			<PageSection>
				<GridContainer>
					<Card>
						<FlexContainer flexDirection='column' style={{cursor: 'pointer'}}
													 onClick={() => getEmployees(empresaId, 'company')}>
							<span>Empleados en {sucursal?.companyName}</span>
							<strong style={{fontSize: '24px'}}>{lastData}</strong>
						</FlexContainer>
						<Chart
							options={chartEmployees.options}
							series={chartEmployees.series}
							type='area'
							height='100px'
							style={{marginLeft: '-1.3rem'}}
						/>
					</Card>
					<Card>
						<FlexContainer flexDirection='column' style={{cursor: 'pointer'}}
													 onClick={() => getEmployees(sucursalId, 'sucursal')}>
							<span>Empleados en {sucursal?.name}</span>
							<strong style={{fontSize: '24px'}}>{lastData2}</strong>
						</FlexContainer>
						<Chart
							options={chartSucursal.options}
							series={chartSucursal.series}
							type='area'
							height='100px'
							style={{marginLeft: '-1.3rem'}}
						/>
					</Card>
				</GridContainer>
				<FlexContainer gap='8px' alignItems='center' flexWrap='wrap' style={{marginBottom:'16px'}}>
					<Button variant='primary' text='Nuevo' onClick={goPeopleNew} icon={<AiOutlinePlus />} />
					<Button variant='dark' text='Filtrar' onClick={() => setClose(!close)} icon={<BsFilter />} />
					<Button variant='light' text='Exportar' onClick={exportReport} icon={<TbFileExport />} />
					<Button variant='light' text='Importar' icon={<TbFileImport />} />
					<Button variant='light' text='Sincronizar' icon={<MdSync />} onClick={syncEmployees} />
					<Input placeholder='Filtrar por nombre' icon={<FaIcons.FaSearch />} onChange={handleSearch} />
				</FlexContainer>

				<Table hover striped responsive size="sm">
					<thead>
						<tr>
							<th className='font-sm'>Nombre</th>
							<th className='text-center font-sm'>Id</th>
							<th className='text-center font-sm'>Área</th>
							<th className='text-center font-sm'>Empresa</th>
							<th className='text-center font-sm'>Ingreso</th>
							<th className='text-center font-sm'>Doc.</th>
							<th className='text-center font-sm'>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{groupData.map(group => (
							<React.Fragment key={group.cargoName}>
								<tr>
									<td colSpan="7" className='text-bold' style={{backgroundColor: '#d6d8db', padding: '8px'}}>
										{group.cargoName} ({group.groupItem.length})
									</td>
								</tr>
								{group.groupItem.map(item => (
									<tr key={item.id}>
										<td>{nameCellValue(item)}</td>
										<td className='text-center font-sm'>{item.id}</td>
										<td className='text-center'>{item.areaName}</td>
										<td className='text-center'>{item.companyName}</td>
										<td className='text-center'>{createdValue(item.iniContrato)}</td>
										<td className='text-center'>{docCellValue(item)}</td>
										<td className='text-center'>{actionValue(item.id)}</td>
									</tr>
								))}
							</React.Fragment>
						))}
					</tbody>
					<tfoot>
          <tr>
            <td colSpan="7" className='text-bold' style={{backgroundColor: '#d6d8db', padding: '8px'}}>
              Total en lista: {dataFilter.length}
            </td>
          </tr>
        </tfoot>
				</Table>

				<EmptyState
					title='Sin resultados'
					subtitle='Haga click en filtros para realizar una nueva consulta.'
					data={data}
					loading={loading}
					error={error}
				/>

			</PageSection>
			<Slidebar
				title='Filtrar'
				toggler={() => setClose(!close)}
				isOpen={close}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '1em',
						marginTop: '1em'
					}}
				>
					<Input
						label={"Fecha de ingreso"}
						type='date'
						name={'fecha'}
						placeholder='Desde'
						{...register('from')}
					/>
					<Input
						type='date'
						name={'fecha'}
						placeholder='Hasta'
						{...register('to')}
					/>
					<label>Cargo</label>
					<SelectPosition {...register('cargoId')} />
					<label>Régimen</label>
					<SelectRegime {...register('regimen')} />
					<label>Estado</label>
					<SelectStatus
						{...register('status', {
							onChange: (e) => {
								setStatus(e.target.value);
							}
						})}
					/>
					{status === 'CESADO' ? (
						<>
							<Input
								label={"Fecha de cese (opcional)"}
								type='date'
								name={'fecha'}
								placeholder='Desde'
								{...register('ceseIni')}
							/>
							<Input
								type='date'
								name={'fecha'}
								placeholder='Hasta'
								{...register('ceseFin')}
							/>
						</>
					) : (
						<></>
					)}
					<Button variant='primary' onClick={handleSubmit(getData)} text={'BUSCAR'} icon={<FaIcons.FaSearch />} />
					<Button variant='light' onClick={resetForm} text={'LIMPIAR'} icon={<FaIcons.FaEraser />} />
				</div>
			</Slidebar>
			<DeleteGenericModal {...wrapper} />
		</>
	);
};

export default People;
