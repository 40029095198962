import axios from "axios";
import React, { useEffect, useContext } from "react";
import { Apis } from "../../../../utils/Apis";
import VacationRequestForm from "./VacationRequestForm";
import { SiteContext } from '../../../../components/context/SiteContext';
import useFetch2 from "../../../../hooks/useFetch2";

const VacationRequestEdit = ({ id, setAction, setTitle }) => {
  const baseURL = Apis.BASE_API_URL + "/vacaciones-sol";
  const { empresaId } = useContext(SiteContext);
  const {data, loading} = useFetch2(baseURL + `/${id}`, [], {});

  const update = (params) => {
    params.empresaId = empresaId;
    return axios.put(baseURL + `/${id}`, params);
  };

  useEffect(() => {
    setTitle("Editar vacaciones");
  }, []);

  return (
    <>
      {
        !loading && data && <VacationRequestForm
          value={data && data}
          onSubmit={update}
          setAction={setAction}
        />
      }
    </>
  );
};

export default VacationRequestEdit;
