import React from "react";
import {Select} from "@wargostec/react-components";

const SelectCivil = React.forwardRef((props, ref) => {
  const data = [
    { name: "SOLTERO", value: 'S', id: 1 },
    { name: "CASADO", value: 'C', id: 2 },
    { name: "VIUDO", value: 'V', id: 3 },
    { name: "DIVORCIADO", value: 'D', id: 4 },
  ];

  return (
    <>
      <Select ref={ref} {...props} >
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  );
});

export default SelectCivil;
