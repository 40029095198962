import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {Apis} from '../../../../utils/Apis';
import useFetch2 from '../../../../hooks/useFetch2';
import axios from 'axios';
import DeleteGenericModal from '../../../../components/modals/DeleteGenericModal';
import AreasNew from './AreasNew';
import AreasEdit from './AreasEdit';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import ModalGeneric from '../../../../components/Modal';
import useEditModal from '../../../../hooks/useEditModal';
import PageSection from '../../../../components/layout/PageSection';
import {Button, Title, Table, FlexContainer} from '@wargostec/react-components';
import {LuFileEdit, LuTrash2} from 'react-icons/lu';
import EmptyState from '../../../../components/migrationcomponents/emptystate';
import {errorToast, successToast} from "../../../../components/Toast";
import {SiteContext} from "../../../../components/context/SiteContext";
import {MdSync} from "react-icons/md";

const Areas = () => {

  const {empresaId} = useContext(SiteContext);

  const {wrapper, refresh, addAction, editAction} = useEditModal({
    addComponent: <AreasNew />,
    editComponent: <AreasEdit />,
  });

  const {wrapper: wrapper2,refresh: refresh2,openDeleteModal} = useDeleteModal({
    text: '¿Desea eliminar esta área?'
  });

  const deleteElement = (areaId) =>
    axios.delete(Apis.BASE_API_URL + `/areas/${areaId}`);

  const {loading, data, fetchData, error} = useFetch2(
    Apis.BASE_API_URL + '/areas',
    []
  );

  const actionValue = useCallback((props) => {

    const id = props.renderValue()

    return (
      <>
        <FlexContainer flexDirection="row" gap="8px" justifyContent="center" alignItems="center">
          <Button
            size={'xs'}
            variant='light'
            onClick={() => editAction(id)}
            icon={<LuFileEdit />}
            text='Editar'
          />
          <Button
            size={'xs'}
            variant='danger'
            onClick={() => openDeleteModal(id, deleteElement)}
            icon={<LuTrash2 />}
            text='Eliminar'
          />
        </FlexContainer>
      </>
    );
  }, []);

  const syncAreas = () => {
    return axios.get(Apis.BASE_API_URL + `/areas/sync?empresaId=${empresaId}`)
      .then(res => {
          successToast('Sincronización realizada con éxito');
          fetchData()
        }
      ).catch((err) => {
          errorToast(err ? err.response.data.message : 'Ocurrió un problema. Por favor intente nuevamente');
        }
      )
  }

  const columns = useMemo(
    () => [
      {
        header: 'Nombre',
        accessorKey: 'name',
      },
      {
        header: 'Acción',
        accessorKey: 'id',
        cell: actionValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
    ]
    , []
  )

  useEffect(() => {
    fetchData();
  }, [refresh, refresh2]);

  return (
    <>
      <PageSection>
        <Title text='Áreas' type={'page'} />
      </PageSection>

      <PageSection>
        <Table
          columns={columns}
          data={data}
          onAdd={addAction}
          onCustom={
            <Button
              variant='light'
              size='sm'
              text="Sincronizar"
              icon={<MdSync />}
              onClick={syncAreas}
            />
          }
          searchable
          emptyListText={
            <EmptyState
              title='Sin resultados'
              subtitle='Haga click en filtros para realizar una nueva consulta.'
              data={data}
              loading={loading}
              error={error}
            />
          }
        />
      </PageSection>

      <ModalGeneric {...wrapper} />
      <DeleteGenericModal {...wrapper2} />
    </>
  );
};

export default Areas;
