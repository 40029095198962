import React from "react";
import useFetch2 from "../../hooks/useFetch2";
import { Apis } from "../../utils/Apis";
import {Select} from "@wargostec/react-components";

const SelectArt = React.forwardRef((props, ref) => {

  const { data } = useFetch2(Apis.BASE_API_URL + "/reglamento-interno", [])

  const newData = data?.map((item) => {
    const name = 'Art.' + item.articulo + ' - ' + item.descripcion
    return {
      name: name,
      id: item.id,
      value: item.id,
    };
  });
  return (
    <>
      {
        (data !== undefined) ?
          <Select ref={ref} {...props} >
            {newData.map((item) => (
              <option key={item.id} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
          :
          <Select disabled>
            <option value="0">Cargando...</option>
          </Select>
      }
    </>
  );
});

export default SelectArt;
