import React from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import {Button, FlexContainer} from "@wargostec/react-components";
import FileUploaderController from "../../../../components/FileUploaderController";
import {AiOutlinePlus} from 'react-icons/ai';
import {LuTrash2} from 'react-icons/lu';
import DocumentView from '../../../../components/viewpdf/DocumentView';

const ContainerDocument = styled.div`
  margin: 1em;
  display: flex;
  flex-wrap: wrap;
  gap: 3em;
`;

const DocumentConfig = ({ fields, control, addDocument, remove }) => {

  return (
    <FlexContainer margin='16px 0' flexDirection='column' alignItems='flex-start'>
      <Button variant="dark" onClick={addDocument} text={'Agregar nuevo documento'} icon={<AiOutlinePlus />}/>
      <ContainerDocument>
        {fields && fields.map((field, index) => {
          return (
            <div key={field.id}>
              <FlexContainer gap='8px'>
                <Controller
                  name={`employeeDocuments.${index}.doc.url`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FileUploaderController value={value} onChange={onChange} />
                  )}
                />
                <Button variant="danger" size={'xs'} onClick={() => remove(index)} icon={<LuTrash2 />}/>
              </FlexContainer>
              <DocumentView value={field?.doc?.url} title={'Documento'} />
            </div>
          )
        })
        }
      </ContainerDocument>
    </FlexContainer>
  );
}

export default DocumentConfig;
