import React, { useEffect } from "react";
import { Controller, useForm } from 'react-hook-form';
import { Apis } from "../../../../utils/Apis";
import axios from "axios";
import CustomerSearchInput from '../../../../components/selects/CustomerSearchInput';
import { DateTime } from 'luxon';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import DeleteGenericModal from '../../../../components/modals/DeleteGenericModal';
import DocumentView from '../../../../components/viewpdf/DocumentView';
import {Button, FlexContainer, Input} from "@wargostec/react-components";
import FileUploaderController from "../../../../components/FileUploaderController";
import {Col, Row} from "../../../../components/migrationcomponents/flexlayout";
import FormGroup from "../../../../components/migrationcomponents/FormGroup";
import {AiOutlineCloudDownload} from "react-icons/ai";
import {LuX} from "react-icons/lu";


const VacationRequestForm = ({ value, onSubmit, setAction }) => {
  const baseURL = Apis.BASE_API_URL + "/vacaciones-sol";
  const { register, handleSubmit, reset, control, setValue } = useForm();

  const {
    wrapper,
    openDeleteModal,
  } = useDeleteModal({ text: '¿Desea eliminar esta vacación?' });

  const downloadReport = () => {
    window.location.href = baseURL + `/${value.id}/report.pdf`;
  };

  const deleteDate = (id) => axios.delete(baseURL + `/${id}`);

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [value]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, []);

  return (
    <>
      <FormGroup>
        <label>Empleado</label>
        <Controller name="employeeId" control={control} render={({ field }) => <CustomerSearchInput {...field} />} />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <Input label={"Fecha de Solicitud"} type="date" value={DateTime.now().toISODate()} {...register("fechaSol")} disabled />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Input label={"Periodo vacacional"} {...register("periodo")} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {
          value.length > 0 ? <>
            <Col>
              <FormGroup>
                <Input label={"Inicio de vacaciones"} type="date" {...register("ini")} disabled />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Input label={"Fin de vacaciones"} type="date" {...register("fin")} disabled />
              </FormGroup>
            </Col>
          </> : <>
            <Col>
              <FormGroup>
                <Input label={"Inicio de vacaciones"} type="date" {...register("ini")} />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Input label={"Fin de vacaciones"} type="date" {...register("fin")} />
              </FormGroup>
            </Col>
          </>
        }
        <Col>
          <FormGroup>
            <Input label={"Duración"} {...register("duracion")} disabled/>
          </FormGroup>
        </Col>
      </Row>
      {value ? (
        <>
          <FlexContainer gap='8px' margin='0 0 8px 0'>
            <FlexContainer flexDirection="column" gap='4px' alignItems="flex-start" flex={1}>
              <label>Documento para firmar</label>
              <Button variant="light" onClick={downloadReport} text={'Descargar'} icon={<AiOutlineCloudDownload />}/>
            </FlexContainer>
            <FlexContainer flexDirection="column" alignItems="flex-start" flex={1}>
              <label>Documentación Firmada</label>
              <Controller
                name='doc.url'
                control={control}
                render={({field: {value, onChange}}) => (
                  <FileUploaderController h value={value} onChange={onChange} />
                )}
              />
              <DocumentView value={value?.doc?.url} title='Documentación Firmada'/>
            </FlexContainer>
          </FlexContainer>
          <Button onClick={() => openDeleteModal(value.id, deleteDate)} variant="danger" text={'Anular Vacaciones'} icon={<LuX />}/>
        </>
      ) : ''}
      <DeleteGenericModal {...wrapper} />
    </>
  );
};

export default VacationRequestForm;
