import React, { useEffect } from "react";
import { Apis } from '../../../../utils/Apis';
import axios from 'axios';
import PositionForm from "./PositionForm";

const PositionNew = ({ setAction, setTitle }) => {
  const save = (params) => {
    return axios.post(Apis.BASE_API_URL + '/cargos', params);
  }

  useEffect(() => {
    setTitle("Nuevo Cargo");
  }, []);

  return <PositionForm value={{}} onSubmit={save} setAction={setAction} />
};

export default PositionNew;
