import React from 'react';
import {Apis} from "../utils/Apis";
import {successToast} from "./Toast";
import {FileUploader} from "@wargostec/react-components";

const FileUploaderController = ({ value, onChange, folder ='docs' }) => {

  function onUpload(rawImage) {
    let data = new FormData()
    data.append("file", rawImage);
    data.append('folder', folder)
    data.append('provider', 'y')
    data.append('bucket', 'asisdata')

    fetch(Apis.BASE_API_FILE + "/upload/photo", { method: "POST", body: data }).then(response => response.json())
      .then(res => {
        if (folder === 'docs') {
          onChange(res.url)
        } else {
          onChange(res.name)
        }
        successToast("Archivo cargado",500)
      })
      .catch(error => {
        console.log(error);
      })
  }

  return (
    <FileUploader
      domain={'https://res.cloudinary.com/dghqjv5az/image/upload/w_906,h_906,c_limit,q_auto,f_auto/linkedasis/persons/'}
      value={value}
      onUpload={onUpload}
      onDelete={() => onChange('')}
      width='150px'
    />
  );
};

export default FileUploaderController;
