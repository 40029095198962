import React, {useContext} from 'react';
import {Preview} from "../../../../components/migrationcomponents/preview";
import {useNavigate} from 'react-router-dom';
import PageSection from '../../../../components/layout/PageSection';
import axios from 'axios';
import {Apis} from '../../../../utils/Apis';
import HolidaysForm from './HolidaysForm';
import {errorToast, successToast} from '../../../../components/Toast';
import {SiteContext} from '../../../../components/context/SiteContext';
import {Card} from "@wargostec/react-components";

const HolidaysNew = () => {
	const navigate = useNavigate();
	const {empresaId} = useContext(SiteContext);
	const save = (params) => {
		params.companyId = empresaId;
		return axios
			.post(Apis.BASE_API_URL + '/convenio-feriado', params)
			.then(() => {
				successToast('Los cambios se registraron correctamente');
				navigate('/convenio-feriados');
			})
			.catch(() => {
				errorToast('Ocurrio un problema. Por favor intente nuevamente');
			});
	};

	return (
		<>
			<PageSection>
				<div style={{marginBottom: '16px'}}>
					<Preview text='Regresar' onClick={() => navigate(-1)} />
				</div>
			</PageSection>
			<PageSection>
				<Card>
					<HolidaysForm value={{}} onSubmit={save} />
				</Card>
			</PageSection>
		</>
	);
};

export default HolidaysNew;
