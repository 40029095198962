import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { DateTime } from 'luxon';
import * as FaIcons from "react-icons/fa";
import ContentLoading from '../../../../components/flex/ContentLoading';
import {Loading} from "../../../../components/migrationcomponents/loading";
import {Button, Card,FlexContainer} from "@wargostec/react-components";
import {LuRefreshCcw} from 'react-icons/lu';

const MarkingChart = ({ data, params, fetchData, loading }) => {
  const [dataChart, setDataChart] = useState([]);
  useEffect(() => {
    const dataChart = data.map((item) => {
      return {
        'employeeNumber': item.employeeNumber,
        'employeeReal': item.employeeReal,
        'fechaHora': item.fechaHora,
      }
    });
    setDataChart(dataChart);
  }, [data]);

  const chartMarking = {
    options: {
      dataLabels: {
        enabled: false
      },
      chart: {
        type: 'line',
        stacked: false,
        zoom: {
          enabled: false
        },
      },
      colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
      fill: {
        opacity: 1,
      },
      tooltip: {
        intersect: false,
        shared: false,
      },
      xaxis: {
        tickAmount: 7,
        categories: dataChart && dataChart.map(x => {
          return DateTime.fromISO(x.fechaHora).toFormat('HH:mm')
        }),
      },
      yaxis: {
        title: {
          text: 'Empleados en Turno',
        }
      },
      stroke: {
        curve: 'stepline',
        width: 3,
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        }
      }
    },
    series: [
      {
        name: 'Programado',
        data: dataChart && dataChart.map(x => x.employeeNumber),
      },
      {
        name: 'Real',
        data: dataChart && dataChart.map(x => x.employeeReal),
      }
    ],
  };
  return (
    <>
      {
        data && loading ? (
          <ContentLoading>
            <Loading variant={'secondary'}/>
          </ContentLoading>
        ) : (
          <Card padding>
            <FlexContainer flexDirection='row' alignItems='center' gap='16px'>
              <strong style={{fontSize: '24px',flex:1}}>Curva del dia: {DateTime.fromISO(params.fecha).toLocaleString({ weekday: 'long', month: 'long', day: '2-digit', year: 'numeric' })}</strong>
              <Button variant='dark' size='xs' onClick={() => fetchData()} text='Actualizar' icon={<LuRefreshCcw size={11}/>}/>
            </FlexContainer>
            <Chart
              options={chartMarking.options}
              series={chartMarking.series}
              type="line"
              height="300px"
            />
          </Card>
        )
      }
    </>
  )
};

export default MarkingChart;
