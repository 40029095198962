import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import HolidayConfig from './HolidayConfig';
import {Button, Input} from "@wargostec/react-components";
import FormGroup from "../../../../components/migrationcomponents/FormGroup";

const HolidaysForm = ({ value, onSubmit }) => {
  const { register, handleSubmit, reset, control } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "detalle",
  });
  const addHoliday = () => {
    append({ horas: 8, employeeId: '' });
  }
  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [value]);


  useEffect(() => {
    handleSubmit(onSubmit);
  }, []);

  return (
    <>
      <FormGroup>
        <Input
          label={"Fecha"}
          type='date'
          name={'fecha'}
          placeholder='Desde'
          {...register('fecha')}
        />
      </FormGroup>
      <FormGroup>
        <label>Feriado</label>
        <HolidayConfig fields={fields} remove={remove} register={register} addHoliday={addHoliday} control={control} />
      </FormGroup>
      <Button variant='primary' onClick={handleSubmit(onSubmit)} text={'GUARDAR'}/>
    </>
  )
}

export default HolidaysForm;
