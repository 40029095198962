import React from "react";
import {Select} from "@wargostec/react-components";

const SelectSchedule = React.forwardRef((props, ref) => {
  const data = [
    { name: "JORNADA", value: "JORNADA", id: 1 },
    { name: "TARDANZA", value: "TARDANZA", id: 2 },
  ];

  return (
    <>
      <Select ref={ref} options={data} {...props}>
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  );
});

export default SelectSchedule;
