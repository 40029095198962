import React, {useCallback, useMemo, useState} from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title,Table,Button,FlexContainer,Input} from "@wargostec/react-components";
import EmptyState from "../../../../components/migrationcomponents/emptystate";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../utils/Apis";
import {LuRefreshCcw,LuCheck, LuX} from "react-icons/lu";
import axios from 'axios';

const Configuration = () => {

  const {data, loading, error} = useFetch2(Apis.BASE_API_URL + "/alerts", []);
  
  const update = (item) => {
    return axios.put(Apis.BASE_API_URL + `/alerts/${item.id}`, item);
  };

  const checkValue = useCallback(props => {
    const check = props.renderValue()
    return check ? <LuCheck /> : <LuX />
  }, []);

  const InputValue = ({ row }) => {
    const [value, setValue] = useState(row.original.mailList);
  
    const handleChange = (event) => {
      setValue(event.target.value);
    };
  
    return (
      <Input value={value} onChange={handleChange} />
    );
  };

  const actionValue = useCallback((props) => {

    return (
      <FlexContainer alignItems="center" justifyContent="center" gap='8px'>
        <Button
          variant='light'
          size='xs'
          text={'Actualizar'}
          onClick={() => update(props.row.original)}
          icon={<LuRefreshCcw />}
        />
      </FlexContainer>
    );
  }, []);

  const columns = [
    {
      header: "Alerta",
      accessorKey: "name",
    },
    {
      header: "Correos",
      accessorKey: "mailList",
      cell: InputValue
    },
    {
      header: "Habilitado",
      accessorKey: "systemEnabled",
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: checkValue
    },
    {
      header: "Acciones",
      accessorKey: "acciones",
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: actionValue
    },
  ];

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Alertas' type={'page'}/>
        </div>
      </PageSection>
      <PageSection>
        <Table
          columns={columns}
          data={data}
          searchable
          emptyListText={
            <EmptyState
              title='Sin resultados'
              subtitle='Haga click en filtros para realizar una nueva consulta.'
              data={data}
              loading={loading}
              error={error}
            />
          }
        />
      </PageSection>
    </>
  );
};

export default Configuration;
