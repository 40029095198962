import React from 'react';
import {Preview} from "../../../../components/migrationcomponents/preview";
import {useNavigate, useParams} from 'react-router-dom';
import PageSection from '../../../../components/layout/PageSection';
import PeopleForm from './PeopleForm';
import axios from 'axios';
import {Apis} from '../../../../utils/Apis';
import useFetch2 from '../../../../hooks/useFetch2';
import {errorToast, successToast} from '../../../../components/Toast';

const PeopleEdit = () => {
	const {id} = useParams();
	const navigate = useNavigate();
	const {data, loading} = useFetch2(Apis.BASE_API_URL + '/employees/' + id,[]);
	const {data: data2} = useFetch2(
    data?.personId ? Apis.BASE_API_URL + '/person-fps?personId=' + data.personId : null, [],{},
    data?.personId !== undefined && data?.personId !== null
  );
	const update = (params) => {
		return axios
			.put(Apis.BASE_API_URL + `/employees/${id}`, params)
			.then(() => {
				successToast('Los cambios se registraron correctamente');
				navigate('/personas');
			})
			.catch(() => {
				errorToast('Ocurrio un problema. Por favor intente nuevamente');
			});
	};

	return (
		<>
			<PageSection>
			<Preview text='Regresar' onClick={() => navigate(-1)} />
				{data && !loading ? (
					<PeopleForm
						value={data}
						onSubmit={update}
						edit={true}
						data={data2}
					/>
				) : (
					<p style={{textAlign: 'center'}}>Cargando...</p>
				)}
			</PageSection>
		</>
	);
};

export default PeopleEdit;
