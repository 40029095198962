import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Apis} from '../../../../utils/Apis';
import useFetch2 from '../../../../hooks/useFetch2';
import {Preview} from "../../../../components/migrationcomponents/preview";
import {Title} from "@wargostec/react-components";
import {errorToast, successToast} from '../../../../components/Toast';
import PageSection from '../../../../components/layout/PageSection';
import HolidaysForm from './HolidaysForm';
import axios from 'axios';
import {Card} from "@wargostec/react-components";

const HolidaysEdit = () => {
	const {id} = useParams();
	const navigate = useNavigate();
	const {data} = useFetch2(Apis.BASE_API_URL + '/convenio-feriado/' + id,[]);

	const update = (params) => {
		return axios
			.put(Apis.BASE_API_URL + `/convenio-feriado/${id}`, params)
			.then(() => {
				successToast('Los cambios se registraron correctamente');
				navigate('/convenio-feriados');
			})
			.catch(() => {
				errorToast('Ocurrio un problema. Por favor intente nuevamente');
			});
	};
	return (
		<>
			<PageSection>
				<div style={{marginBottom: '16px'}}>
					<Preview text='Regresar' onClick={() => navigate(-1)} />
				</div>
			</PageSection>
			<PageSection>
				<Card>
					<HolidaysForm value={data} onSubmit={update} />
				</Card>
			</PageSection>
		</>
	);
};

export default HolidaysEdit;
