import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Apis} from '../../../../utils/Apis';
import useFetch2 from '../../../../hooks/useFetch2';
import {Preview} from "../../../../components/migrationcomponents/preview";
import {errorToast, successToast} from '../../../../components/Toast';
import PageSection from '../../../../components/layout/PageSection';
import AbsenceForm from './AbsenceForm';
import axios from 'axios';
import {Card} from "@wargostec/react-components";

const AbsenceEdit = () => {
	const {id} = useParams();
	const navigate = useNavigate();
	const {data} = useFetch2(Apis.BASE_API_URL + '/inasistencias/' + id,[]);

	const update = (params) => {
		return axios
			.put(Apis.BASE_API_URL + `/inasistencias/${id}`, params)
			.then((res) => {
				successToast('Los cambios se registraron correctamente');
				navigate('/inasistencia');
			})
			.catch((err) => {
				errorToast('Ocurrio un problema. Por favor intente nuevamente');
			});
	};

	return (
		<>
			<Preview text='Regresar' onClick={() => navigate(-1)} />
			<PageSection>
				<Card padding>
					<AbsenceForm value={data} onSubmit={update} />
				</Card>
			</PageSection>
		</>
	);
};

export default AbsenceEdit;
