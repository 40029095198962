import React from "react"
import Modal from "react-bootstrap/Modal";
import {Button} from "@wargostec/react-components";

const ConfirmModal = ({
                        show,
                        onHide,
                        action,
                        title = 'Confirmación',
                        text = '¿Esta seguro que desea realizar esta acción?',
                      }) => {
  return (

    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={onHide} text={'Cancelar'}/>
        <Button variant="primary" onClick={action} text={'Confirmar'}/>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
