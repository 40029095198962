import React, {useCallback, useMemo} from "react";
import useFetch2 from "../../../../hooks/useFetch2";
import {Apis} from "../../../../utils/Apis";
import PageSection from "../../../../components/layout/PageSection";
import {Table, Title} from "@wargostec/react-components";
import EmptyState from "../../../../components/migrationcomponents/emptystate";
import {FaSquare} from "react-icons/fa";

const TypeOfEvents = () => {
  const {data, loading, error} = useFetch2(Apis.BASE_API_URL + "/evento-tipo", []);

  const colorSquare = useCallback(props =>
    <FaSquare size={26} color={props.renderValue() === null ? "#fff" : props.renderValue()} />
    , []);

  const columns = useMemo(
    () => [
      {
        header: "Nombre", 
        accessorKey: 'name'
      },
      {
        header: "Abreviatura",
        accessorKey: 'abrv',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Fondo',
        accessorKey: 'color',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: colorSquare,

      },
      {
        header: "Texto",
        accessorKey: "colorSec",
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: colorSquare,
      },
    ]
    , []
  )

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Tipos de eventos' type={'page'} />
        </div>
      </PageSection>
      <PageSection>
        <Table
          columns={columns}
          data={data}
          searchable
          emptyListText={
            <EmptyState
              title='Sin resultados'
              subtitle='Haga click en filtros para realizar una nueva consulta.'
              data={data}
              loading={loading}
              error={error}
            />
          }
        />
      </PageSection>
    </>
  );
};

export default TypeOfEvents;
