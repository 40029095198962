import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastSucessStyle = {
  backgroundColor:"#37ba32",
  fontSize: "14px",
  paddingRight: "8px",
  borderRadius: "6px",
}

const toastErrorSucessStyle = {
  backgroundColor:"#dc2626",
  fontSize: "14px",
  paddingRight: "8px"
}

export const successToast = (text,time=2000) => {
  toast.success(text, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: time,
    hideProgressBar:true,
    draggable:true,
    theme:"colored",
    style:toastSucessStyle
  });
};
export const errorToast = (text) => {
  toast.error(text, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2000,
    hideProgressBar:true,
    draggable:true,
    theme:"colored",
    style:toastErrorSucessStyle
  });
};
export const infoToast = (text) => {
  toast.info(text, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2000,
    hideProgressBar:true,
    draggable:true,
    theme:"colored",
  });
};

export const warningToast = (text) => {
  toast.warning(text, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2000,
    hideProgressBar:true,
    draggable:true,
    theme:"colored",
  });
};

