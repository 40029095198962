import React from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";

const HoursExtra = () => {
  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Horas extras' type={'page'}/>
        </div>
      </PageSection>
    </>
  );
};

export default HoursExtra;
