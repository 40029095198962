import React, { useEffect } from "react";
import axios from 'axios';
import ClockForm from "./ClockForm";
import {Apis} from "../../../utils/Apis";

const ClockNew = ({ setAction, setTitle }) => {
  const save = (params) => {
    return axios.post(Apis.BASE_API_URL + '/clocks', params);
  }

  useEffect(() => {
    setTitle("Nuevo Reloj");
  }, []);

  return <ClockForm value={{}} onSubmit={save} setAction={setAction} />
};

export default ClockNew;
