import React, {useEffect} from 'react';
import * as FaIcons from 'react-icons/fa';
import PageSection from '../../../../components/layout/PageSection';
import styled from 'styled-components';
import {Preview} from "../../../../components/migrationcomponents/preview";
import {useNavigate} from 'react-router-dom';
import SelectReason from '../../../../components/selects/SelectReason';
import {Controller, useForm} from 'react-hook-form';
import CustomerSearchInput from '../../../../components/selects/CustomerSearchInput';
import {Button, Card, Title} from "@wargostec/react-components";

const ContainerForm = styled.div`
	width: 50%;
	padding: 1% 5%;
	> * {
		margin-bottom: 15px;
	}
	> textarea {
		border-radius: 10px;
		padding: 10px;
	}
`;
const SurveyForm = ({value, onSubmit}) => {
	const navigate = useNavigate();
	const {register, handleSubmit, reset, control} = useForm({
		defaultValues: {
			motivo: value?.motivo,
			explicacion: value?.explicacion,
			p1: value?.p1,
			p2: value?.p2,
			p3: value?.p3
		}
	});

	useEffect(() => {
		if (value) {
			reset(value);
		}
	}, [value]);

	useEffect(() => {
		handleSubmit(onSubmit);
	}, []);

	return (
		<PageSection>
			<Card>
				<Preview text='Regresar' onClick={() => navigate(-1)} />
				<ContainerForm>
					<Title text='Encuesta' type='form' />
					<label>Empleado</label>
					<Controller
						name='employeeId'
						control={control}
						render={({field}) => <CustomerSearchInput {...field} />}
					/>
					<label>¿Que opcion explica mejor el motivo de su renuncia?</label>
					<SelectReason {...register('motivo')} />
					<label>Explique brevemente el motivo de su renuncia</label>
					<textarea
						rows='7'
						cols='59'
						placeholder='Ingrese motivo y código del reglamento'
						{...register('explicacion')}
					/>
					<label>
						En caso la renuncia sea por otra oferta laboral: Si se te ofreciera
						las mismas condiciones de las otras empresas ¿Mantendrias tu
						renuncia?
					</label>
					<textarea
						rows='7'
						cols='59'
						placeholder='Máximo 400 carácteres'
						{...register('p1')}
					/>
					<label>
						¿Le recomendarías a otras personas que postulen a la empresa en caso
						se dieran las vacantes?
					</label>
					<textarea
						rows='7'
						cols='59'
						placeholder='Máximo 400 carácteres'
						{...register('p2')}
					/>
					<label>
						¿En qué aspectos crees tú que podría mejorar la empresa?
					</label>
					<textarea
						rows='7'
						cols='59'
						placeholder='Máximo 400 carácteres'
						{...register('p3')}
					/>
					<Button variant='primary' onClick={handleSubmit(onSubmit)} text={'Guardar'} icon={<FaIcons.FaCheck />}/>
				</ContainerForm>
			</Card>
		</PageSection>
	);
};

export default SurveyForm;
