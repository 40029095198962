import React from "react";
import styled, {keyframes} from "styled-components"
import {FlexContainer} from "@wargostec/react-components";
import person from "./person-empty.png";
import {FaSpinner} from "react-icons/fa";

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const EmptyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:16px;
  gap:16px;
  user-select: none;
`
const LoadingIcon = styled(FaSpinner)`
  animation: ${spinAnimation} 1s linear infinite;
`

const EmptyState = ({title, subtitle,loading, error,data}) => {

  if (loading) {
    return (
      <EmptyStyled>
        <LoadingIcon size={32} color='#ccc' />
        <span style={{fontSize:'14px',fontWeight:400}}>Cargando...</span>
      </EmptyStyled>
    );
  }

  if (error) {
    return (
      <EmptyStyled>
        <FlexContainer flexDirection="column" alignItems="center">
          <strong style={{fontSize:'18px'}}>Error cargando los datos</strong>
          <span style={{fontSize:'14px',fontWeight:400}}>{error.message}</span>
        </FlexContainer>
      </EmptyStyled>
    );
  }
   if (!data || data.length === 0) {
    return (
      <EmptyStyled>
        <img src={person} alt="person" />
        <FlexContainer flexDirection="column" alignItems="center">
          <strong style={{fontSize:'18px'}}>{title}</strong>
          <span style={{fontSize:'14px',fontWeight:400}}>{subtitle}</span>
        </FlexContainer>
      </EmptyStyled>
    );
  }
}

export default EmptyState