import React from 'react';
import SelectOffice from '../../../../components/selects/SelectOffice';
import {Button,FlexContainer} from "@wargostec/react-components";
import {LuTrash2} from 'react-icons/lu';
import {AiOutlinePlus} from 'react-icons/ai';

const OfficeConfig = ({ fields, register, addOffice, remove, data, loading }) => {

  return (
    <FlexContainer flexDirection='column' gap='16px' alignItems='flex-start'>
      <Button variant="primary" size={'xs'} onClick={addOffice} text={'Agregar'} icon={<AiOutlinePlus />} />

      {fields && fields.map((field, index) => {
        return (
          <FlexContainer key={field.id} gap='8px' alignItems='center'>
            <SelectOffice {...register(`sucursales[${index}].sucursalId`)} data={data} loading={loading}  />
            <Button variant="danger" size={'xs'} onClick={() => remove(index)} icon={<LuTrash2/>} />
          </FlexContainer>
        )
      })
      }
    </FlexContainer>
  );
}

export default OfficeConfig;
