import {useState} from "react";
import {errorToast, successToast} from '../components/Toast';

const useConfirmModal = ({text}) => {

  const [show, setShow] = useState(false);
  //saved for later use
  const [id, setId] = useState(null);
  const [action, setAction] = useState(null);

  const openConfirmModal = (action, id) => {
    //save function and id for later use, if it were necessary
    setId(id)
    setAction(() => action)
    //open modal
    setShow(true)
  }

  const onCloseConfirmModal = () => {
    setShow(false)
  }

  const executeAction = () => {
    action(id).then(res => {
      setShow(false)
      successToast("Operación realizada!")
    }).catch(err => {
      errorToast("Ocurrio un problema. Por favor intente nuevamente")
    })
  }

  const wrapper = {show: show, onHide: onCloseConfirmModal, 'action': executeAction, text: text}

  return {wrapper, id, openConfirmModal}
}

export default useConfirmModal
