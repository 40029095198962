import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import FormGroup from "../../../../components/migrationcomponents/FormGroup";
import {Input} from "@wargostec/react-components";

const ScheduleForm = ({ value, onSubmit, setAction }) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: value.name,
      description: value.description,
      entrada: value.entrada,
      salida: value.salida,
      minutosDescanso: value.minutosDescanso,
      color: value.color,
    },
  });

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [value]);

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, []);

  return (
    <>
      <FormGroup>
        <Input label={"Abreviatura"} name="ini" type={"text"} {...register("name")} />
      </FormGroup>
      <FormGroup>
        <Input label={"Descripción"} name="fin" type={"text"} {...register("description")} />
      </FormGroup>
      <FormGroup>
        <Input label={"Entrada"} name="fin" type={"time"} {...register("entrada")} />
      </FormGroup>
      <FormGroup>
        <Input label={"Salida"} name="fin" type={"time"} {...register("salida")} />
      </FormGroup>
      <FormGroup>
        <Input label={"Minutos descanso"} name="fin" type={"text"} {...register("minutosDescanso")} />
      </FormGroup>
      <FormGroup>
        <Input label={"Color"} name="fin" type={"color"} {...register("color")} />
      </FormGroup>
    </>
  );
};

export default ScheduleForm;
