import React, {useState, useEffect, useContext, useCallback} from 'react';
import {Title,FlexContainer} from "@wargostec/react-components";
import EmptyState from "../../../../components/migrationcomponents/emptystate";
import * as FaIcons from 'react-icons/fa';
import PageSection from '../../../../components/layout/PageSection';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../utils/Apis';
import {DateTime} from 'luxon';
import SelectType from './SelectType';
import {Controller, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import CustomerSearchInput from '../../../../components/selects/CustomerSearchInput';
import axios from 'axios';
import useDeleteModal from '../../../../hooks/useDeleteModal';
import DeleteGenericModal from '../../../../components/modals/DeleteGenericModal';
import {SiteContext} from '../../../../components/context/SiteContext';
import DocumentView from '../../../../components/viewpdf/DocumentView';
import {Button, Slidebar, Table, Input} from "@wargostec/react-components";
import {TbFileExport} from 'react-icons/tb';
import {warningToast} from '../../../../components/Toast';
import {LuFileEdit, LuTrash2} from 'react-icons/lu';

const Absence = () => {
	const [close, setClose] = useState(false);
	const {empresaId} = useContext(SiteContext);
	let defaultParams = {empresaId};
	const navigate = useNavigate();
	const [params, setParams] = useState(defaultParams);
	const {register, handleSubmit, setValue, control} = useForm({
		defaultValues: defaultParams
	});
	const {loading, data, fetchData, error} = useFetch2(
		Apis.BASE_API_URL + '/inasistencias',
		[],
		params,
		{},
		false
	);
	const {
		wrapper: wrapper2,
		refresh: refresh2,
		openDeleteModal
	} = useDeleteModal({text: '¿Desea eliminar esta inasistencia?'});
	const deleteElement = (areaId) =>
		axios.delete(Apis.BASE_API_URL + `/inasistencias/${areaId}`);
	const getData = (params) => {
		setParams(params);
		setClose(!close);
	};

	const resetForm = () => {
		setValue('from', '');
		setValue('to', '');
		setValue('type', '');
		setValue('employeeId', '');
	};

	useEffect(() => {
		fetchData();
	}, [params, refresh2]);

	const goAbsenceNew = () => {
		navigate('/inasistencia-nuevo');
	};

	const goAbsenceEdit = (id) => {
		navigate('/inasistencia-edit/' + id);
	};


	const exportReport = () => {
    const queryParams = new URLSearchParams(params).toString();

    if (params.from && params.to) {
      const link = Apis.BASE_API_URL + `/inasistencias/report.xlsx?${queryParams}`;
      window.location.href = link;
    } else {
      warningToast('Para descargar el consolidad debe indicar el rango de fechas');
    }
  }

	const formatDate = useCallback(props => <>
		{
			<div>
				{props.renderValue() ?
					<span>{DateTime.fromISO(props.renderValue()).toFormat("dd'/'MM'/'yyyy")}</span> :
					<span>---</span>}
			</div>
		}
	</>
		, []);

	const actionValue = useCallback((props) => {
		const id = props.row.original.id;

		return (
			<FlexContainer alignItems="center" justifyContent="center" gap='8px'>
        <Button
          variant='light'
          size='xs'
          onClick={() => goAbsenceEdit(id)}
          icon={<LuFileEdit />}
        />
        <Button
          variant='danger'
          size='xs'
          onClick={() => openDeleteModal(id, deleteElement)}
          icon={<LuTrash2 />}
        />
      </FlexContainer>
		);
	}, []);

	const employeeValue = ({row}) => (
		<div>
			{row.original.employee?.person?.lastNameFirstName}
		</div>
	);

	const documentValue = ({row}) => (
		<div>
			{row.original.employee?.person?.document}
		</div>
	);

	const renderDocCell = ({row}) => {
		const doc = row.original.doc;
		const viewPDF = () => {

			// window.open(
			//   `${'https://asisdata.s3.amazonaws.com/'}${row.original.doc?.url}`,
			//   '_blank'
			// );

			window.open(
				`${'https://wgt-tmp-uploads.s3.amazonaws.com/'}${row.original.doc?.url}`,
				'_blank'
			);
		};

		return (
			<>
				{row.original.doc && row.original.doc?.url !=='' ? (
					<DocumentView 
						value={row.original?.doc?.url} 
						title='Sustento' 
						variant='light' 
						size='xs' 
						icon={<FaIcons.FaRegFilePdf />} 
					/>
				) : (
					<>---</>
				)}
			</>
		);
	};

	const columns = [
		{
			header: "Id",
      accessorKey: "id",
      enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
		},
		{
			header: 'Empleado',
			accessorKey: 'employee',
			cell: employeeValue,
		},
		{
			header: 'Documento',
			accessorKey: 'document',
			meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
			cell: documentValue,
		},
		{
			header: 'Tipo',
			accessorKey: 'type',
			enableGlobalFilter: false,
      enableSorting: false
		},
		{
			header: 'Comentario',
			accessorKey: 'description'
		},
		{
			header: 'Sustento',
			accessor: 'doc',
			cell: renderDocCell,
			enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
		},
		{
			header: 'Inicio',
			accessorKey: 'ini',
			cell: formatDate,
			enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
		},
		{
			header: 'Fin',
			accessorKey: 'fin',
			cell: formatDate,
			enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
		},
		{
			header: 'Acción',
			accessorKey: '',
			cell: actionValue,
			enableGlobalFilter: false,
      enableSorting: false,
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
		}
	];
	return (
		<>
			<PageSection>
				<div style={{marginBottom: '1rem'}}>
					<Title text='Inasistencia' type={'page'} />
				</div>
				<Button variant='light' text={'Descargar consolidado'} onClick={exportReport} icon={<TbFileExport />} />
			</PageSection>
			<PageSection>
				<Table
					columns={columns}
					data={data}
					searchable
					onFilter={() => setClose(!close)}
					onAdd={goAbsenceNew}
					pageSize={20}
					emptyListText={
						<EmptyState
							title='Sin resultados'
							subtitle='Haga click en filtros para realizar una nueva consulta.'
							data={data}
							loading={loading}
							error={error}
						/>
					}
				/>

			</PageSection>
			<Slidebar
				title='Filtrar'
				toggler={() => setClose(!close)}
				isOpen={close}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.5em',
						marginTop: '1em'
					}}
				>
					<Input
						label={"Fecha de inasistencia"}
						type='date'
						name={'fecha'}
						placeholder='Desde'
						{...register('from')}
					/>
					<Input
						type='date'
						name={'fecha'}
						placeholder='Hasta'
						{...register('to')}
					/>
					<label>Empleado</label>
					<Controller
						name='employeeId'
						control={control}
						render={({field}) => <CustomerSearchInput {...field} />}
					/>
					<label>Tipo</label>
					<SelectType {...register('type')} />
					<Button variant='primary' onClick={handleSubmit(getData)} text={'BUSCAR'} icon={<FaIcons.FaSearch />} />
					<Button variant='light' onClick={resetForm} text={'LIMPIAR'} icon={<FaIcons.FaEraser />} />
				</div>
			</Slidebar>
			<DeleteGenericModal {...wrapper2} />
		</>
	);
};

export default Absence;
