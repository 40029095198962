import { useEffect, useState } from "react";

const groupBy = (data, key) => {
  return data && data.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const useGroupBy = (data, key) => {
  const [dataGroup, setDataGroup] = useState(data);

  useEffect(() => {
    setDataGroup(groupBy(data, key));
  }, [
    data,
    key,
  ]);

  return {dataGroup}
  // const groupedData = groupBy(data, key);
  // return Object.keys(groupedData).map(function (key) {
  //   return {
  //     key: key,
  //     data: groupedData[key],
  //   };
  // });
}

// const useGroupBy = (data, key) => {
//   const [dataGroup, setDataGroup] = useState(data);
//   const groupedData = groupBy(data, key);
//   const dataNew = Object.keys(groupedData).map( (key) => {
//     return {
//       key: key,
//       data: groupedData[key],
//     };
//   });

//   return {dataGroup};
// };

export default useGroupBy;