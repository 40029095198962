import React, {useEffect} from 'react';
import SelectFinger from '../../../../components/selects/SelectFinger';
import {useForm} from 'react-hook-form';
import {Alert} from "../../../../components/migrationcomponents/alert";
import FormGroup from "../../../../components/migrationcomponents/FormGroup";
import SelectClock from "../../../../components/selects/SelectClock";

const FingerPrintForm = ({ value, onSubmit, setAction }) => {
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit));
  }, []);

  return (
    <>
      <Alert variant='info' text=' Se enviara un comando al reloj en un periodo maximo de 30 segundos. Siga las instrucciones en el reloj. Una vez finalizado, la huella se guardara en el servidor.' />
      <FormGroup>
        <label>Reloj</label>
        <SelectClock {...register('clockId')} />
      </FormGroup>
      <FormGroup>
        <label>Seleccione el dedo que registrara</label>
        <SelectFinger {...register('fingerId')}/>
      </FormGroup>
    </>
  )

};

export default FingerPrintForm;
