import React, { useEffect } from "react";
import { Apis } from "../../../../utils/Apis";
import axios from "axios";
import useFetch2 from "../../../../hooks/useFetch2";
import FormEditPeriods from "./FormEditPeriods";


const EditPeriods = ({ id, setAction, setTitle }) => {
  const baseURL = Apis.BASE_API_URL + "/periodos";
  const { data, loading } = useFetch2(baseURL + `/${id}`);

  const update = async (params) => {
    params.id = data.id;
    params.tenant = data.tenant;
    await axios.put(baseURL + `/${id}`, params);
  };

  useEffect(() => {
    setTitle("Periodo");
  }, []);

  return (
    <div>
      {data && !loading ? 
        <FormEditPeriods value={data} onSubmit={update} setAction={setAction} />
      : <p style={{textAlign: 'center'}}>Cargando...</p>}
    </div>
  );
};

export default EditPeriods;
