import React, { useEffect } from 'react';
import { Apis } from '../../../../utils/Apis';
import axios from 'axios';
import AssignmentForm from './AssignmentForm';

const AssignmentEdit = ({ id, setAction, setTitle }) => {
  const update = (params) => {
    params.employeeId = id.employeeId;
    params.id = params.turnoDiaId;
    delete params.turnoDiaId;
    return axios.put(Apis.BASE_API_URL + `/turno-dia/${id.turnoDiaId}`, params);
  };

  useEffect(() => {
    setTitle(id.name);
  }, []);

  return <AssignmentForm value={id.data} setAction={setAction} onSubmit={update} edit={true} />;
};

export default AssignmentEdit;