import React, { useContext } from 'react'
import PageSection from '../../components/layout/PageSection'
import useFetch2 from '../../hooks/useFetch2'
import { Apis } from '../../utils/Apis'
import { DateTime } from 'luxon';
import styled from 'styled-components'
import { SiteContext } from "../../components/context/SiteContext";
import {Loading} from "../../components/migrationcomponents/loading";
import ContentLoading from '../../components/flex/ContentLoading'
import {Card, ViewerImage,FlexContainer,Title} from "@wargostec/react-components";
import GridContainer from '../../components/flex/GridContainer';
import {LuCake} from 'react-icons/lu';

const Banner = styled.div`
  width: 100%;
  height: 120px;
  font-size: 2rem;
  background: linear-gradient(45deg, #0674db, #069cf9);
  color: white;
  padding: 1rem;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  &:hover {
    transition: all 0.3s ease;
    background-image: linear-gradient(-45deg, #0674db, #069cf9);
  }
`

const Human = () => {
  const {empresaId} = useContext(SiteContext);
  const {data, loading} = useFetch2(Apis.BASE_API_URL + `/employees/birthdays?empresaId=${empresaId}`,[]);

  const notImage = 'https://res.cloudinary.com/dghqjv5az/image/upload/v1629393973/mix/profile03_k7zkt9.png'

  const getPhotoUrl = (photo) => {
    let url = "https://res.cloudinary.com/dghqjv5az/image/upload/w_906,h_906,c_limit,q_auto,f_auto/linkedasis/persons/"

    return url + photo
  }

  return (
    <PageSection>
      <Banner>
        Recursos Humanos
      </Banner>
      {loading ? 
        <ContentLoading>
          <Loading variant='secondary' />
        </ContentLoading>
        :
        <>
          <Title text="Proximos cumpleaños de colaboradores" type={'page'}/>
          <GridContainer gridTemplateColumns='1fr 1fr 1fr 1fr 1fr 1fr' style={{marginTop:'16px'}}>
            {
              data && data.map((item, index) => {
                return (
                  <Card key={index}>
                    <FlexContainer flexDirection='column' alignItems='center' justifyContent='center' gap='16px'>
                      <ViewerImage src={item.photo ? getPhotoUrl(item?.photo) : notImage} zoom={true}
                                  height={60} width={'auto'} border={'circle'}/>
                      <strong style={{textAlign:'center',fontSize:'14px'}}>{item.firstName} {item.lastName1}</strong>
                      <FlexContainer gap='8px' alignItems='center' justifyContent='center'>
                        <LuCake size={20}/>
                        <span>{DateTime.fromISO(item.cumple).toFormat('dd/MM')}</span>
                      </FlexContainer>
                    </FlexContainer>

                  </Card>
                )
              })
            }
          </GridContainer>
        </>
        
      }
    </PageSection>
  )
}

export default Human
