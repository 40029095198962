import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import SurveyForm from './SurveyForm';
import useFetch2 from '../../../../hooks/useFetch2';
import {Apis} from '../../../../utils/Apis';
import axios from 'axios';
import {errorToast, successToast} from '../../../../components/Toast';

const SurveyEdit = () => {
	const {id} = useParams();
	const navigate = useNavigate();
	const baseURL = Apis.BASE_API_URL + '/employee-exit-survey';
	const {data} = useFetch2(baseURL + `/${id}`,[]);

	const update = (params) => {
		return axios
			.put(baseURL + `/${id}`, params)
			.then((res) => {
				successToast('Los cambios se registraron correctamente');
				navigate('/encuesta-salida');
			})
			.catch((err) => {
				errorToast('Ocurrio un problema. Por favor intente nuevamente');
			});
	};
	return <SurveyForm value={data} onSubmit={update} />;
};

export default SurveyEdit;
