import React from "react";
import {Select} from "@wargostec/react-components";

const SelectFinger = React.forwardRef((props, ref) => {
  const data = [
    { name: "MEÑIQUE IZQUIERDO", value: '0', id: 0 },
    { name: "ANULAR IZQUIERDO", value: '1', id: 1 },
    { name: "MEDIO IZQUIERDO", value: '2', id: 2 },
    { name: "INDICE IZQUIERDO", value: '3', id: 3 },
    { name: "PULGAR IZQUIERDO", value: '4', id: 4 },
    { name: "PULGAR DERECHO", value: '5', id: 5 },
    { name: "INDICE DERECHO", value: '6', id: 6 },
    { name: "MEDIO DERECHO", value: '7', id: 7 },
    { name: "ANULAR DERECHO", value: '8', id: 8 },
    { name: "MEÑIQUE DERECHO", value: '9', id: 9 },
  ];

  return (
    <>
      <Select ref={ref} {...props} >
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  );
});

export default SelectFinger;
