import React from "react";
import useFetch2 from "../../hooks/useFetch2";
import {Apis} from "../../utils/Apis";
import {Select} from "@wargostec/react-components";

const SelectNacionality = React.forwardRef((props, ref) => {
  const {data, loading} = useFetch2(Apis.BASE_API_URL + "/combos/countries", [])
  const newData = data && data.map((item) => {
    return {
      name: item.name,
      id: item.id,
      value: item.id,
    };
  });
  return (
    <>
      {
        data && !loading ?
          <Select ref={ref} {...props}>
            {newData.map((item) => (
              <option key={item.id} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
          :
          <Select disabled>
            <option value="0">Cargando...</option>
          </Select>
      }
    </>
  );
});

export default SelectNacionality;
