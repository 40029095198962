import React from "react";
import PageSection from "../../../../components/layout/PageSection";
import {Title} from "@wargostec/react-components";


const Atrasos = () => {
  return (
  <PageSection>
    <div style={{marginBottom: '1rem'}}>
      <Title text='Atrasos' type={'page'}/>
    </div>
  </PageSection>
  )
};

export default Atrasos;
