import React from "react";
import {Select} from "@wargostec/react-components";

const SelectRecords = React.forwardRef((props, ref) => {
  const data = [
    { name: "ENTRADA", value: "ENTRADA", id: 1 },
    { name: "SALIDA", value: "SALIDA", id: 2 },
    { name: "ENTRADA DESCANSO", value: "ENTRADA_DESCANSO", id: 3 },
    { name: "SALIDA DESCANSO", value: "SALIDA_DESCANSO", id: 4 },
  ];

  return (
    <>
      <Select ref={ref} {...props}>
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  );
});

export default SelectRecords;
