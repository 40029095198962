import React from 'react';
import SurveyForm from '../survey/SurveyForm';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {Apis} from '../../../../utils/Apis';
import {errorToast, successToast} from '../../../../components/Toast';

const NewSurveyNew = () => {
	const navigate = useNavigate();
	const save = (params) => {
		return axios
			.post(Apis.BASE_API_URL + '/employee-exit-survey', params)
			.then((res) => {
				successToast('Los cambios se registraron correctamente');
				navigate('/nueva-encuesta-salida');
			})
			.catch((err) => {
				errorToast('Ocurrio un problema. Por favor intente nuevamente');
			});
	};

	return <SurveyForm value={{}} onSubmit={save} />;
};

export default NewSurveyNew;
