import styled from "styled-components";

const Row = styled.div`
  display: flex;
  margin: 0 -10px;
`

const Col = styled.div`
  flex-basis:  100%;
  flex : 1;
  margin: 0 10px;
`

export { Col, Row }
