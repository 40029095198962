import React from 'react';
import * as FaIcons from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';
import {Button, Title} from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";

const NewSurvey = () => {
	const navigate = useNavigate();

	const goNewSurveyNew = () => {
		navigate('/nueva-encuesta-salida-nuevo');
	};

	return (
		<>
			<PageSection>
				<div style={{marginBottom: '1rem'}}>
					<Title text='Nueva encuesta de salida' type={'page'}/>
				</div>
				<Button variant='primary' onClick={goNewSurveyNew} text={'Nuevo'} icon={<FaIcons.FaPlus size='15px'/>}/>
			</PageSection>
		</>
	);
};

export default NewSurvey;
