import React from "react";
import {Select} from "@wargostec/react-components";

const SelectReason = React.forwardRef((props, ref) => {
  const data = [
    { name: "FIN DE CONTRATO", value: "FIN_DE_CONTRATO", id:1 },
    { name: "CAMBIO DE RESIDENCIA", value: "CAMBIO_DE_RESIDENCIA", id:2 },
    { name: "RENUNCIA VOLUNTARIA", value: "RENUNCIA_VOLUNTARIA", id:3 },
    { name: "OTRA OPCION LABORAL", value: "OTRA_OPCION_LABORAL", id:4 },
    { name: "ESTUDIOS", value: "ESTUDIOS", id:5 },
    { name: "MOTIVOS PERSONALES", value: "MOTIVOS_PERSONALES", id:6 },
    { name: "SALARIO", value: "SALARIO", id:7 },
    { name: "RELACION CON TU JEFE", value: "RELACION_CON_TU_JEFE", id:8 },
    { name: "OTROS", value: "OTROS", id:9 },

  ];

  return (
    <>
      <Select ref={ref} {...props}>
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>
    </>
  );
});

export default SelectReason;
