import React, { useEffect, useState, useContext } from "react";
import moment from "moment/moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import useFetch2 from "../../../../hooks/useFetch2";
import { Apis } from "../../../../utils/Apis";
import { getDateFilter } from "../../../../components/CasinoDates";
import PageSection from "../../../../components/layout/PageSection";
import useEditModal from "../../../../hooks/useEditModal";
import VacationRequestEdit from "./VacationRequestEdit";
import ModalGeneric from "../../../../components/Modal";
import VacationRequestsNew from "./VacationRequestsNew";
import { DateTime } from 'luxon';
import { SiteContext } from '../../../../components/context/SiteContext';
import {Button, Card, Title} from "@wargostec/react-components";
import {AiOutlinePlus} from "react-icons/ai";

const VacationRequests = () => {
  const baseURL = Apis.BASE_API_URL + "/vacaciones-sol";
  const { empresaId } = useContext(SiteContext);
  const localize = momentLocalizer(moment);
  const date = getDateFilter("month");

  const querystring = window.location.search;
  const query = new URLSearchParams(querystring);
  let iniQueryParams = query.get("from");
  let finQueryParams = query.get("to");

  if (iniQueryParams === null) {
    iniQueryParams = date.ini;
  }

  if (finQueryParams === null) {
    finQueryParams = date.fin;
  }

  const defaultParams = {
    from: iniQueryParams,
    to: finQueryParams,
    empresaId: empresaId,
  };
  const [params, setParams] = useState(defaultParams);
  const fechasDefault = { ini: iniQueryParams, fin: finQueryParams };
  const [fechas, setFechas] = useState(fechasDefault);
  const { data, fetchData, error, loading } = useFetch2(baseURL, [], params);

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const generateData = (values) => {
    const calendar = [];
    values.map((item) => {
      calendar.push({
        start: DateTime.fromISO(item.ini).toJSDate(),
        end: addDays(DateTime.fromISO(item.fin).toJSDate(), 1),
        title: item.employee?.person.fullname,
        data: item,
      });
    });

    return calendar;
  };
  const { wrapper, refresh, editAction, addAction } = useEditModal({
    editComponent: <VacationRequestEdit />,
    addComponent: <VacationRequestsNew />,
  });

  const openModal = (event) => {
    editAction(event.data.id);
  };
  useEffect(() => {
    if (params) fetchData();
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [refresh]);

  // useEffect(() => {
  //   const newDate = {
  //     from: fechas.ini,
  //     to: fechas.fin,
  //   };
  //   setParams({ ...params, ...newDate });
  // }, [fechas]);

  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Solicitud de vacaciones' type={'page'}/>
        </div>
        <Button variant="primary" onClick={addAction} text={"Nuevo"} icon={<AiOutlinePlus />}/>
      </PageSection>
      <PageSection>
        <Card>
          <Calendar
            localizer={localize}
            selectable
            events={generateData(data)}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 1000, zIndex: "1" }}
            showAllEvents 
            onSelectEvent={
              data.onSelectEvent
                ? (event) => data.onSelectEvent(event)
                : (event) => openModal(event)
            }
            views={["month", "week"]}
            defaultView="month"
            messages={{
              next: ">",
              previous: "<",
              today: "Hoy",
              month: "Mes",
              week: "Semana",
              day: "Día",
            }}
            onNavigate={(date) => {
              const newDate = {
                from: moment(date).startOf("month").format("YYYY-MM-DD"),
                to: moment(date).endOf("month").format("YYYY-MM-DD"),
              };
              setParams({ ...params, ...newDate });
            }}
            eventPropGetter={(event, start, end, isSelected) => ({
              event,
              start,
              end,
              isSelected,
              style: { zIndex: "1", backgroundColor: "#007bff", fontSize: "10px" },
            })}
          />
        </Card>
      </PageSection>
      <ModalGeneric {...wrapper} />
    </>
  );
};

export default VacationRequests;
