import React, { useContext } from "react";
import useFetch2 from "../../hooks/useFetch2";
import { Apis } from "../../utils/Apis";
import { SiteContext } from "../context/SiteContext";
import {Select} from "@wargostec/react-components";

const SelectTurns = React.forwardRef((props, ref) => {
  const { sucursalId } = useContext(SiteContext);
  const baseURL = Apis.BASE_API_URL + "/turnos";
  const params = { sucursalId: sucursalId };
  const { data, loading } = useFetch2(baseURL, [], params);
  const newData = data && data.map((item) => {
    const nameItem = item.name + "(" + item.description + ")"
    return {
      name: nameItem,
      value: item.id,
    };
  });
  newData.unshift({ name: "DESCANSO SEMANAL", id: 0, value: 0 });

  return (
    <>
      {
        data && !loading ?
          <Select ref={ref}  {...props} >
            {newData.map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
          :
          <Select disabled>
            <option value="0">Cargando...</option>
          </Select>
      }
    </>
  );
});

export default SelectTurns;
