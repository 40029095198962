import React, { useEffect } from 'react';
import axios from 'axios';
import ClockForm from './ClockForm';
import {Apis} from "../../../utils/Apis";
import useFetch2 from "../../../hooks/useFetch2";

const ClockEdit = ({ id, setAction, setTitle }) => {

  const {data} = useFetch2(Apis.BASE_API_URL + `/clocks/${id}`, [], {});

  const update = (params) => {
    console.log(params);
    return axios.put(Apis.BASE_API_URL + `/clocks/${id}`, params);
  };

  useEffect(() => {
    setTitle('Editar Reloj');
  }, []);

  return <ClockForm value={data} onSubmit={update} setAction={setAction} />
};

export default ClockEdit;
