import React, {useState, useEffect} from 'react';
import {DateTime} from 'luxon';
import * as FaIcons from 'react-icons/fa';
import useGroupBy from '../../../../hooks/useGroupBy';
import RecordsEdit from './RecordsEdit';
import useEditModal from '../../../../hooks/useEditModal';
import ModalGeneric from '../../../../components/Modal';
import axios from 'axios';
import {Apis} from '../../../../utils/Apis';
import {successToast} from '../../../../components/Toast';
import {Button, Card, FlexContainer} from "@wargostec/react-components";
import {HiMiniUserCircle} from "react-icons/hi2";
import Table from 'react-bootstrap/Table';
import {LuCheck, LuFileEdit, LuX} from 'react-icons/lu';
import EmptyState from '../../../../components/migrationcomponents/emptystate';

const RecordsTable = ({data, loading, fetchData, refreshData, error}) => {
  const [enabled, setEnabled] = useState(true);
  const {dataGroup} = useGroupBy(data, 'fecha');
  const {wrapper, refresh, editAction} = useEditModal({
    editComponent: <RecordsEdit />,
  });

  const changeEnabled = (id, valid) => {
    valid ?
      axios.post(Apis.BASE_API_URL + `/marcaciones/${id}/clear`).then(() => {
        setEnabled(!enabled);
        successToast("Registro Limpiado")
        refreshData();
      })
      :
      axios.post(Apis.BASE_API_URL + `/marcaciones/${id}/enable`).then(() => {
        setEnabled(!enabled)
        successToast("Registro Habilitado")
        refreshData();
      })
  }

  const style = (x) => {
    if (x.deleted > 0) {
      return {backgroundColor: '#d6d8db', color: '#6c757d'};
    } else if (x.valid === false) {
      return {backgroundColor: '#f8d7da'};
    } else {
      return {}; // Si no hay estilos especiales, devolver un objeto vacío
    }
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  useEffect(() => {
    fetchData();
  }, [enabled]);

  return (
    <div>
      <Card noPadding>
        <Table responsive hover size='sm'>
          <thead>
            <tr>
              <th>Empleado</th>
              <th className='text-center'>Hora</th>
              <th className='text-center'>Tipo</th>
              <th className='text-center'>Fuente</th>
              <th className='text-center'>Sucursal</th>
              <th className='text-center'>Válido</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          {Object.keys(dataGroup).length === 0 ? (
            <tbody>
              <tr>
                <td colSpan="7">
                  <EmptyState
                    title="Sin resultados"
                    subtitle="No se encontraron datos para mostrar."
                    data={data}
                    loading={loading}
                    error={error}
                  />
                </td>
              </tr>
            </tbody>
          ) : (
            Object.keys(dataGroup).map((key, index) => (
              <tbody key={index}>
                <tr>
                  <th colSpan='8' className='head'>
                    {DateTime.fromISO(key).toFormat('dd/MM/yyyy')}
                  </th>
                </tr>
                {dataGroup[key].map((x, index) => (
                  <tr key={index} style={style(x)}>
                    <td style={{backgroundColor: 'transparent'}}>
                      <HiMiniUserCircle style={{marginRight: '4px'}} size={18} />
                      {x.employeeName}
                    </td>
                    <td style={{backgroundColor: 'transparent'}} className='text-center'>
                      {DateTime.fromISO(x.fechaHora).toFormat(
                        'dd/MM HH:mm'
                      )}
                    </td>
                    <td style={{backgroundColor: 'transparent'}} className='text-center'>{x.tipo}</td>
                    <td style={{backgroundColor: 'transparent'}} className='text-center'>{x.source}</td>
                    <td style={{backgroundColor: 'transparent'}} className='text-center'>{x.sucursalName}</td>
                    <td style={{backgroundColor: 'transparent'}} className='text-center'>
                      {x.valid ? (
                        <FaIcons.FaCheckCircle color='#15803d' size={16} style={{marginRight: '5px'}} />
                      ) : (
                        <FaIcons.FaExclamationCircle color='#f59e0b' size={16} style={{marginRight: '5px'}} />
                      )}
                      {x.autoCorrect ? (
                        <FaIcons.FaRobot size={16} />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td style={{backgroundColor: 'transparent'}}>
                      <FlexContainer flexDirection="row" gap="8px" justifyContent="center" alignItems="center">
                        <Button
                          size={'xs'}
                          variant='light'
                          onClick={() => editAction(x)}
                          icon={<LuFileEdit />}
                          text='Editar'
                        />
                        <Button
                          size={'xs'}
                          variant='light'
                          onClick={() => changeEnabled(x.id, x.valid)}
                          icon={x.deleted > 0 ? <LuCheck /> : <LuX />}

                        />
                      </FlexContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            )))}
        </Table>
      </Card>
      <ModalGeneric {...wrapper} />
    </div>
  );
}

export default RecordsTable;
