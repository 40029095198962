import axios from 'axios';
import React, { useEffect, useContext } from 'react'
import useFetch2 from '../../../../hooks/useFetch2';
import { Apis } from '../../../../utils/Apis';
import ScheduleForm from './ScheduleForm';
import { SiteContext } from '../../../../components/context/SiteContext';

const ScheduleEdit = ({ id, setAction, setTitle }) => {
  const baseURL = Apis.BASE_API_URL + "/horarios";
  const { sucursalId } = useContext(SiteContext);
  const { loading, data } = useFetch2(
    baseURL + `/${id}?sucursalId=${sucursalId}`
  );

  const update = async (params) => {
    await axios.put(baseURL + `/${id}`, params);
  };

  useEffect(() => {
    setTitle("Horario");
  }, []);

  return (
    <div>
      {data && !loading ?
        <ScheduleForm value={data} onSubmit={update} setAction={setAction} />
        : <p style={{ textAlign: 'center' }}>Cargando...</p>}
    </div>
  )
}

export default ScheduleEdit