import React from 'react';
import styled from 'styled-components';
import {LuArrowLeft} from 'react-icons/lu';

const PreviewStyled = styled.div`
  cursor: pointer;
  font-weight: normal;
  color: #0a0a0a;
  margin:16px 0;
  display: flex;
  gap:4px;
  align-items: center;
`;

export function Preview({ text, onClick }) {
  return (
    <PreviewStyled onClick={onClick}>
      <LuArrowLeft size={16} />
      <span>{text}</span>
    </PreviewStyled>
  );
}
