import React from "react";
import {Button, Title} from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";

const Permissions = () => {
  return (
    <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text='Permisos de salida y atrasos' type={'page'}/>
        </div>
      </PageSection>
    </>
  );
};

export default Permissions;
