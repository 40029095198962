import React from "react";
import {Card} from "@wargostec/react-components";
import {Loading} from "./loading";
import styled from "styled-components";

const ContentLoading = styled.div`
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoDataTable = ({ text }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", fontSize: "17px", paddingTop: "26px", paddingBottom: "26px" }}>
      <h2>{text}</h2>
    </div>
  )
}

const ErrorCard = ({ axiosError }) => {
  return (
    <Card>
      <h4>Hubo un error</h4>
      <h6>Codigo {axiosError.response?.status}</h6>
    </Card>
  )
}


const TableLoading = ({ children, loading, data, error, noDataText, formBlockStyle, variant }) => {

  const firstvalue = error ?
    <ErrorCard axiosError={error} />
    :
    <div style={formBlockStyle}>
      {
        data && data.length > 0
          ? children
          :
          <NoDataTable text={noDataText} />
      }
    </div>

  return (
    !loading
      ?
      firstvalue
      :
      <ContentLoading>
        <Loading variant={variant ? variant : 'secondary'} />
      </ContentLoading>
  )
}

export { NoDataTable, TableLoading }


