import React, {useContext, useEffect, useState} from "react";
import { useForm, useFieldArray } from 'react-hook-form';
import Table from '../../../../components/styles/Table';
import PageSection from "../../../../components/layout/PageSection";
import SelectHours from "../../../../components/selects/SelectHours";
import {Col, Row} from "../../../../components/migrationcomponents/flexlayout";
import {Button, Card, Input, Select, Title} from "@wargostec/react-components";
import FormGroup from "../../../../components/migrationcomponents/FormGroup";
import {SiteContext} from "../../../../components/context/SiteContext";
import {Apis} from "../../../../utils/Apis";
import useFetch2 from "../../../../hooks/useFetch2";

const TurnForm = ({ value, onSubmit, title }) => {

  const [cycles, setCycles] = useState(0);
  const {sucursalId} = useContext(SiteContext);
  const baseURL = Apis.BASE_API_URL + "/horarios";
  const params = {sucursalId: sucursalId};
  const {data:dataselect, loading} = useFetch2(baseURL, [], params);

  const [defaultValues, setDefaultValues] = useState({
    detalle: [
      {
        name: 'LUNES',
        horarioId: '',

      },
      {
        name: 'MARTES',
        horarioId: '',
      },
      {
        name: 'MIÉRCOLES',
        horarioId: '',
      },
      {
        name: 'JUEVES',
        horarioId: '',
      },
      {
        name: 'VIERNES',
        horarioId: '',
      },
      {
        name: 'SÁBADO',
        horarioId: '',
      },
      {
        name: 'DOMINGO',
        horarioId: '',
      },
    ]
  });

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues
  });
  const { fields } = useFieldArray({
    control,
    name: "detalle",
  });

  const detalle = []
  for (let i = 1; i <= cycles; i++) {
    detalle.push(
      {
        name: 'LUNES',
        horarioId: '',

      },
      {
        name: 'MARTES',
        horarioId: '',
      },
      {
        name: 'MIERCOLES',
        horarioId: '',
      },
      {
        name: 'JUEVES',
        horarioId: '',
      },
      {
        name: 'VIERNES',
        horarioId: '',
      },
      {
        name: 'SABADO',
        horarioId: '',
      },
      {
        name: 'DOMINGO',
        horarioId: '',
      },
    );
  }

  useEffect(() => {
    reset({ detalle });
  }, [cycles]);

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [value]);

  useEffect(() => {
    handleSubmit(onSubmit);
  }, []);

  return (
    <>
      {dataselect && !loading ?
      <>
      <PageSection>
        <div style={{marginBottom: '1rem'}}>
          <Title text={title} type={'form'}/>
        </div>
      </PageSection>
      <PageSection>
        <Card>
          <Row>
            <Col>
              <FormGroup>
                <Input label={"Nombre"} placeholder="Nombre" {...register("name")} />
              </FormGroup>
              <FormGroup>
                <Input label={"Descripción"} placeholder="Descripcion" {...register("description")} />
              </FormGroup>
              <FormGroup>
                <Select label={"Periodo"} {...register("periodo")} >
                  <option value="SEMANAL">SEMANAL</option>
                </Select>
              </FormGroup>
              <FormGroup>
                <Input label={"Ciclos"}placeholder="Ciclos" {...register("cycles", {
                  onChange: function (e) {
                    setCycles(e.target.value);
                  }
                })
                } />
              </FormGroup>
            </Col>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Día</th>
                    <th>Horario</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    fields && fields.map((field, index) => {
                      return (
                        <tr key={field.id} style={{ width: '100%' }}>
                          <td>
                            {field.name}
                          </td>
                          <td>
                            <SelectHours data={dataselect} loading={loading} {...register(`detalle[${index}].horarioId`)} />
                          </td>
                        </tr>
                      )
                    }
                    )
                  }
                </tbody>
              </Table>
              <Button variant="primary" onClick={handleSubmit(onSubmit)} text={'Guardar'} />
            </Col>
          </Row>
        </Card>
      </PageSection>
      </>
      :
      <p style={{textAlign: 'center'}}>Cargando...</p>
      }
    </>
  );
};

export default TurnForm;
