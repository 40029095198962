import React, {useEffect} from 'react';
import ModalInfo from '../modals/ModalInfo';
import useModal from '../../hooks/useModal';
import {Button} from "@wargostec/react-components";

const DocumentPdfView = ({document, setTitle, title}) => {
  useEffect(() => {
    setTitle(document.title);
  }, []);

  return (
    <iframe title='document' src={`https://asisdata.s3.amazonaws.com/${document.value}`} style={{width: '100%', height: '75vh'}} />
  );
}


const DocumentView = ({value, title, icon,size,variant='warning'}) => {
  const {wrapper: wrapper1, someAction: getDocument} = useModal({
    component: <DocumentPdfView />
  })


  const handleClick = () => {
    if (value && title) {
      getDocument({document: {value, title}});
    }
  };

  return (
    <>
      {value && title && (
        <Button text={!icon && 'Ver documento'} 
          variant={variant} 
          icon={icon} 
          size={size} 
          style={!icon ? {marginTop:'12px',minWidth:'150px'} : {}} 
          onClick={handleClick}
        />
      )}
      <ModalInfo {...wrapper1} />
    </>
  );
}

export default DocumentView;