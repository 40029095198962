import React, {useEffect, useContext} from 'react';
import useFetch2 from '../hooks/useFetch2';
import {Apis} from '../utils/Apis';
import styled from 'styled-components';
import {SiteContext} from '../components/context/SiteContext';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button,FlexContainer} from "@wargostec/react-components";

const ContainerCard = styled.div`
	width: 100%;
	max-width: 1200px;
	height: 100%;
	display: grid;
	grid-gap: 16px;
	grid-template-columns: repeat(auto-fit, minmax(150px, 200px));
	grid-template-rows: repeat(auto-fi, minmax(150px, 200px));
	grid-template: fit-content;
	justify-content: center;
	margin: 0 auto;
`;

const Card = styled.div`
	width: 100%;
	min-height: 150px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align:center;
	border-radius:16px;
	padding:16px;
	background:#fff;
	cursor: pointer;
	transition: all 0.3s ease;
	.logo {
		box-sizing: border-box;
		overflow: hidden;
		min-height: 100px;
		display: flex;
		align-items: center;
		img {
			max-width: 100%;
			object-fit: cover;
			filter: grayscale(100%);
			transition: all 0.3s ease;
		}
	}
	.name {
		font-size: 1.1em;
		transition: all 0.3s ease;
		font-weight: bold;
	}

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px 0px;
	}
	&:hover img {
		filter: grayscale(20%);
	}
	&:hover .name {
		transform: scale(1.1);
	}
`;

const Selector = () => {
	const {setSucursal, setEmpresaId, setSucursalId} = useContext(SiteContext);
	const [dataFilter, setDataFilter] = React.useState([]);
	const location = useLocation();
	const navigate = useNavigate();

	const params = {product: "asis"};
	const {loading, data} = useFetch2(Apis.API_WARGOS_USER + '/users/me', [], params);

	const access = (sucursal) => {
		localStorage.setItem('sucursal', JSON.stringify(sucursal));
		setSucursal(sucursal);
		setEmpresaId(sucursal.companyId);
		setSucursalId(sucursal.id);
		const searchParams = new URLSearchParams(location.search);
		const redirect = searchParams.get('redirect');
		if (redirect) {
			navigate(redirect);
		} else {
			navigate('/');
		}
	};

	const logout = () => {
		localStorage.clear();
		navigate('/logout');
	};

	const getImageUrl = (logoPath) => {
		if (logoPath) {
			return logoPath;
		} else {
			return 'https://res.cloudinary.com/dghqjv5az/image/upload/v1629394113/mix/company2_ktg4d7.png';
		}
	}

	useEffect(() => {
		if (data && data.salas) {
			setDataFilter(data.salas);
		}
	}, [data]);

	return (
		<FlexContainer flexDirection='column' alignItems='center' padding='32px' gap='32px'>
			<h4 className='text-center'>
				Seleccione una sala
			</h4>
			<Button variant={'light'} onClick={logout} text={'Cerrar Sesión'}/>
			<ContainerCard>
				{!loading && dataFilter ? (
					dataFilter.map((item, index) => {
						return (
							<Card key={index} onClick={() => access(item)}>
								<div className='logo'>
									<img src={getImageUrl(item.companyLogo)} alt='imagen'/>
								</div>
								<div className='name'>{item.name}</div>
							</Card>
						);
					})
				) : (
					<p style={{textAlign: 'center'}}>Cargando...</p>
				)}
			</ContainerCard>
		</FlexContainer>
	);
};

export default Selector;
