import React, {useEffect, useState} from 'react';
import {useWauth} from '../auth/WauthProvider';
import axios from 'axios';
import {Apis} from '../utils/Apis';
import {Navigate} from 'react-router-dom';

const CallbackPage = () => {
	const {handleAuthentication, isAuthenticated} = useWauth();
	const [message, setMessage] = useState('Verificando cuenta');

	let referer = '/selector';

	const getUser = () => {
		const params = {"product": "asis"};
		axios.get(Apis.API_WARGOS_USER + `/users/me`,{params: params}).then((res) => {
			const fullName = res.data.user.name + ' ' + res.data.user.lastName;
			localStorage.setItem('fullname', fullName);
		});
	};

	useEffect(() => {
		handleAuthentication();
		getUser();
	}, []);

	if (isAuthenticated) {
		// if (localStorage.sala && localStorage.printer) referer = '/'
		return <Navigate to={referer} />;
	}

	return <div>{message}</div>;
};

export default CallbackPage;
