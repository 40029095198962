import React from 'react';
import {Select} from "@wargostec/react-components";
import useFetch2 from "../../hooks/useFetch2";
import {Apis} from "../../utils/Apis";

const SelectClock = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch2(Apis.BASE_API_URL + "/clocks", [])

  return (
    <>
      {
        data && !loading ?
          <Select ref={ref} {...props}>
            {data.map((item) => (
              <option key={item.id} value={item.id}>
                {item.sucursalName} - {item.serialNumber}
              </option>
            ))}
          </Select>
          :
          <Select disabled>
            <option value="0">Cargando...</option>
          </Select>
      }
    </>
  );
});

export default SelectClock;
