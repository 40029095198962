import React, { useEffect } from "react";
import { Apis } from '../../../../utils/Apis';
import axios from 'axios';
import RegulationForm from './RegulationForm';
import useFetch2 from '../../../../hooks/useFetch2';

const RegulationEdit = ({ id, setAction, setTitle }) => {
  const { data } = useFetch2(Apis.BASE_API_URL + '/reglamento-interno/' + id, []);

  const update = (params) => {
    return axios.put(Apis.BASE_API_URL + `/reglamento-interno/${id}`, params);
  };

  useEffect(() => {
    setTitle("Editar Reglamento Interno");
  }, []);

  return (
    <>
      <RegulationForm value={data} onSubmit={update} setAction={setAction} />
    </>
  );
};

export default RegulationEdit;