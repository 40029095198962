import React from "react";
import {Title} from "@wargostec/react-components";
import PageSection from "../../../../components/layout/PageSection";

const Notifications = () => {
  return (
    <PageSection>
      <div style={{marginBottom: '1rem'}}>
        <Title text='Grupo de notificaciones' type={'page'}/>
      </div>
    </PageSection>
  );
};

export default Notifications;
